import React from 'react'
import { useIntl } from 'react-intl'

import { get, includes, isEmpty, startCase, uniqueId } from 'lodash-es'
import pluralize from 'pluralize'

import {
  Assignment,
  AssignmentGroup,
  AssignmentStatuses,
  AssignmentStatusesLabel,
  getAssignmentGroupLabel,
  getAssignmentStatusLabel,
  getCombinedAssignmentCompletionDate,
  getCombinedAssignmentStatus,
  Provider,
} from '@lyrahealth-inc/shared-app-logic'

import { ItemAction } from './AssignmentsList'
import LtIcon from './LtIcon'
import colors from '../../../styles/_0colors.scss'
import MenuDotsIcon from '../../atoms/icons/MenuDotsIcon'
import Label from '../../atoms/label/Label'
import NavigationMenu from '../../atoms/navigationMenu/NavigationMenu'
import dateUtils from '../../utils/dateUtils'
import GenericListItem from '../genericListItem/GenericListItem'

const AssignmentListItem: React.FC<AssignmentListItemProps> = ({
  assignment,
  assignments,
  assignmentTags,
  fullBorder = false,
  itemActions,
  itemClickHandler,
  multiProvider = false,
  provider,
  sessionCount,
  showDueDate = true,
  showProviderName = true,
  userRole,
  isCareOnboardingPhase2Enabled,
}) => {
  const { formatMessage } = useIntl()
  const assignmentName = get(assignment, 'content.name') || assignment.name
  const title = get(assignment, 'content_meta_data.title') || get(assignment, 'content.title') || assignment.title
  const group = get(assignment, 'content.group') || assignment.group

  const displayStatus =
    userRole === 'client' && assignments
      ? getAssignmentStatusLabel({
          formatMessage,
          simplifyStatus: true,
          status: getCombinedAssignmentStatus({ assignment, assignments, isCareOnboardingPhase2Enabled }),
        })
      : getAssignmentStatusLabel({
          formatMessage,
          simplifyStatus: userRole === 'client',
          status: assignment.status as AssignmentStatuses,
        })

  let statusInfo: string
  if (displayStatus === AssignmentStatusesLabel[AssignmentStatuses.completed]) {
    statusInfo = displayStatus.concat(` ${getCombinedAssignmentCompletionDate({ assignment, assignments })}`)
  } else {
    statusInfo = displayStatus
  }

  let onClick = () => {}
  if (itemClickHandler) {
    onClick = () => itemClickHandler(assignment)
  }
  const inactive = userRole === 'client' && assignment.status === 'missed'
  if (
    inactive ||
    (userRole === 'provider' && group === AssignmentGroup.ASSESSMENT && assignment.status !== 'completed')
  ) {
    onClick = () => {}
  }

  const renderDetails = () => {
    const numEntries = assignment.response_count ? pluralize('entry', assignment.response_count, true) : null
    let sessionDueDate, providerName
    let details = []

    if (userRole === 'client') {
      if (!isEmpty(provider)) {
        const appointment = provider?.appointments.find(
          (appointment) => appointment.sessionNumber === assignment.session_period,
        )
        if (multiProvider) providerName = provider?.provider.display_name
        if (appointment) {
          sessionDueDate = dateUtils.getAppointmentDateTimeObject(appointment).format('MMMM D')
        }
      }
      if (assignment.past) {
        return group === AssignmentGroup.EXERCISE ? numEntries : null
      }
      details.push(startCase(getAssignmentGroupLabel(group as AssignmentGroup)))
      if (showDueDate) details.push(sessionDueDate)
      if (group === AssignmentGroup.EXERCISE) details.push(numEntries || 'Not started')
      else {
        if (statusInfo) details.push(startCase(statusInfo))
      }
      if (showProviderName) details.push(providerName)
    } else if (userRole === 'provider') {
      switch (group) {
        case AssignmentGroup.EXERCISE:
          details.push(numEntries)
          break
        default:
          details.push(statusInfo)
      }
    }
    details = details.filter((detail) => !isEmpty(detail))
    return details.join(' • ')
  }

  const renderBadge = () => {
    const unreadResponseCount = get(assignment, 'unread_count')
    if (userRole === 'client') {
      if (assignment.past) return null
      switch (group) {
        case AssignmentGroup.ASSESSMENT:
          return !includes(['completed', 'missed'], assignment.status) ? 'To-do' : null
        case AssignmentGroup.EXERCISE:
          return assignment.response_count < 1 ? 'To-do' : null
        default:
          return assignment.status !== 'completed' ? 'To-do' : null
      }
    } else {
      if (userRole === 'provider' && unreadResponseCount > 0 && group !== AssignmentGroup.INFO_SHEET) {
        return unreadResponseCount
      }
    }
    return null
  }

  const renderRightAction = () => {
    const actions = []
    itemActions = itemActions?.filter((action: ItemAction) => action.shouldShow(assignment, sessionCount)) || []
    if (itemActions.length && group !== AssignmentGroup.ASSESSMENT) {
      actions.push(
        <NavigationMenu
          data-test-id='AssignmentListItem-right-action'
          customIcon={<MenuDotsIcon />}
          alignment='right'
          menuType=''
        >
          {itemActions.map((action: ItemAction) => (
            <li
              data-test-id={`AssignmentListItem-dropdownAction-${action.name}`}
              onClick={(e) => action.selectHandler(e, action.name, assignment, sessionCount)}
              key={uniqueId('navigationMenu')}
            >
              <a>{action.text}</a>
            </li>
          ))}
        </NavigationMenu>,
      )
    }
    return actions
  }

  const renderAssignmentTags = (tags: AssignmentTag[]) => {
    return tags.map((tag) => {
      if (tag.type === AssignmentTagType.success) {
        return <Label textColor={colors.x_green4} backgroundColor={colors.x_green1} text={tag.text} />
      } else {
        return <Label text={tag.text} />
      }
    })
  }

  return (
    <GenericListItem
      key={uniqueId('genericListItem')}
      onClick={onClick}
      title={title}
      subText={renderDetails()}
      badgeContent={renderBadge()}
      icon={<LtIcon type={group} size={24} />}
      rightItems={[assignmentTags && renderAssignmentTags(assignmentTags), renderRightAction()]}
      data-test-id={`AssignmentListItem-${assignmentName}`}
      inactive={inactive}
      fullBorder={fullBorder}
    />
  )
}

type AssignmentListItemProps = {
  assignment: Assignment
  assignments?: Assignment[]
  assignmentTags?: AssignmentTag[]
  fullBorder?: boolean
  itemActions?: Array<ItemAction>
  itemClickHandler?: (val: any) => void
  multiProvider?: boolean
  provider?: Provider
  sessionCount?: number
  showDueDate?: boolean
  showProviderName?: boolean
  userRole: string
  isCareOnboardingPhase2Enabled?: boolean
}

type AssignmentTag = {
  type: AssignmentTagType
  text: string
}

export enum AssignmentTagType {
  default,
  success,
}

export default AssignmentListItem
