import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Link,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE_STEP_TWO } from '../../common/constants/routingConstants'
import { getAuthConfig } from '../../data/auth/authSelectors'
import { Config } from '../../lyraTherapy/types'
import { BodyTextContainer, ButtonContainer, HeaderContainer, WalkthroughContentContainer } from '../constants'

export const SchedulingWalkthrough1: React.FC = () => {
  const navigate = useNavigate()
  const { colors } = useTheme()
  const config: Config = useSelector(getAuthConfig)

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_NEXT_STEP })
    navigate(`${SCHEDULING_ACTIVATE_STEP_TWO.route}`)
  }

  const instructionsLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_HOW_TO_CONNECT })
    window.open(
      'https://provider-support.lyrahealth.com/hc/en-us/articles/115008263367-How-to-connect-your-calendar',
      '_blank',
    )
  }

  const internationalInstructionsLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_INTERNATIONAL_WALKTHROUGH_HOW_TO_CONNECT })
    window.open(
      'https://icas-provider-support.zendesk.com/hc/en-us/articles/33280044864275-How-to-Set-up-and-Edit-Your-Calendar-Availability',
      '_blank',
    )
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Let’s get started'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='We’ll walk you through the calendar integration process step-by-step. This requires managing your calendar settings and will take around 15 minutes.'
        />
      </BodyTextContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text={
            <>
              First, review{' '}
              <Link
                text='these instructions'
                onPress={config.internationalSmartSchedulingEnabled ? internationalInstructionsLink : instructionsLink}
                size={BodyTextSize.DEFAULT}
              />
              . to set up your availability and access live support if needed.
            </>
          }
        />
      </BodyTextContainer>
      <ButtonContainer>
        <PrimaryButton text='Continue' onPress={onNextStep} testID={tID('CompleteStepOne-button')} />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
