import React, { useEffect, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RjsfForm from 'react-jsonschema-form'
import ReactMarkdown from 'react-markdown'

// @ts-expect-error TS(7016): Could not find a declaration file for module 'fina... Remove this comment to see the full error message
import focusOnError from 'final-form-focus'
import { debounce, get, isEqual } from 'lodash-es'

import { removeHiddenFields } from '@lyrahealth-inc/shared-app-logic'

import { validateForm } from './contentValidation'
import fields from './fields'
import styles from './formBody.module.scss'
import widgets from './widgets'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import { getUISchema } from '../../utils/contentSchemaUtils'

interface FormChangeProperties {
  values: Dict
}

type Props = {
  children?: React.ReactNode
  content: $TSFixMe
  response?: $TSFixMe
  submitForm?: $TSFixMeFunction
  preview?: boolean
  readOnly?: boolean
  formContext?: $TSFixMe
  userRole?: string
  autoSave?: boolean
  showSubmitButton?: boolean
  disableSubmitButton?: boolean
  submitButtonLabel?: string
  customFields?: $TSFixMe
  disabledUntilValid?: boolean
  customValidation?: $TSFixMeFunction
  onFormChange?: ({ values }: FormChangeProperties) => void
}

const FormBody = ({
  children,
  content,
  response = {},
  submitForm,
  preview,
  readOnly,
  userRole,
  formContext = {},
  autoSave = true,
  customFields = {},
  showSubmitButton = true,
  disableSubmitButton = false,
  submitButtonLabel = 'Submit',
  disabledUntilValid = false,
  customValidation = () => {},
  onFormChange,
}: Props) => {
  const initialValuesSet = useRef(false)
  const [initialValues, setInitialValues] = useState({})
  const metaData = content.meta_data || get(content, 'content_meta_data') || content
  const name = content.name || get(content, 'content.name')
  const schema = metaData.schema
  const uiSchema = getUISchema(content)
  const savingDraft = useRef(false)
  const focusOnErrorDecorator = useRef(focusOnError())
  const previousValues = useRef<Dict | null>(null)

  useEffect(() => {
    if (!initialValuesSet.current) {
      if (['draft', 'completed'].includes(response.status)) {
        setInitialValues(response.response)
      } else {
        setInitialValues({
          ...metaData.initialValues,
          ...response.response,
        })
      }
      initialValuesSet.current = true
    }
  }, [metaData.initialValues, name, response.response, response.status])

  useEffect(() => () => handleFormChange.cancel())

  const arrayFieldTemplate = ({ items, schema: { title, name, inline } }: $TSFixMe) => {
    const components = { p: (props: $TSFixMe) => props.children }
    return (
      <div role='group' aria-label={title}>
        {title ? (
          <h4 className='array-title' id={name}>
            {/* eslint-disable-next-line react/no-children-prop */}
            <ReactMarkdown children={title} components={components} />
          </h4>
        ) : null}
        <div aria-labelledby={name} className={inline ? 'inline-container' : ''}>
          {items.map((item: $TSFixMe) => (
            <React.Fragment key={item.index}>{item.children}</React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  const SpyOnFormChange = () => (
    <FormSpy subscription={{ values: true, pristine: true }}>
      {({ values, pristine }) => {
        // Only call onFormChange if current values are different from previous values
        if (!!onFormChange && !pristine && !isEqual(values, previousValues.current)) {
          previousValues.current = values
          // wrapped in a setTimeout to nerf warning: Cannot update a component while rendering a different component
          setTimeout(() => onFormChange({ values }), 0)
        }
        return null
      }}
    </FormSpy>
  )

  const handleFormChange = debounce(async (values) => {
    if (savingDraft.current) {
      return
    }
    const group = content.group || get(content, 'content.group')
    switch (group) {
      case 'exercise':
      case 'assessment':
      case 'lesson':
        if (userRole === 'client') {
          savingDraft.current = true
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          await submitForm(values, 'draft', response.id)
          savingDraft.current = false
        }
        break
      default:
        savingDraft.current = true
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        await submitForm(values, 'draft', response.id)
        savingDraft.current = false
        break
    }
  }, 4000)

  const Autosave = () => (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({ values, pristine, submitting }) => {
        if (savingDraft.current || pristine || submitting || isEqual(values, response.response)) {
          return null
        }
        handleFormChange(values)
        return null
      }}
    </FormSpy>
  )
  const onSubmit = (values: $TSFixMe) => {
    if (savingDraft.current) {
      return
    }
    handleFormChange.cancel()
    values = removeHiddenFields({ values, fields: schema.properties })
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return submitForm(values, 'completed', response.id)
  }

  return (
    <div className={styles.container}>
      <Form
        validateOnBlur={metaData.validateOnBlur}
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        decorators={[focusOnErrorDecorator.current]}
        validate={(values) => ({
          ...validateForm(schema.properties, values),
          ...customValidation(schema.properties, values),
        })}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <>
              {autoSave && <Autosave />}
              <RjsfForm
                onSubmit={handleSubmit}
                ArrayFieldTemplate={arrayFieldTemplate}
                schema={schema}
                widgets={widgets}
                uiSchema={uiSchema}
                fields={{ ...fields, ...customFields }}
                formData={response}
                formContext={{ readOnly, ...formContext, schema }}
                noValidate
              >
                {children}
                {!!onFormChange && <SpyOnFormChange />}
                {showSubmitButton && (
                  <FormSpy subscription={{ valid: true }}>
                    {({ valid }) => (
                      <DefaultButton
                        style={readOnly ? { display: 'none' } : {}}
                        id='submit-assignment'
                        data-test-id='FormBody-submitButton'
                        type='submit'
                        disabled={preview || disableSubmitButton || (disabledUntilValid && !valid)}
                        isLoading={submitting}
                      >
                        {submitButtonLabel}
                      </DefaultButton>
                    )}
                  </FormSpy>
                )}
              </RjsfForm>
            </>
          )
        }}
      </Form>
    </div>
  )
}

export default FormBody
