import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  ChevronIconDirection,
  ChevronV2Icon,
  GearIcon,
  QuestionMarkIcon,
  SecondaryButton,
  Subhead,
  TertiaryIconButton,
} from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderCalendarHeaderProps = {
  onNextPressed: () => void
  onPrevPressed: () => void
  onTodayPressed: () => void
  onSettingsPressed: () => void
  rangeText: string
  scheduledSessions: number | null
  clientHours: number | null
}

const Header = styled.View(({ theme }) => ({
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 3,
}))

const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing['16px'],
  padding: `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const HeaderInnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const MetricsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const Metric = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['12px'],
  padding: theme.spacing['8px'],
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '4px',
}))

const Border = styled.View(({ theme }) => ({
  height: '1px',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '1px',
  backgroundColor: theme.colors.borderDefault,
}))

const IconButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const IconButton = styled(TertiaryIconButton)(({ theme }) => ({
  alignSelf: 'center',
  border: `1.5px solid ${theme.colors.borderDefault}`,
  height: '48px',
  width: '48px',
}))

const CenteredIconButton = styled(TertiaryIconButton)({
  alignSelf: 'center',
})

export const ProviderCalendarHeader: FunctionComponent<ProviderCalendarHeaderProps> = ({
  onNextPressed,
  onPrevPressed,
  onTodayPressed,
  onSettingsPressed,
  rangeText,
  scheduledSessions,
  clientHours,
}) => {
  const theme = useTheme() as ThemeType
  return (
    <Header>
      <HeaderContainer>
        <HeaderInnerContainer>
          <SecondaryButton
            style={{ alignSelf: 'center', borderColor: theme.colors.borderDefault }}
            customTextColor={theme.colors.textSecondary}
            text={<FormattedMessage defaultMessage='Today' description='Button text that moves calendar to today' />}
            onPress={onTodayPressed}
          />
          <IconButtonContainer>
            <IconButton leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />} onPress={onPrevPressed} />
            <IconButton leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />} onPress={onNextPressed} />
          </IconButtonContainer>
          <Subhead text={rangeText} size={SubheadSize.LARGE} />
        </HeaderInnerContainer>
        <HeaderInnerContainer>
          <MetricsContainer testID={tID('ProviderCalendarHeader-metrics-client-hours')}>
            <Metric>
              <Subhead size={SubheadSize.XLARGE} text={clientHours} />
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='Client hours'
                    description='Label for client hours on lc calendar header'
                  />
                }
              />
            </Metric>
            <Metric testID={tID('ProviderCalendarHeader-metrics-scheduled')}>
              <Subhead size={SubheadSize.XLARGE} text={scheduledSessions} />
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='Scheduled'
                    description='Label for scheduled sessions on lc calendar header'
                  />
                }
              />
            </Metric>
          </MetricsContainer>
          <CenteredIconButton
            testID={tID('ProviderCalendarHeader-settings-button')}
            leftIcon={<GearIcon color={theme.colors.iconDefault} size={24} />}
            onPress={onSettingsPressed}
          />
          <CenteredIconButton
            testID={tID('ProviderCalendarHeader-help-button')}
            leftIcon={<QuestionMarkIcon fillColor={theme.colors.iconDefault} width={24} />}
            onPress={() => {}}
          />
        </HeaderInnerContainer>
      </HeaderContainer>
      <Border />
    </Header>
  )
}
