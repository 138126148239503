import React, { FunctionComponent } from 'react'

import LottieView from 'lottie-react-native'
import styled from 'styled-components/native'

import calendarConfetti from '../../assets/lottie/calendar_confetti.json'
import { tID } from '../../utils'

const Container = styled.View({
  position: 'relative',
})

export const CalendarCheckmarkLottie: FunctionComponent = () => {
  return (
    <Container testID={tID('CalendarCheckmarkLottie')}>
      <LottieView autoPlay source={calendarConfetti} />
    </Container>
  )
}
