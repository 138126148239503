import { addMinutes, differenceInMinutes, parseISO, subMinutes } from 'date-fns'
import { getTimezoneOffset as getTimezoneOffsetFNS } from 'date-fns-tz'

import { EventExtendedProps } from '../../../models'
export enum OffsetDirection {
  BEFORE_AND_AFTER = 'BEFORE_AND_AFTER',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  NONE = 'NONE',
}

export const getAdjustedEvent: (
  event: { startStr: string; endStr: string },
  startingRange: { startStr: string; endStr: string } | null,
) => {
  start: string
  end: string
  offset: OffsetDirection
} = (event, startingRange) => {
  //ISO dates from this library do not include tz info so considering as UTC while processing
  const startTime = parseISO(event.startStr + 'Z')
  const endTime = parseISO(event.endStr + 'Z')
  const differenceHours = differenceInMinutes(endTime, startTime) / 60

  if (!startingRange || startingRange.startStr === event.startStr) {
    return {
      start: startTime.toISOString(),
      end: differenceHours % 1 !== 0 ? addMinutes(endTime, 30).toISOString() : endTime.toISOString(),
      offset: differenceHours % 1 !== 0 ? OffsetDirection.AFTER : OffsetDirection.NONE,
    }
  }
  return {
    start: differenceHours % 1 !== 0 ? startTime.toISOString() : subMinutes(startTime, 30).toISOString(),
    end: addMinutes(endTime, 30).toISOString(),
    offset: differenceHours % 1 !== 0 ? OffsetDirection.BEFORE : OffsetDirection.BEFORE_AND_AFTER,
  }
}

export const eventsOverlap = (a: { start: Date; end: Date }, b: { start: Date; end: Date }): boolean => {
  if (a.start < b.start && b.start < a.end) return true
  if (a.start < b.end && b.end < a.end) return true // b ends in a
  if (b.start <= a.start && a.end <= b.end) return true // a in b
  return false
}

export const isBookableEvent = (extendedProps: EventExtendedProps) =>
  ['bookable', 'bookable_recurring'].includes(extendedProps.lyraEventType)

export const getTimezoneOffset = (timeZone: string, dateStr: string): number => {
  const date = parseISO(dateStr.endsWith('Z') ? dateStr : dateStr + 'Z')
  return getTimezoneOffsetFNS(timeZone, new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
}

export const isSessionOrSlot = (extendedProps: EventExtendedProps) => {
  return isBookableEvent(extendedProps) || extendedProps.lyraEventType === 'session'
}

export const isOOOEvent = (title: string | undefined) =>
  title !== undefined && /^\s*(OOO|PTO|Vacation|Sick|UglyPopcorn)([^a-z]|$)/i.test(title)
