import { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { OAUTH_SUCCESS_RESPONSE } from './constants'
import { LC_CALENDAR_SETUP } from '../common/constants/routingConstants'

const CalendarCallback: FunctionComponent = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(OAUTH_SUCCESS_RESPONSE)
      window.opener.focus()
      window.close()
    } else {
      navigate(LC_CALENDAR_SETUP.route)
    }
  }, [navigate])
  return null
}

export default CalendarCallback
