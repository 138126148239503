import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import { CheckboxButtonType } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  Checkbox,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import {
  SCHEDULING_ACTIVATE_GOOGLE_FINAL,
  SCHEDULING_ACTIVATE_STEP_FOUR,
} from '../../common/constants/routingConstants'
import { BodyTextContainer, ButtonContainer, HeaderContainer, WalkthroughContentContainer } from '../constants'

const CheckboxContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '124px',
}))

const CheckboxItem = styled.View({
  maxHeight: '54px',
  width: '55%',
  alignSelf: 'center',
})

export const SchedulingWalkthrough2: React.FC = () => {
  const [optionSelected, setOptionSelected] = useState(false)
  const [googleSelected, setGoogleSelected] = useState(false)
  const [simplePracticeSelected, setSimplePracticeSelected] = useState(false)
  const navigate = useNavigate()
  const { colors } = useTheme()

  useEffect(() => {
    setOptionSelected(googleSelected || simplePracticeSelected)
  }, [googleSelected, simplePracticeSelected])

  const onPressGoogle = () => {
    setGoogleSelected(!googleSelected)
  }

  const onPressSimplePractice = () => {
    setSimplePracticeSelected(!simplePracticeSelected)
  }

  const onNextStep = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_NEXT_STEP })
    if (simplePracticeSelected) {
      navigate(SCHEDULING_ACTIVATE_STEP_FOUR.route)
    } else {
      navigate(SCHEDULING_ACTIVATE_GOOGLE_FINAL.route)
    }
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Which calendar services do you use to manage your appointments?'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='You can select multiple calendars if you use more than one.'
        />
      </BodyTextContainer>
      <CheckboxContainer>
        <CheckboxItem testID={tID('SelectGoogle-button')}>
          <Checkbox
            title='Google Calendar'
            checked={googleSelected}
            onPress={onPressGoogle}
            buttonType={CheckboxButtonType.CHECKBOX_TEXT_OUTLINE}
          />
        </CheckboxItem>
        <CheckboxItem testID={tID('SelectSimplePractice-button')}>
          <Checkbox
            title='SimplePractice'
            checked={simplePracticeSelected}
            onPress={onPressSimplePractice}
            buttonType={CheckboxButtonType.CHECKBOX_TEXT_OUTLINE}
          />
        </CheckboxItem>
      </CheckboxContainer>
      <ButtonContainer>
        <PrimaryButton
          text='Okay'
          onPress={onNextStep}
          disabled={!optionSelected}
          testID={tID('CompleteStepTwo-button')}
        />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
