import React, { FunctionComponent, useMemo, useState } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import { parseISO } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import {
  EventExtendedProps,
  formatPhoneNumberForDisplay,
  getProgramNameFromId,
  getProgramNameLabel,
  isBookableEvent,
  MEETING_FORMATS,
} from '@lyrahealth-inc/shared-app-logic'

import { ProviderCalendarPopoverDescription } from './ProviderCalendarPopoverDescription'
import {
  AlignLeftIcon,
  BodyText,
  ChatLiveIcon,
  ClockIcon,
  CloseIcon,
  ExternalLinkIcon,
  Link,
  LocationIcon,
  PhoneIcon,
  PressableOpacity,
  PrimaryButton,
  ProfileCircleIcon,
  ScheduleIcon,
  Subhead,
  TrashIcon,
  VideoIcon,
} from '../../atoms'
import { ConfirmationModal } from '../../organisms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarEventBadges } from '../providerCalendarEventBadges/ProviderCalendarEventBadges'

export type ProviderCalendarPopoverProps = {
  title: string
  startDate: string
  endDate: string
  extendedProps: EventExtendedProps
  timeZone: string
  onClientProfilePressed?: (clientId: string) => void
  onClose?: () => void
  onDelete?: () => void
  onOpenInGoogleCalendar?: (externalId: string, calendarConfigurationId: string) => void
  onOpenZoom?: (externalId: string, calendarConfigurationId: string) => void
}

const Container = styled.View(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  paddingBottom: theme.spacing['24px'],
  width: '450px',
  gap: theme.spacing['16px'],
}))

const HeaderButtonContainer = styled.View(({ theme }) => ({
  alignSelf: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const HeaderTopRow = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing['16px'],
  justifyContent: 'space-between',
}))

const Header = styled.View(({ theme }) => ({
  gap: theme.spacing['4px'],
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
  padding: `${theme.spacing['24px']} ${theme.spacing['24px']} ${theme.spacing['16px']} ${theme.spacing['24px']}`,
}))

const Section = styled.View(({ theme }) => ({
  padding: `0px ${theme.spacing['24px']}`,
  gap: theme.spacing['16px'],
}))

const SectionItem = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const JoinButton = styled(PrimaryButton)(({ theme }) => ({
  margin: `0px ${theme.spacing['24px']}`,
  flexGrow: 1,
}))

export const ProviderCalendarPopover: FunctionComponent<ProviderCalendarPopoverProps> = ({
  title,
  startDate,
  endDate,
  extendedProps,
  timeZone,
  onClose,
  onClientProfilePressed,
  onDelete,
  onOpenInGoogleCalendar,
  onOpenZoom,
}) => {
  const theme = useTheme() as ThemeType
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const eventTitle = useMemo(() => {
    if (extendedProps.lyraEventType === 'bookable') {
      return title + ' (one-time)'
    }

    if (extendedProps.lyraEventType !== 'session') {
      return title
    }
    const programName = getProgramNameFromId(extendedProps.lyraProgramId)
    const programNameLabel = programName ? getProgramNameLabel(programName) : ''

    if (extendedProps.isNoShow) {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastName} • No Show • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: extendedProps.clientFirstName,
            lastName: extendedProps.clientLastName,
            programName: programNameLabel,
          }}
        />
      )
    }
    if (extendedProps.appointmentClass === 'initial') {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastName} • Intake • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: extendedProps.clientFirstName,
            lastName: extendedProps.clientLastName,
            programName: programNameLabel,
          }}
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='{firstName} {lastName} • Session {sessionNumber} • {programName}'
        description='Event title for lyra appointment'
        values={{
          firstName: extendedProps.clientFirstName,
          lastName: extendedProps.clientLastName,
          sessionNumber: extendedProps.appointmentSessionNumber,
          programName: programNameLabel,
        }}
      />
    )
  }, [extendedProps, title])

  const meetingFormatIcon = useMemo(() => {
    if (extendedProps.lyraEventType !== 'session') {
      return <ScheduleIcon fillColor={theme.colors.iconDefault} size={24} />
    }
    switch (extendedProps.appointmentMeetingFormat) {
      case MEETING_FORMATS.IN_PERSON: {
        return <LocationIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.PHONE:
      case MEETING_FORMATS.CALL: {
        return <PhoneIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.LIVE_MESSAGING: {
        return <ChatLiveIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      default: {
        return <VideoIcon fillColor={theme.colors.iconDefault} size={24} />
      }
    }
  }, [extendedProps, theme.colors.iconDefault])

  const sections = useMemo(() => {
    switch (extendedProps.lyraEventType) {
      case 'bookable':
      case 'bookable_recurring':
        return null
      case 'generic':
        return (
          <>
            {extendedProps.description && (
              <SectionItem>
                <View style={{ flexShrink: 0, alignSelf: 'flex-start' }}>
                  <AlignLeftIcon size={24} fillColor={theme.colors.iconDefault} />
                </View>
                <ProviderCalendarPopoverDescription description={extendedProps.description} />
              </SectionItem>
            )}

            <SectionItem>
              <ExternalLinkIcon size={24} strokeColor={theme.colors.iconDefault} />
              <Link
                testID={tID('ProviderCalendarPopover-open-in-google-calendar-link')}
                size={BodyTextSize.SMALL}
                underline
                onPress={() =>
                  onOpenInGoogleCalendar?.(extendedProps.externalId, extendedProps.calendarConfigurationId)
                }
                text={
                  <FormattedMessage
                    defaultMessage='Open Google Calendar'
                    description='Link to event in google calendar'
                  />
                }
              />
            </SectionItem>
          </>
        )
      case 'session':
        const { clientId } = extendedProps
        return (
          <>
            {extendedProps.clientTimeZone && (
              <SectionItem>
                <ClockIcon size={24} fillColor={theme.colors.iconDefault} />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='Timezone: {timeZone}'
                      values={{
                        timeZone: extendedProps.clientTimeZone,
                      }}
                      description='Client timezone displayed on event'
                    />
                  }
                  size={BodyTextSize.SMALL}
                />
              </SectionItem>
            )}
            {extendedProps.clientPhoneNumber && (
              <SectionItem>
                <PhoneIcon size={24} fillColor={theme.colors.iconDefault} />
                <BodyText
                  text={
                    <FormattedMessage
                      defaultMessage='{phoneNumber} — Dial *88 to block your number'
                      values={{ phoneNumber: formatPhoneNumberForDisplay(extendedProps.clientPhoneNumber) }}
                      description='Phone number displayed on event'
                    />
                  }
                  size={BodyTextSize.SMALL}
                />
              </SectionItem>
            )}
            {clientId && (
              <SectionItem>
                <ProfileCircleIcon size={24} fillColor={theme.colors.iconDefault} />
                <Link
                  testID={tID('ProviderCalendarPopover-client-profile-link')}
                  size={BodyTextSize.SMALL}
                  underline
                  onPress={() => onClientProfilePressed?.(clientId)}
                  text={<FormattedMessage defaultMessage='Go to profile' description='Link to client profile' />}
                />
              </SectionItem>
            )}
          </>
        )
    }
  }, [extendedProps, onClientProfilePressed, onOpenInGoogleCalendar, theme.colors.iconDefault])
  const isBookable = isBookableEvent(extendedProps)
  return (
    <>
      <Container testID={tID('ProviderCalendarPopover')}>
        <Header>
          <HeaderTopRow>
            <ProviderCalendarEventBadges title={title} extendedProps={extendedProps} />
            <HeaderButtonContainer>
              {isBookable && (
                <PressableOpacity
                  onPress={() => setShowConfirmationModal(true)}
                  testID={tID('ProviderCalendarPopover-delete-button')}
                >
                  <TrashIcon size={20} />
                </PressableOpacity>
              )}
              <PressableOpacity onPress={onClose}>
                <CloseIcon size={16} />
              </PressableOpacity>
            </HeaderButtonContainer>
          </HeaderTopRow>

          <Subhead size={SubheadSize.MEDIUM} text={eventTitle} />
        </Header>
        <Section>
          <SectionItem>
            {meetingFormatIcon}
            <BodyText
              text={
                <FormattedDateTimeRange
                  from={parseISO(startDate)}
                  to={parseISO(endDate)}
                  weekday='long'
                  month='long'
                  day='numeric'
                  hour='numeric'
                  minute='numeric'
                  timeZoneName='short'
                  timeZone={timeZone}
                />
              }
              size={BodyTextSize.SMALL}
            />
          </SectionItem>
          {sections}
        </Section>
        {extendedProps.lyraEventType === 'generic' && extendedProps.hasZoomMeeting && (
          <JoinButton
            testID={tID('ProviderCalendarPopover-join-zoom-button')}
            fullWidth
            text={<FormattedMessage defaultMessage='Join' description='Button to join zoom meeting' />}
            onPress={() => onOpenZoom?.(extendedProps.externalId, extendedProps.calendarConfigurationId)}
          />
        )}
      </Container>
      <ConfirmationModal
        visible={showConfirmationModal}
        onRequestClose={() => setShowConfirmationModal(false)}
        onConfirmationButtonPress={() => onDelete?.()}
        modalTitle={
          <FormattedMessage
            defaultMessage='Delete availability this week?'
            description='Title of modal to delete one-time availability'
          />
        }
        cancelButtonText={<FormattedMessage defaultMessage='Cancel' description='Cancel button' />}
        confirmationButtonText={<FormattedMessage defaultMessage='Delete' description='Add availability button' />}
        modalContents={
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='Clients will not be able to book at this time.'
                description='Body text for delete confirmation modal'
              />
            }
            size={BodyTextSize.DEFAULT}
          />
        }
      />
    </>
  )
}
