import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  ChevronIcon,
  ChevronIconDirection,
  ContentContainer,
  Link,
  PressableOpacity,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  WordCloudButton,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getAuthConfig } from '../../data/auth/authSelectors'
import { Config } from '../../lyraTherapy/types'
import { BodyTextContainer, HeaderContainer } from '../constants'

export const SchedulingCalendarView: React.FC<SchedulingCalendarViewProps> = ({
  providerAvailabilities,
  schedulingCredentialsValid,
}) => {
  const { colors } = useTheme()
  const config: Config = useSelector(getAuthConfig)
  const [availabilityPageIndex, setAvailabilityPageIndex] = useState(0)
  const pageSize = 5
  const startingPaginationIndex = availabilityPageIndex * pageSize
  const endingPaginationIndex = startingPaginationIndex + pageSize
  const providerAvailabilitiesLength = providerAvailabilities.availabilities
    ? providerAvailabilities.availabilities.length
    : 0
  const atFinalPage = availabilityPageIndex * pageSize + pageSize >= providerAvailabilitiesLength

  const CalendarDisplayTitle = styled(HeaderContainer)({
    marginTop: '40px',
  })

  const UnavailableSlot = styled(BodyText)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing['48px'],
    width: '100%',
    marginBottom: theme.spacing['12px'],
  }))

  const AvailabilityColumn = styled.View({
    width: '20%',
    alignSelf: 'center',
    display: 'flex',
    paddingLeft: '6px',
    paddingRight: '6px',
  })

  const AvailabilitiesContainer = styled.View(({ theme }) => ({
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: theme.spacing['40px'],
    paddingRight: theme.spacing['40px'],
    marginTop: '20px',
    width: '100%',
  }))

  const DateContainer = styled.View({
    display: 'flex',
    width: '20%',
    flexDirection: 'column',
  })

  const DateHeader = styled.View(({ theme }) => ({
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    paddingBottom: theme.spacing['12px'],
    borderBottomWidth: '1px',
    borderBottomColor: theme.colors.borderDefault,
  }))

  const InnerDateHeader = styled.View({
    flexDirection: 'row',
    display: 'flex',
    width: 'calc(100% - 96px)',
  })

  const CalendarContainer = styled.View(({ theme }) => ({
    width: 'auto',
    paddingTop: theme.spacing['12px'],
    paddingBottom: theme.spacing['24px'],
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: theme.spacing['8px'],
    borderColor: theme.colors.borderDefault,
    borderWidth: '1px',
    marginTop: theme.spacing['32px'],
    marginLeft: theme.spacing['12px'],
    marginRight: theme.spacing['12px'],
  }))

  const AvailableSlot = styled(WordCloudButton)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing['12px'],
  }))

  const PaginationContainer = styled(PressableOpacity)<{ theme: ThemeType; hidden: boolean }>(({ theme, hidden }) => ({
    opacity: hidden ? 0 : 1,
    paddingLeft: theme.spacing['16px'],
    paddingTop: theme.spacing['16px'],
    display: 'flex',
    width: theme.spacing['48px'],
    height: theme.spacing['48px'],
  }))

  const onPrevPressed = () => {
    if (availabilityPageIndex !== 0) {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_CALENDAR_VIEW_PREV })
      setAvailabilityPageIndex((prevState) => prevState - 1)
    }
  }

  const onNextPressed = () => {
    if (!atFinalPage) {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_CALENDAR_VIEW_NEXT })
      setAvailabilityPageIndex((prevState) => prevState + 1)
    }
  }

  const learnMoreLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_AVAILABILITY_CALENDAR_LINK })
    window.open(
      'https://provider-support.lyrahealth.com/hc/en-us/articles/115008263367-Connecting-Your-Calendar-with-Lyra',
      '_blank',
    )
  }

  const internationalLearnMoreLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_INTERNATIONAL_AVAILABILITY_CALENDAR_LINK })
    window.open(
      'https://icas-provider-support.zendesk.com/hc/en-us/articles/33280044864275-How-to-Set-up-and-Edit-Your-Calendar-Availability&sa=D&source=docs&ust=1727461653050786&usg=AOvVaw2c-SC2FgN2fQ1dZLMDzOJ0',
      '_blank',
    )
  }

  const renderAvailabilityItem = (availabilitySlot: string, index: number) => {
    if (availabilitySlot === '-') {
      return <UnavailableSlot key={index} text='–' />
    }
    return (
      <AvailableSlot
        isSelected={false}
        key={index}
        onPress={noop}
        text={availabilitySlot}
        suppressAccessibilitySelected={true}
      />
    )
  }

  const renderAvailabilityColumn = (availabilityTimeList: string[], index: number) => {
    return (
      <AvailabilityColumn key={index} testID={tID('AvailabilityCalendar-column' + index)}>
        {availabilityTimeList.map((availabilityItem, index) => renderAvailabilityItem(availabilityItem, index))}
      </AvailabilityColumn>
    )
  }

  const renderAvailabilitiesTable = () => {
    const currentAvailabilities = providerAvailabilities.times.slice(startingPaginationIndex, endingPaginationIndex)
    return currentAvailabilities.map((availabilityTimes, index) => renderAvailabilityColumn(availabilityTimes, index))
  }

  const renderDateItem = (availabilityDate: string, index: number) => {
    const dateArray = availabilityDate.split(' ')
    return (
      <DateContainer key={index} testID={tID('AvailabilityCalendar-header-' + availabilityDate)}>
        <BodyText
          color={colors.textSecondary}
          bold={true}
          size={BodyTextSize.SMALL}
          textAlign='center'
          text={dateArray[0].toUpperCase()}
        />
        <BodyText
          color={colors.textSecondary}
          size={BodyTextSize.LARGE}
          bold={true}
          textAlign='center'
          text={dateArray[1]}
        />
      </DateContainer>
    )
  }

  const renderDateHeader = () => {
    const currentDates = providerAvailabilities.availabilities.slice(startingPaginationIndex, endingPaginationIndex)
    return currentDates.map((availabilityDate, index) => renderDateItem(availabilityDate, index))
  }

  // Given the update in this PR ( https://github.com/LyraHealth/LyraWebAPI/pull/9593 ), we are now having even unsubmitted
  // calendars returned to us. So instead of checking for calendar status, we should instead check for availabilities
  // being present in the response body.
  if (!providerAvailabilities.availabilities) {
    return (
      <ContentContainer>
        <CalendarDisplayTitle>
          <Subhead level='2' size={SubheadSize.LARGE} textAlign='left' text='Your Available Times Will Display Below' />
        </CalendarDisplayTitle>
        <BodyTextContainer>
          <BodyText
            color={colors.textSecondary}
            textAlign='left'
            text={
              <>
                Once your calendar is live for booking we will display your available times here for your reference.{' '}
                <Link
                  text='Learn more'
                  onPress={config.internationalSmartSchedulingEnabled ? internationalLearnMoreLink : learnMoreLink}
                  size={BodyTextSize.DEFAULT}
                />
              </>
            }
          />
        </BodyTextContainer>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer>
      <CalendarDisplayTitle>
        <Subhead level='2' size={SubheadSize.LARGE} textAlign='left' text='Your Available Times' />
      </CalendarDisplayTitle>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='left'
          text={
            <>
              {schedulingCredentialsValid
                ? 'The following schedule is shared with clients. '
                : 'The following schedule will be shared with clients once live for booking. '}
              If you need to adjust your availability, you can block off times directly in your connected calendar (e.g.
              Google Calendar) and refresh this page.{' '}
              <Link
                text='Learn more'
                onPress={config.internationalSmartSchedulingEnabled ? internationalLearnMoreLink : learnMoreLink}
                size={BodyTextSize.DEFAULT}
              />
            </>
          }
        />
      </BodyTextContainer>
      <CalendarContainer>
        <DateHeader>
          <PaginationContainer
            hidden={availabilityPageIndex === 0}
            disabled={availabilityPageIndex === 0}
            onPress={onPrevPressed}
            testID={tID('CalendarPageSelector-prev')}
          >
            <ChevronIcon direction={ChevronIconDirection.LEFT} fillColor={colors.iconDefault} />
          </PaginationContainer>
          <InnerDateHeader>{renderDateHeader()}</InnerDateHeader>
          <PaginationContainer
            hidden={atFinalPage}
            disabled={atFinalPage}
            onPress={onNextPressed}
            testID={tID('CalendarPageSelector-next')}
          >
            <ChevronIcon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconDefault} />
          </PaginationContainer>
        </DateHeader>
        <AvailabilitiesContainer>{renderAvailabilitiesTable()}</AvailabilitiesContainer>
      </CalendarContainer>
    </ContentContainer>
  )
}

type SchedulingCalendarViewProps = {
  providerAvailabilities: { availabilities: string[]; times: string[][] }
  schedulingCredentialsValid: boolean
}
