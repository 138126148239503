import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { SafeAreaView } from 'react-native-safe-area-context'

import styled from 'styled-components/native'

import {
  CalendarCheckmarkLottie,
  Modal,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  XIcon,
} from '@lyrahealth-inc/ui-core-crossplatform'

export interface CalendarIsLiveConfirmationModalProps {
  visible: boolean
  onClosePress: () => void
}

const Container = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && {
    flexGrow: 1,
    paddingHorizontal: theme.spacing['16px'],
    paddingVertical: theme.spacing['12px'],
    backgroundColor: theme.colors.backgroundPrimary,
  }),
}))

const Content = styled.View({
  alignItems: 'center',
  marginTop: 0,
})

const ButtonContainer = styled.View({
  justifyContent: 'flex-end',
  flexDirection: 'row',
})

const CloseButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const Heading = styled(Subhead)(({ theme: { spacing } }) => ({
  marginTop: spacing['12px'],
}))

export const CalendarIsLiveConfirmationModal: FunctionComponent<CalendarIsLiveConfirmationModalProps> = ({
  visible = false,
  onClosePress = () => {},
}) => {
  const modalContents = (
    <Container testID={tID('CalendarIsLiveModal')}>
      <ButtonContainer>
        <PressableOpacity onPress={onClosePress}>
          <XIcon size={24} />
        </PressableOpacity>
      </ButtonContainer>
      <Content testID={tID('CalendarIsLiveModalContent')}>
        <CalendarCheckmarkLottie />
        <Heading
          textAlign='center'
          size={SubheadSize.MEDIUM}
          text={
            <FormattedMessage
              defaultMessage='Your new Lyra calendar is now live'
              description='Header text for modal that informs the user they have successfully set up their Lyra calendar'
            />
          }
        />
      </Content>
      <ButtonContainer>
        <CloseButton
          onPress={onClosePress}
          testID={tID('CalendarIsLiveModal-continueButton')}
          text={<FormattedMessage defaultMessage='Done' description='Button text. Clicking will dismiss the modal' />}
        />
      </ButtonContainer>
    </Container>
  )

  return (
    <Modal
      onRequestClose={onClosePress}
      onCloseEnd={onClosePress}
      visible={visible}
      modalContents={modalContents}
      useDynamicHeightForBottomSheet
      showCloseIcon
    />
  )
}
