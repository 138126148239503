import React from 'react'

import styles from './SUDSTable.module.scss'
import DataTable from '../../molecules/table/DataTable'

const tableContent = [
  { suds: '0', description: 'No distress/Do not avoid' },
  { suds: '25', description: 'A little distress/Rarely avoid' },
  { suds: '50', description: 'Moderate distress/Sometimes avoid' },
  { suds: '75', description: 'Strong distress/Usually avoid' },
  { suds: '100', description: 'Extreme distress/Always avoid' },
]

export const SUDSTable = () => {
  return (
    <>
      <p className={styles['section-title']}>
        Use the following rating scale to rate your Subjective Units of Distress (SUDS):
      </p>
      <div className={styles['table-container']}>
        <DataTable
          columns={[
            { Header: 'SUDS', accessor: 'suds', maxWidth: 75 },
            { Header: 'Description', accessor: 'description' },
          ]}
          data={tableContent}
          sortable={false}
          resizable={false}
          highlight={false}
          pagination={false}
        />
      </div>
    </>
  )
}

export const TeensDistressTable = () => {
  return (
    <div className={styles['table-container']}>
      <DataTable
        columns={[
          { Header: 'Rating Scale', accessor: 'suds', maxWidth: 150 },
          { Header: 'Description', accessor: 'description' },
        ]}
        data={tableContent}
        sortable={false}
        resizable={false}
        highlight={false}
        pagination={false}
      />
    </div>
  )
}
