import React from 'react'
import { useSelector } from 'react-redux'

import { Field, formValueSelector } from 'redux-form/immutable'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { InputField, PrimaryButton } from '@lyrahealth-inc/ui-core'
import { colors, ToggleSwitchRFF } from '@lyrahealth-inc/ui-core-crossplatform'

// TODO!!! UPDATE SO WE DYNAMICALLY BUILD THE UPDATE FORM TO MATCH PROVIDER MODALITY.
const ProviderRatesForm: React.FC<ProviderRatesFormProps> = ({
  handleSubmit,
  submitting,
  submitFunction,
  primaryButtonText,
}) => {
  const { differentialPricingRatesEnabled } = useFlags()
  const selector = formValueSelector('editProviderRatesForm')
  const differentialPricingEnabled = useSelector((state: boolean) => selector(state, 'differential_pricing_enabled'))
  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='individual_initial_evaluation'
            data-test-id='individual_initial_evaluation'
            type='text'
            component={InputField}
            label='Initial Evaluation (individual) Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='individual_follow_up_session'
            data-test-id='individual_follow_up_session'
            type='text'
            component={InputField}
            label='Follow Up Session (individual) Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='couples_initial_evaluation'
            data-test-id='couples_initial_evaluation'
            type='text'
            component={InputField}
            label='Initial Evaluation (couples) Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='couples_follow_up_session'
            data-test-id='couples_follow_up_session'
            type='text'
            component={InputField}
            label='Follow Up Session (couples) Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='family_initial_evaluation'
            data-test-id='family_initial_evaluation'
            type='text'
            component={InputField}
            label='Initial Evaluation (family) Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='family_follow_up_session'
            data-test-id='family_follow_up_session'
            type='text'
            component={InputField}
            label='Follow Up Session (family) Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='group_initial_evaluation'
            data-test-id='group_initial_evaluation'
            type='text'
            component={InputField}
            label='Initial Evaluation (group) Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='group_follow_up_session'
            data-test-id='group_follow_up_session'
            type='text'
            component={InputField}
            label='Follow Up Session (group) Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='medication_management_initial_evaluation'
            data-test-id='medication_management_initial_evaluatio'
            type='text'
            component={InputField}
            label='Initial Evaluation (medication management) Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='medication_management_follow_up_session'
            data-test-id='medication_management_follow_up_session'
            type='text'
            component={InputField}
            label='Follow Up Session (medication management) Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='smp_session'
            data-test-id='smp_session'
            type='text'
            component={InputField}
            label='SMP Session Rate'
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='ssc_session'
            data-test-id='ssc_session'
            type='text'
            component={InputField}
            label='GSC Session Rate'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='child_initial_evaluation'
            data-test-id='child_initial_evaluation'
            type='text'
            component={InputField}
            label={
              differentialPricingRatesEnabled ? 'Initial Evaluation (minor) Rate' : 'Initial Evaluation (CPP) Rate'
            }
          />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='child_follow_up_session'
            data-test-id='child_follow_up_session'
            type='text'
            component={InputField}
            label={differentialPricingRatesEnabled ? 'Follow Up Session (minor) Rate' : 'Follow Up Session (CPP) Rate'}
          />
        </div>
      </div>
      {differentialPricingRatesEnabled && (
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='minor_medication_management_initial_evaluation'
              data-test-id='minor_medication_management_initial_evaluation'
              type='text'
              component={InputField}
              label='Minor Intake (prescriber) Rate'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name='minor_medication_management_follow_up_evaluation'
              data-test-id='minor_medication_management_follow_up_evaluation'
              type='text'
              component={InputField}
              label='Minor Follow Up (prescriber) Rate'
            />
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <Field
            name='alcohol_use_disorder_session'
            data-test-id='alcohol_use_disorder_session'
            type='text'
            component={InputField}
            label='Alcohol Use Disorder Session Therapy Rate'
          />
        </div>
      </div>

      {differentialPricingRatesEnabled && (
        <>
          <hr />
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  component={ToggleSwitchRFF}
                  label='Differential Pricing'
                  name='differential_pricing_enabled'
                  labelColor={colors.mediumGrayBlue}
                  offColor={colors.mediumGrayBlue}
                  onColor={colors.devBlue}
                  handleColor={colors.charcoal1}
                />
              </div>
            </div>

            {differentialPricingEnabled && (
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name='differential_pricing_in_person_modifier'
                  data-test-id='differential_pricing_in_person_modifier'
                  type='text'
                  component={InputField}
                  label='In Person Modifier'
                />
              </div>
            )}
          </div>
        </>
      )}

      <div className='row'>
        <div className='col-xs-12'>
          <PrimaryButton type='submit' id='test-providerrates-submit' isLoading={submitting}>
            {primaryButtonText}
          </PrimaryButton>
        </div>
      </div>
    </form>
  )
}

type ProviderRatesFormProps = {
  handleSubmit: Function
  submitting: boolean
  submitFunction: Function
  primaryButtonText: string
}

export default ProviderRatesForm
