import React, { FunctionComponent, ReactNode, useCallback, useRef } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import { PressableOpacity, PrimaryButton, Subhead, TertiaryButton, XIcon } from '../../atoms'
import { IS_WEB } from '../../constants'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { Modal } from '../modal/Modal'

const Container = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  flexGrow: 1,
  flexDirection: 'column',
  minHeight: '150px',
  padding: '0px',
}))

const Header = styled.View<{
  hasTitle: boolean
  theme: ThemeType
}>(({ hasTitle, theme }) => ({
  flexDirection: 'row',
  justifyContent: hasTitle ? 'space-between' : 'flex-end',
  backgroundColor: 'transparent',
  alignItems: 'flex-end',
  zIndex: 1,
  paddingBottom: theme.spacing['24px'],
  paddingTop: '0px',
  ...(IS_WEB && { cursor: 'default' }),
}))

const ContentsContainer = styled.View({
  alignItems: 'flex-start',
  flex: 1,
})

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMinWidthLaptop ? 'row-reverse' : 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing['16px'],
}))

const ConfirmButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['24px'],
  alignSelf: 'center',
  ...(theme.breakpoints.isMobileSized && {
    marginBottom: theme.spacing['16px'],
    width: '100%',
  }),
}))

const CancelButton = styled(TertiaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['24px'],
  alignSelf: 'center',
  ...(theme.breakpoints.isMobileSized && {
    marginBottom: theme.spacing['16px'],
    width: '100%',
  }),
}))

export interface ConfirmationModalProps {
  cancelButtonText: ReactNode
  confirmationButtonText: ReactNode
  modalContents: ReactNode
  modalTitle?: ReactNode
  onConfirmationButtonPress: () => void
  onRequestClose: () => void
  visible: boolean
  width?: string
  onCancelPressed?: () => void
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  cancelButtonText,
  confirmationButtonText,
  modalContents,
  modalTitle,
  onRequestClose,
  onConfirmationButtonPress,
  visible,
  width = '544px',
  onCancelPressed,
}) => {
  const { breakpoints, colors } = useTheme()
  const ref = useRef<BottomSheetGorhom>(null)

  const closeBottomSheet = useCallback(() => {
    ref?.current?.close()
  }, [])

  const modalContentsWithButtons = (
    <Container testID={tID('ConfirmationModalContainer')}>
      <Header hasTitle={Boolean(modalTitle)} testID={tID('ConfirmationModal-header')}>
        {modalTitle && (
          <Subhead size={breakpoints.isMobileSized ? SubheadSize.XSMALL : SubheadSize.SMALL} text={modalTitle} />
        )}
        <PressableOpacity onPress={onRequestClose}>
          <XIcon size={24} fillColor={colors.iconDefault} />
        </PressableOpacity>
      </Header>
      <ContentsContainer>{modalContents}</ContentsContainer>
      <ButtonsContainer>
        <ConfirmButton
          onPress={onConfirmationButtonPress}
          testID={tID('ConfirmationModalContainer-continueButton')}
          text={confirmationButtonText}
        />
        <CancelButton
          onPress={onCancelPressed ? onCancelPressed : onRequestClose}
          testID={tID('ConfirmationModalContainer-close-button')}
          text={cancelButtonText}
        />
      </ButtonsContainer>
    </Container>
  )

  if (!modalContents) {
    return null
  }

  return (
    <Modal
      modalTitle={modalTitle}
      onRequestClose={onRequestClose}
      onCloseEnd={() => {
        if (breakpoints?.isMobileSized) {
          closeBottomSheet()
        }
        onRequestClose()
      }}
      visible={visible}
      bottomSheetRef={ref}
      modalContents={modalContentsWithButtons}
      showCloseIcon
      useDynamicHeightForBottomSheet
      width={width}
    />
  )
}
