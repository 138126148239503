import React, { FunctionComponent, useMemo, useState } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'
import { LayoutChangeEvent, Platform } from 'react-native'

import { addMinutes } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  CameraVideoControlButton,
  CloseIcon,
  MicVideoControlButton,
  PrimaryButton,
  SettingsVideoControlButton,
  Subhead,
} from '../../atoms'
import TertiaryIconButton from '../../atoms/tertiaryIconButton/TertiaryIconButton'
import { useZoom } from '../../hooks/useZoom'
import { JoinSessionParams } from '../../hooks/useZoom/types'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ZoomSelfView } from '../zoomSelfView/ZoomSelfView'

const Container = styled.View(({ theme }) => ({
  gap: theme.spacing['24px'],
  height: '100%',
  width: '100%',
  alignItems: 'center',
  padding: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const TextContainer = styled.View<{ width: number }>(({ theme, width }) => ({
  gap: theme.spacing['16px'],
  alignItems: 'center',
  width,
  maxWidth: '100%',
}))

const VideoContainer = styled.View<{ width: number }>(({ width }) => ({
  flexGrow: 1,
  borderRadius: '16px',
  position: 'relative',
  width,
  overflow: 'hidden',
  height: 0,
  maxWidth: '100%',
  minWidth: '200px',
  minHeight: '112px',
}))

const JoinButton = styled(PrimaryButton)({
  alignSelf: 'center',
  width: '100%',
  maxWidth: '320px',
})

const ButtonsContainer = styled.View(({ theme }) => ({
  gap: theme.spacing['16px'],
  alignItems: 'center',
  flexDirection: 'row',
}))

const SessionDetailsContainer = styled.View(({ theme }) => ({
  gap: theme.spacing['4px'],
  alignItems: 'center',
}))

const CloseButton = styled(TertiaryIconButton)({
  alignSelf: 'flex-end',
  marginBottom: '-8px',
})

export type ZoomVideoCheckProps = {
  getSessionParams: () => Promise<JoinSessionParams>
  participantName: string
  sessionStartDate: Date
  sessionDuration: number
  sessionNumber?: number
  openSettings?: () => void
  isFocused?: boolean
}

export const ZoomVideoCheck: FunctionComponent<ZoomVideoCheckProps> = ({
  getSessionParams,
  participantName,
  sessionStartDate,
  sessionDuration,
  sessionNumber,
  openSettings,
  isFocused,
}) => {
  const {
    joinSession,
    toggleMute,
    toggleVideo,
    muted,
    videoOff,
    leaveSession,
    config: { shouldEndSessionOnLeave },
  } = useZoom()
  const theme = useTheme() as ThemeType
  const [width, setWidth] = useState(400)
  const [isJoinButtonDisabled, setIsJoinButtonDisabled] = useState(false)
  const isMobileSized = theme.breakpoints.isMobileSized
  const zoomVideoControlsColors = theme.colors.components.zoomVideoControls

  const sessionEndDate = useMemo(
    () => addMinutes(sessionStartDate, sessionDuration),
    [sessionStartDate, sessionDuration],
  )
  return (
    <Container>
      {isMobileSized && (
        <CloseButton
          iconColor={theme.colors.iconDefault}
          onPress={() => leaveSession({ end: shouldEndSessionOnLeave })}
          leftIcon={<CloseIcon size={20} />}
        />
      )}
      <TextContainer width={width}>
        <Subhead
          text={<FormattedMessage defaultMessage='Ready?' description='Title for video check' />}
          size={SubheadSize.LARGE}
        />
        <Subhead
          textAlign='center'
          text={
            isMobileSized ? (
              <FormattedMessage
                defaultMessage='Find a private spot with good connection. Headphones are recommended.'
                description='Subtitle for video check'
              />
            ) : (
              <FormattedMessage
                defaultMessage='Find a private spot with good connection. Headphones are recommended. Close other tabs and exit unnecessary applications to ensure the best video experience'
                description='Subtitle for video check'
              />
            )
          }
          size={SubheadSize.SMALL}
        />
      </TextContainer>
      <VideoContainer
        testID={tID('ZoomVideoCheck-self-view')}
        onLayout={(event: LayoutChangeEvent) => setWidth((event.nativeEvent.layout.height * 16) / 9)}
        width={width}
      >
        <ZoomSelfView isFullscreen isFocused={isFocused} />
      </VideoContainer>
      {isMobileSized && (
        <>
          <ButtonsContainer>
            <MicVideoControlButton
              size={64}
              onPress={toggleMute}
              disabled={muted}
              enabledColor={zoomVideoControlsColors.enabled.background}
              disabledColor={zoomVideoControlsColors.disabled.background}
              iconColor={zoomVideoControlsColors.icon.text}
            />
            <CameraVideoControlButton
              size={64}
              onPress={toggleVideo}
              disabled={videoOff}
              enabledColor={zoomVideoControlsColors.enabled.background}
              disabledColor={zoomVideoControlsColors.disabled.background}
              iconColor={zoomVideoControlsColors.icon.text}
            />
            {Platform.OS === 'web' && (
              <SettingsVideoControlButton
                size={64}
                onPress={() => openSettings?.()}
                disabled={false}
                enabledColor={zoomVideoControlsColors.enabled.background}
                iconColor={zoomVideoControlsColors.icon.text}
              />
            )}
          </ButtonsContainer>
          <SessionDetailsContainer>
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='{participantName} • Session {sessionNumber}'
                  description='Title of video session with participant name and session number'
                  values={{ participantName, sessionNumber }}
                />
              }
            />
            <BodyText
              size={BodyTextSize.SMALL}
              text={
                <FormattedDateTimeRange
                  from={sessionStartDate}
                  to={sessionEndDate}
                  hour='numeric'
                  minute='numeric'
                  timeZoneName='short'
                />
              }
            />
          </SessionDetailsContainer>
        </>
      )}
      <JoinButton
        testID={tID('ZoomVideoCheck-join-button')}
        text={<FormattedMessage defaultMessage='Join now' description='Button to join video session' />}
        onPress={() => {
          setIsJoinButtonDisabled(true)
          getSessionParams().then((sessionParams) => joinSession(sessionParams))
        }}
        disabled={isJoinButtonDisabled}
        fullWidth
        loading={isJoinButtonDisabled}
      />
    </Container>
  )
}
