import axios from 'axios'

import { logout, refreshApiToken } from '../../data/authActions'
import * as store from '../../data/store'

// Auth Instance ------
// set up auth instance, that sends token and listens for 401
const authInstance = axios.create({
  baseURL: '/api',
})
const CancelToken = axios.CancelToken

authInstance.interceptors.request.use(function (config) {
  const token = store.default.getState().get('auth')?.apiToken
  if (token && config.headers) {
    config.headers.Authorization = 'JWT ' + token
  }
  if (!config.cancelToken) {
    // allow for preattached cancel tokens, so specific call targeting is possible
    config.cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      ;(authInstance as any).cancelLastRequest = c
    })
  }
  return config
})

authInstance.interceptors.response.use(
  function (response) {
    if (response.headers['fresh-token']) {
      store.default.dispatch(refreshApiToken(response.headers['fresh-token']))
    }

    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // @ts-expect-error TS(2345): Argument of type '(dispatch: any) => Promise<unkno... Remove this comment to see the full error message
      store.default.dispatch(logout())
    }

    return Promise.reject(error)
  },
)

export const axiosAuthInstance = authInstance

// Generic Instance --------
// axios instance without any frills
export const axiosGenericInstance = axios.create({ baseURL: '/api' })
