import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

export type ProviderCalendarPopoverDescriptionProps = {
  description: string
}

const Container = styled.div({
  maxHeight: '400px',
  overflow: 'scroll',
})
export const ProviderCalendarPopoverDescription: FunctionComponent<ProviderCalendarPopoverDescriptionProps> = ({
  description,
}) => {
  return <Container dangerouslySetInnerHTML={{ __html: description }} />
}
