import { createReducer } from '@reduxjs/toolkit'

import {
  CustomerInfo,
  LyraCodeValidationInfo,
  Patient,
  ProviderAdminProviderInfo,
  Transfer,
} from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_LYRA_CODE_VALIDATION_INFO,
  CLEAR_REQUEST_PAYMENT_STORE,
  CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL,
  CLOSE_DUPLICATE_PAYMENT_MODAL,
  CLOSE_INELIGIBLE_CLIENT_MODAL,
  CLOSE_OUTCOME_REMINDER_MODAL,
  CLOSE_SESSIONS_EXCEEDED_MODAL,
  CLOSE_SESSIONS_MODAL,
  DISPLAY_INELIGIBLE_CLIENT_MODAL,
  GET_CHARGE_TO_COPY,
  GET_CLIENT,
  GET_CUSTOMERS,
  GET_CUSTOMERS_BY_COUNTRY,
  GET_PRACTICE_PROVIDERS,
  GET_PROVIDER_RATES,
  SELECT_PROVIDER,
  SET_ELIGIBILITY_STATUS,
  SET_LYRA_CODE_VALIDATION_INFO,
  SET_SELECTED_PROVIDER_CAPACITY,
  SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL,
  SHOW_DUPLICATE_PAYMENT_MODAL,
  SUBMIT_PAYMENT_REQUEST,
} from '../../common/constants/reduxConstants'

type RequestPaymentState = {
  showDuplicateModal: boolean
  showCancelledSessionsExceededModal: boolean
  rates?: {
    smp_session: string
    ssc_session: string
  }
  customers: CustomerInfo[]
  customersByCountry: {
    [key: string]: CustomerInfo[]
  }
  providersList?: ProviderAdminProviderInfo[]
  copy?: any
  patient?: Patient
  data: {
    session_tracking_status?: string | null
    outcome_reminder_status?: { show_reminder: boolean; outcome_sent_date: string }
    data?: Transfer
  }
  response?: {
    data: {
      session_tracking_status?: string | null
    }
  }
  lyraCodeValidationInfo?: LyraCodeValidationInfo
  eligibility?: {
    status: boolean
  }
  selectedProvider?: ProviderAdminProviderInfo
  showIneligibleClientModal?: boolean
}

const initialState = {
  showDuplicateModal: false,
  showCancelledSessionsExceededModal: false,
  data: {},
  customers: [],
  customersByCountry: {},
}

export default createReducer<RequestPaymentState>(initialState, (builder) => {
  builder.addCase(GET_PROVIDER_RATES, (state, action: any) => {
    state.rates = action.data.data
    return state
  })
  builder.addCase(SUBMIT_PAYMENT_REQUEST, (state, action: any) => {
    return {
      ...state,
      copy: undefined,
      patient: undefined,
      data: {
        ...state.data,
        ...action.payload.data,
      },
    }
  })
  builder.addCase(CLEAR_REQUEST_PAYMENT_STORE, (state, _: any) => {
    return {
      ...initialState,
      customers: state.customers,
    }
  })
  builder.addCase(`${GET_PRACTICE_PROVIDERS}_FULFILLED`, (state, action: any) => {
    state.providersList = action.payload.data.practice_providers
    return state
  })
  builder.addCase(`${GET_CHARGE_TO_COPY}_FULFILLED`, (state, action: any) => {
    state.copy = action.payload.data
    return state
  })
  builder.addCase(GET_CUSTOMERS, (state, action: any) => {
    const customers = action.data.customers ?? []
    // filter out customers that are internal only
    const externalCustomers = customers.filter((c: any) => !c.internal_customer)
    state.customers = externalCustomers
    return state
  })
  builder.addCase(GET_CUSTOMERS_BY_COUNTRY, (state, action: any) => {
    state.customersByCountry[action.country] = action.payload.customers.filter(
      (c: CustomerInfo) => !c.internal_customer,
    )
    return state
  })
  builder.addCase(CLOSE_SESSIONS_MODAL, (state, action: any) => {
    state.data.session_tracking_status = action.payload
    return state
  })
  builder.addCase(CLOSE_SESSIONS_EXCEEDED_MODAL, (state, action: any) => {
    state.response = {
      data: {
        session_tracking_status: action.payload,
      },
    }
    return state
  })
  builder.addCase(SHOW_DUPLICATE_PAYMENT_MODAL, (state) => {
    state.showDuplicateModal = true
    return state
  })
  builder.addCase(CLOSE_DUPLICATE_PAYMENT_MODAL, (state) => {
    state.showDuplicateModal = false
    return state
  })
  builder.addCase(SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL, (state) => {
    state.showCancelledSessionsExceededModal = true
    return state
  })
  builder.addCase(CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL, (state) => {
    state.showCancelledSessionsExceededModal = false
    return state
  })
  builder.addCase(DISPLAY_INELIGIBLE_CLIENT_MODAL, (state) => {
    state.showIneligibleClientModal = true
    return state
  })
  builder.addCase(CLOSE_INELIGIBLE_CLIENT_MODAL, (state) => {
    state.showIneligibleClientModal = false
    return state
  })
  builder.addCase(SELECT_PROVIDER, (state, action: any) => {
    const selectedProvider = state.providersList?.find((prov) => {
      return prov.lyra_id === action.payload
    })
    state.selectedProvider = selectedProvider
    return state
  })
  builder.addCase(`${GET_CLIENT}_FULFILLED`, (state, action: any) => {
    state.patient = action.payload.data.clients[0]
    return state
  })
  builder.addCase(CLOSE_OUTCOME_REMINDER_MODAL, (state) => {
    state.data.outcome_reminder_status = undefined
    return state
  })
  builder.addCase(SET_LYRA_CODE_VALIDATION_INFO, (state, action: any) => {
    state.lyraCodeValidationInfo = action.data
    return state
  })
  builder.addCase(CLEAR_LYRA_CODE_VALIDATION_INFO, (state) => {
    state.lyraCodeValidationInfo = undefined
    return state
  })
  builder.addCase(SET_ELIGIBILITY_STATUS, (state, action: any) => {
    state.eligibility = action.data.user_eligibility
    return state
  })
  builder.addCase(`${SET_SELECTED_PROVIDER_CAPACITY}_FULFILLED`, (state, action: any) => {
    if (!state.selectedProvider) {
      return state
    }
    const selectedProvider: ProviderAdminProviderInfo = {
      ...state.selectedProvider,
      capacity_value: action.payload.data.capacity_value,
      available: action.payload.data.available,
      capacity_updated_at: action.payload.data.capacity_updated_at,
    }
    const updatedProviders = state.providersList?.map((provider) => {
      if (provider.id === selectedProvider.id) return selectedProvider
      return provider
    })
    state.selectedProvider = selectedProvider
    state.providersList = updatedProviders
    return state
  })
})
