import { Feedback } from '@lyrahealth-inc/shared-app-logic'

import {
  GET_ALL_NOTES,
  GET_APPOINTMENT_SUMMARIES,
  GET_NOTES,
  GET_NOTES_FEEDBACK,
  SUBMIT_NOTE,
  UPDATE_NOTE,
  UPDATE_NOTE_FEEDBACK,
} from '../../../common/constants/reduxConstants'

export const getNotes = ({ cancelToken, episode_id, id_only }: any) => ({
  action: GET_NOTES,
  request: {
    method: 'get',
    url: '/lt/v1/notes',
    params: { episode_id, id_only },
    cancelToken,
  },
})

export const submitNote = (data: any) => ({
  action: SUBMIT_NOTE,

  request: {
    method: 'post',
    url: '/lt/v1/notes',
    data,
  },
})

export const updateNote = ({ note_id, ...data }: any) => ({
  action: UPDATE_NOTE,
  request: {
    method: 'put',
    url: `lt/v1/notes/${note_id}`,
    data,
  },
})

export const getAllNotes = ({ cancelToken, params }: any) => ({
  // params needs to be an URLSearchParams object here to allow for duplicate query params keys to be sent
  action: GET_ALL_NOTES,
  request: {
    method: 'get',
    url: '/lt/v1/notes',
    params: params,
    cancelToken,
  },
})

export const getAppointmentSummaries = ({ providerId, patientId, episodeId, statusOnly, appointmentId }: any) => ({
  action: GET_APPOINTMENT_SUMMARIES,
  request: {
    method: 'GET',
    url: `/v1/${providerId}/appointments/summaries`,
    params: { patient_id: patientId, episode_id: episodeId, status_only: statusOnly, appointment_id: appointmentId },
  },
})

export const getFeedback = ({ providerId, entityId }: { providerId: string; entityId: string }) => ({
  action: GET_NOTES_FEEDBACK,
  request: {
    method: 'get',
    url: '/lt/v1/feedback',
    params: { lyra_id: providerId, entity_id: entityId },
  },
})

export const submitFeedback = (data: Feedback) => ({
  action: UPDATE_NOTE_FEEDBACK,
  request: {
    method: 'POST',
    url: '/lt/v1/feedback',
    data,
  },
})
