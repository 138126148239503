import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { TextStyle, ViewStyle } from 'react-native'

import { isBoolean } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { getFontStyles } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

export type CollapsibleSectionHandle = {
  open: () => void
  close: () => void
}

export type CollapsibleSectionProps = {
  backgroundColor?: string
  buttonStyle?: ViewStyle | Array<ViewStyle>
  chevronColor?: string
  content: ReactNode
  contentInnerContainerStyle?: ViewStyle
  CustomExpansionIcon?: React.ReactElement
  handleIsExpandedChanged?: (isOpen: boolean) => void
  iconComponent?: ReactNode
  initiallyOpen?: boolean
  isExpanded?: boolean
  onAccordianStateChanged?: (isOpen?: boolean) => void
  onCollapsibleSectionPress?: () => void
  positionChevronTop?: boolean
  rightItems?: string | React.ReactElement
  showGradient?: boolean
  style?: ViewStyle
  subtitle?: ReactNode
  subtitleColor?: string
  testIDPrefix?: string
  title: ReactNode
  titleColor?: string
  titleStyle?: TextStyle
}

const Container = styled.View<{ backgroundColor: string; theme: ThemeType }>(({ theme, backgroundColor }) => ({
  backgroundColor,
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '12px',
}))

const HeaderContainerButton = styled(PressableOpacity)<{ isOpen: boolean; theme: ThemeType }>(({ isOpen, theme }) => ({
  borderRadius: 4,
  paddingVertical: theme.spacing['16px'],
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'space-between',
  display: 'flex',
  ...(isOpen && {
    borderBottomWidth: '1px',
    borderBottomColor: theme.colors.borderDefault,
  }),
}))

const LeftItemsContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  flex: 1,
})

const RightItemsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'flex-end',
  marginRight: theme.spacing['24px'],
  ...getFontStyles(theme.colors).body.small,
  color: theme.colors.textSecondary,
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  ...(theme.breakpoints.isMobileSized && { marginRight: theme.spacing['8px'] }),
}))

const Subtitle = styled(BodyText)(({ theme }) => ({
  textTransform: 'none',
  ...getFontStyles(theme.colors).body.small,
  lineHeight: theme.spacing['20px'],
  color: theme.colors.textSecondary,
}))

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['24px'],
  marginRight: theme.spacing['16px'],
}))

const ChevronContainer = styled.View<{ isOpen: boolean }>(({ isOpen }) => ({
  marginLeft: '2px',
  marginRight: '2px',
  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
  ...(IS_WEB && { transition: '300ms ease-in-out' }),
}))

const ContentOuterContainer = styled.View<{ contentHeight: number; isOpen: boolean; theme: ThemeType }>(
  ({ contentHeight, isOpen }) => ({
    position: 'relative',
    overflow: 'hidden',
    zIndex: -1,
    display: 'grid',
    ...(IS_WEB && {
      gridTemplateRows: isOpen ? contentHeight : '0',
      transition: '250ms grid-template-rows ease',
    }),
  }),
)

const ContentInnerContainer = styled.View<{ isOpen: boolean; theme: ThemeType }>(({ theme, isOpen }) => ({
  position: 'absolute',
  width: '100%',
  bottom: theme.spacing['0px'],
  ...(IS_WEB && {
    opacity: isOpen ? 1 : 0,
    transition: '300ms ease-in-out',
  }),
}))

/**
 * A component to show/hide additional content
 */
export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  backgroundColor,
  buttonStyle,
  chevronColor,
  content,
  contentInnerContainerStyle,
  handleIsExpandedChanged,
  initiallyOpen = false,
  isExpanded,
  onAccordianStateChanged,
  onCollapsibleSectionPress,
  rightItems,
  style,
  subtitle,
  title,
  titleColor,
}) => {
  const isWithinGroup = isBoolean(isExpanded) && handleIsExpandedChanged

  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(isWithinGroup ? isExpanded : initiallyOpen)

  const componentRef = useRef(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (componentRef.current) {
      const { height: contentHeight } = (componentRef.current as HTMLElement).getBoundingClientRect()
      setHeight(contentHeight)
    }
  }, [])

  const switchCollapsedStatus = () => {
    onCollapsibleSectionPress && !isOpen && onCollapsibleSectionPress()
    if (isWithinGroup) {
      handleIsExpandedChanged(!isOpen)
    } else {
      onAccordianStateChanged && onAccordianStateChanged(!isOpen)
      setIsOpen(!isOpen)
    }
  }

  return (
    <Container
      testID={tID('CollapsibleSection-container')}
      backgroundColor={backgroundColor || colors.backgroundPrimary}
      style={style}
    >
      <HeaderContainerButton
        isOpen={isOpen}
        onPress={switchCollapsedStatus}
        testID={tID('CollapsibleSection-titleButton')}
        accessibilityRole='button'
        accessibilityLabel={formatMessage({
          defaultMessage: 'Expand or collapse section',
          description: 'button text expand or collapse collapsible section',
        })}
        style={buttonStyle}
      >
        <LeftItemsContainer>
          <IconContainer testID={tID('CollapsibleSection-chevronIconContainer')}>
            <ChevronContainer isOpen={isOpen}>
              <ChevronIcon
                fillColor={chevronColor || titleColor || colors.textPrimary}
                size={20}
                direction={ChevronIconDirection.RIGHT}
              />
            </ChevronContainer>
          </IconContainer>
          <TitleContainer>
            <Subhead
              selectable={false}
              text={title}
              size={SubheadSize.MEDIUM}
              nativeID={'CollapsibleSection-title'}
              wrap
              noRole
            />
            {subtitle && <Subtitle selectable={false} text={subtitle} nativeID={'CollapsibleSection-subtitle'} />}
          </TitleContainer>
        </LeftItemsContainer>
        {rightItems && <RightItemsContainer>{rightItems}</RightItemsContainer>}
      </HeaderContainerButton>
      <ContentOuterContainer
        isOpen={isOpen}
        contentHeight={height}
        testID={tID('CollapsibleSection-contentOuterContainer')}
      >
        <ContentInnerContainer
          accessibilityElementsHidden={!isOpen}
          accessibilityLabelledBy={'CollapsibleSection-contentInnerContainer'}
          nativeID={'CollapsibleSection-contentInnerContainer'}
          style={contentInnerContainerStyle}
          isOpen={isOpen}
          ref={componentRef}
        >
          {content}
        </ContentInnerContainer>
      </ContentOuterContainer>
    </Container>
  )
}
