import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Dimensions } from 'react-native'

import * as Sentry from '@sentry/react'
import { useTheme } from 'styled-components/native'
import UAParser from 'ua-parser-js'

import { IO_DEVICES, ZOOM_EVENTS, ZOOM_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'

import { ZOOM_MINI_VIEW_HEIGHT, ZOOM_MINI_VIEW_WIDTH } from './constants'
import {
  Camera,
  DeviceSettings,
  JoinSessionParams,
  LeaveSessionParams,
  NETWORK_QUALITY_MAP,
  Participant,
  VideoCallEffect,
  ZoomCommand,
  ZoomNetworkQuality,
  ZoomPlatformSpecificFunction,
} from './types'
import { useZoomStream } from './useZoomStream'
import { useZoomToast, ZoomToast } from './useZoomToast'
import { getErrorObject, getVideoEffectURL, VB_BROWSERS } from './utils'
import ZoomVideo, {
  AudioChangeAction,
  ConnectionChangePayload,
  ConnectionState,
  LocalVideoTrack,
  MobileVideoFacingMode,
  MutedSource,
  ReconnectReason,
  VideoCapturingState,
  VideoQuality,
} from './ZoomVideo'
import { AppContext } from '../../context'
import { ThemeType } from '../../utils'
import { useGetIsMobileWebBrowser } from '../useGetIsMobileWebBrowser'

const LEAVE_DELAY_ON_END_COMMAND_SEND_MS = 500

export const useZoomPlatformSpecific: ZoomPlatformSpecificFunction = ({
  sessionEndedCallback,
  sessionClosedCallback,
  addToast,
  attached,
  setNetworkQuality,
}) => {
  const {
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  const client = useMemo(() => ZoomVideo.createClient(), [])
  const stream = useZoomStream()
  const [muted, setMuted] = useState(true)
  const [sessionVideoOff, setSessionVideoOff] = useState(true)
  const [localVideoOff, setLocalVideoOff] = useState(true)
  const [cameraList, setCameraList] = useState<Camera[]>([])
  const [remoteParticipants, setRemoteParticipants] = useState<Participant[]>([])
  const [inSession, setInSession] = useState(false)
  const [selfElement, setSelfElement] = useState<HTMLCanvasElement | HTMLVideoElement | null>(null)
  const [sharingUserId, setSharingUserId] = useState<number | null>(null)
  const [screenshareElement, setScreenshareElement] = useState<HTMLCanvasElement | HTMLVideoElement | null>(null)
  const [videoEffect, setVideoEffectEnum] = useState<VideoCallEffect>(
    (localStorage.getItem(IO_DEVICES.EFFECT) as VideoCallEffect | null) || VideoCallEffect.NONE,
  )
  const [sessionStarted, setSessionStarted] = useState(false)
  const prevSelfElement = useRef<HTMLCanvasElement | HTMLVideoElement | null>(null)
  const [participantElement, setParticipantElement] = useState<HTMLCanvasElement | null>(null)
  const prevParticipantElement = useRef<HTMLCanvasElement | null>(null)
  const currentDisplayedParticipant = useRef<number | null>(null)
  const [previewVideoElement, setPreviewVideoElement] = useState<HTMLVideoElement | null>(null)
  const prevPreviewElement = useRef<HTMLVideoElement | HTMLCanvasElement | null>(null)
  const localVideoTrack = useRef<LocalVideoTrack | null>(null)
  const [participantScreenshareElement, setParticipantScreenshareElement] = useState<HTMLCanvasElement | null>(null)
  const [joinTime, setJoinTime] = useState<Date | null>(null)
  const [isOnline, setIsOnline] = useState(true)
  const [isReconnecting, setIsReconnecting] = useState(false)
  const hasFailedOver = useRef(false)
  const [showPermissionsModal, setShowPermissionsModal] = useState(false)

  const addZoomToast = useZoomToast(addToast)

  const isMobileBrowser = useGetIsMobileWebBrowser()

  const [videoInput, setVideoInput] = useState<string | null>(localStorage.getItem(IO_DEVICES.CAMERA) || null)
  const [audioInput, setAudioInput] = useState<string | null>(localStorage.getItem(IO_DEVICES.MICROPHONE) || null)
  const [audioOutput, setAudioOutput] = useState<string | null>(localStorage.getItem(IO_DEVICES.SPEAKER) || null)
  const { trackEvent } = useContext(AppContext)

  const videoOff = useMemo(
    () => (sessionStarted ? sessionVideoOff : localVideoOff),
    [localVideoOff, sessionStarted, sessionVideoOff],
  )

  const onSessionClosedOrEndedCallback = useCallback(
    (rejoining: boolean) => {
      sessionStarted && !rejoining ? sessionEndedCallback?.() : sessionClosedCallback?.()
      setInSession(false)
      setSessionStarted(false)
    },
    [sessionClosedCallback, sessionEndedCallback, sessionStarted],
  )
  const supportsVirtualBackground = useMemo(() => {
    const userAgent = new UAParser(window.navigator.userAgent)
    const browserName = userAgent.getResult().browser.name
    return !isMobileBrowser && browserName != null && VB_BROWSERS.includes(browserName)
  }, [isMobileBrowser])

  useEffect(() => {
    const networkChanged = () => setIsOnline(navigator.onLine)
    window.addEventListener('online', networkChanged)
    window.addEventListener('offline', networkChanged)
    return () => {
      window.removeEventListener('online', networkChanged)
      window.removeEventListener('offline', networkChanged)
    }
  }, [])

  useEffect(() => {
    if (!inSession) {
      return
    }
    if (!isOnline) {
      if (!isReconnecting) {
        setIsReconnecting(true)
        addZoomToast(ZoomToast.RECONNECTING)
      }
    } else {
      if (isReconnecting && !hasFailedOver.current) {
        setIsReconnecting(false)
        addZoomToast(ZoomToast.RECONNECTED)
      }
    }
  }, [addZoomToast, inSession, isOnline, isReconnecting])

  const onConnectionChange = useCallback(
    (payload: ConnectionChangePayload) => {
      switch (payload.state) {
        case ConnectionState.Connected:
          setInSession(true)
          break
        case ConnectionState.Reconnecting:
          if (payload.reason === ReconnectReason.Failover) {
            hasFailedOver.current = true
            addZoomToast(ZoomToast.DISCONNECTED)
          }
          break
        case ConnectionState.Closed:
          if (payload.reason === 'ended by host') {
            onSessionClosedOrEndedCallback(false)
          } else {
            setInSession(false)
          }
          break
      }
    },
    [addZoomToast, onSessionClosedOrEndedCallback],
  )

  const onCurrentAudioChange = useCallback(
    async (payload: { action: AudioChangeAction; type?: 'phone' | 'computer'; source?: MutedSource }) => {
      switch (payload.action) {
        case AudioChangeAction.Join:
          setTimeout(() => {
            setMuted(!!client.getCurrentUserInfo()?.muted)
          }, 1000)
          break
        case AudioChangeAction.Leave:
          setMuted(true)
          break
        case AudioChangeAction.Muted:
          setMuted(true)
          break
        case AudioChangeAction.Unmuted:
          setMuted(false)
          break
      }
    },
    [client, setMuted],
  )

  const onVideoCapturingChange = useCallback(
    async (payload: { state: VideoCapturingState }) => {
      switch (payload.state) {
        case VideoCapturingState.Started:
          setSessionVideoOff(false)
          break
        case VideoCapturingState.Stopped:
          setSessionVideoOff(true)
          break
      }
    },
    [setSessionVideoOff],
  )

  const onDeviceChange = useCallback(async () => {
    ZoomVideo.getDevices().then((devices) => {
      const videoInputs = devices
        .filter((device) => device.kind === 'videoinput')
        .map((device) => ({ deviceId: device.deviceId, deviceName: device.label }))
      setCameraList(videoInputs)
      if (videoInput && !videoInputs.find((input) => input.deviceId === videoInput)) {
        setVideoInput(null)
      }
      const audioInputs = devices.filter((device) => device.kind === 'audioinput')
      if (audioInput && !audioInputs.find((input) => input.deviceId === audioInput)) {
        setAudioInput(null)
      }

      const audioOutputs = devices.filter((device) => device.kind === 'audiooutput')
      if (audioOutput && !audioOutputs.find((input) => input.deviceId === audioOutput)) {
        setAudioOutput(null)
      }
    })
  }, [audioInput, audioOutput, videoInput])

  useEffect(() => {
    if (!attached) {
      return
    }
    onDeviceChange()
    navigator.mediaDevices.addEventListener('devicechange', onDeviceChange)
    return () => navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange)
  }, [attached, onDeviceChange])

  const startAudio = useCallback(async () => {
    trackEvent?.(ZOOM_EVENTS.START_AUDIO, {
      [ZOOM_PROPERTIES.STREAM_EXISTS]: !!stream,
      [ZOOM_PROPERTIES.MUTED]: muted,
    })
    if (!stream) {
      return
    }

    try {
      await stream.startAudio({ mute: muted })
      if (audioInput) {
        await stream.switchMicrophone(audioInput)
      }

      if (audioOutput) {
        await stream.switchSpeaker(audioOutput)
      }

      trackEvent?.(ZOOM_EVENTS.START_AUDIO_SUCCESS, {
        [ZOOM_PROPERTIES.MUTED]: muted,
      })
    } catch (error) {
      trackEvent?.(ZOOM_EVENTS.START_AUDIO_ERROR, {
        [ZOOM_PROPERTIES.ERROR]: getErrorObject(error),
        [ZOOM_PROPERTIES.MUTED]: muted,
      })
      if (error.type === 'INSUFFICIENT_PRIVILEGES' && error.reason === 'USER_FORBIDDEN_MICROPHONE') {
        setShowPermissionsModal(true)
      }
    }
  }, [audioInput, audioOutput, muted, stream, trackEvent])

  const toggleMute = useCallback(async () => {
    trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE, {
      [ZOOM_PROPERTIES.STREAM_EXISTS]: !!stream,
      [ZOOM_PROPERTIES.MUTED]: muted,
      [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
    })
    if (!sessionStarted) {
      if (muted) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
          if (stream) {
            setMuted(false)
          }

          trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE_SUCCESS, {
            [ZOOM_PROPERTIES.MUTED]: false,
            [ZOOM_PROPERTIES.SESSION_STARTED]: false,
          })
        } catch (error) {
          trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE_ERROR, {
            [ZOOM_PROPERTIES.MUTED]: muted,
            [ZOOM_PROPERTIES.ERROR]: getErrorObject(error),
          })
          setShowPermissionsModal(true)
        }
      } else {
        setMuted(true)
        trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE_SUCCESS, {
          [ZOOM_PROPERTIES.MUTED]: true,
          [ZOOM_PROPERTIES.SESSION_STARTED]: false,
        })
      }
      return
    }

    if (!stream) {
      return
    }

    try {
      if (muted) {
        if (!client.getCurrentUserInfo()?.audio) {
          await startAudio()
        }
        await stream.unmuteAudio()
      } else {
        await stream.muteAudio()
      }
      trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE_SUCCESS, {
        [ZOOM_PROPERTIES.MUTED]: !muted,
        [ZOOM_PROPERTIES.SESSION_STARTED]: true,
      })
    } catch (error) {
      trackEvent?.(ZOOM_EVENTS.TOGGLE_MUTE_ERROR, {
        [ZOOM_PROPERTIES.MUTED]: muted,
        [ZOOM_PROPERTIES.ERROR]: getErrorObject(error),
      })
    }
  }, [trackEvent, stream, muted, sessionStarted, client, startAudio])

  const getDeviceIdIfMobile = useCallback(
    (deviceId: string | null) => {
      if (
        !deviceId ||
        !isMobileBrowser ||
        !Object.values(MobileVideoFacingMode).includes(deviceId as MobileVideoFacingMode)
      ) {
        return deviceId
      }

      const deviceToFindRegex = deviceId === MobileVideoFacingMode.User ? /front/i : /(rear|back)/i
      const matchingDevice = cameraList.find((camera) => camera.deviceName.match(deviceToFindRegex))
      return matchingDevice ? matchingDevice.deviceId : null
    },
    [cameraList, isMobileBrowser],
  )

  const getShouldStopVideo = useCallback(
    (deviceId: string | null, elementToAttach: HTMLCanvasElement | HTMLVideoElement) => {
      if (localVideoOff) {
        return false
      }

      if (prevPreviewElement.current !== elementToAttach) {
        return true
      }

      if (videoInput !== deviceId) {
        return true
      }

      return false
    },
    [localVideoOff, videoInput],
  )

  const restartLocalVideo = useCallback(
    async (deviceId: string | null) => {
      const realDeviceId = getDeviceIdIfMobile(deviceId)
      const elementToAttach = previewVideoElement
      if (!elementToAttach) {
        return
      }

      const shouldStopVideo = getShouldStopVideo(deviceId, elementToAttach)
      if (shouldStopVideo) {
        try {
          await localVideoTrack.current?.stop()
        } catch {}
      }

      if (localVideoTrack.current) {
        ;(localVideoTrack.current as any).deviceId = realDeviceId
        await localVideoTrack.current.start(elementToAttach)
      } else {
        localVideoTrack.current = ZoomVideo.createLocalVideoTrack(realDeviceId ?? undefined)
        await localVideoTrack.current.start(elementToAttach)
      }
      prevPreviewElement.current = elementToAttach
    },
    [getDeviceIdIfMobile, getShouldStopVideo, previewVideoElement],
  )

  const startLocalVideo = useCallback(async () => {
    await restartLocalVideo(videoInput)
    setLocalVideoOff(false)
  }, [restartLocalVideo, videoInput])

  const stopLocalVideo = useCallback(async () => {
    try {
      await localVideoTrack.current?.stop()
    } catch {}
    setLocalVideoOff(true)
    prevPreviewElement.current = null
  }, [])
  const startVideo = useCallback(
    async (selfElement: HTMLVideoElement | HTMLCanvasElement) => {
      if (!stream) {
        return
      }
      try {
        stream && (await stream.detachVideo(client.getCurrentUserInfo()?.userId))
      } catch (err) {
        Sentry.addBreadcrumb({
          category: 'Zoom',
          data: {
            videoInput,
            selfElement,
            prevSelfElement: prevSelfElement?.current,
            sessionInfo: client.getSessionInfo,
          },
        })
        Sentry.captureException(err)
      }
      const urlForEffect = getVideoEffectURL(videoEffect)
      if (stream.isRenderSelfViewWithVideoElement()) {
        await stream.startVideo({
          videoElement: selfElement as HTMLVideoElement,
          cameraId: videoInput ?? undefined,
          mirrored: true,
          captureHeight: isMobileSized ? ZOOM_MINI_VIEW_HEIGHT : undefined,
          captureWidth: isMobileSized ? ZOOM_MINI_VIEW_WIDTH : undefined,
          virtualBackground: urlForEffect
            ? {
                imageUrl: urlForEffect,
              }
            : undefined,
        })
      } else {
        await stream.startVideo({
          cameraId: videoInput ?? undefined,
          mirrored: true,
          virtualBackground: urlForEffect
            ? {
                imageUrl: urlForEffect,
              }
            : undefined,
        })
        await stream.renderVideo(
          selfElement as HTMLCanvasElement,
          client.getCurrentUserInfo()?.userId,
          1920,
          1080,
          0,
          0,
          stream.isSupportHDVideo() ? VideoQuality.Video_720P : VideoQuality.Video_360P,
        )
      }
      prevSelfElement.current = selfElement
    },
    [client, isMobileSized, stream, videoEffect, videoInput],
  )

  const stopVideo = useCallback(
    async (selfElement: HTMLVideoElement | HTMLCanvasElement) => {
      if (!stream) {
        return
      }

      await stream.stopVideo()
      if (selfElement instanceof HTMLCanvasElement) {
        await stream.stopRenderVideo(selfElement as HTMLCanvasElement, client.getCurrentUserInfo()?.userId)
      }
      prevSelfElement.current = null
    },
    [client, stream],
  )

  const toggleLocalVideo = useCallback(async () => {
    if (!previewVideoElement) {
      return
    }
    if (videoOff) {
      await startLocalVideo()
    } else {
      await stopLocalVideo()
    }
  }, [previewVideoElement, startLocalVideo, stopLocalVideo, videoOff])

  const toggleVideo = useCallback(async () => {
    trackEvent?.(ZOOM_EVENTS.TOGGLE_VIDEO, {
      [ZOOM_PROPERTIES.STREAM_EXISTS]: !!stream,
      [ZOOM_PROPERTIES.VIDEO_OFF]: videoOff,
      [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
    })
    if (!sessionStarted) {
      try {
        await toggleLocalVideo()
        trackEvent?.(ZOOM_EVENTS.TOGGLE_VIDEO_SUCCESS, {
          [ZOOM_PROPERTIES.VIDEO_OFF]: !videoOff,
          [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
        })
      } catch (error) {
        trackEvent?.(ZOOM_EVENTS.TOGGLE_VIDEO_ERROR, {
          [ZOOM_PROPERTIES.ERROR]: getErrorObject(error),
          [ZOOM_PROPERTIES.VIDEO_OFF]: videoOff,
          [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
        })
        setShowPermissionsModal(true)
      }
      return
    }
    if (!stream || !selfElement) {
      return
    }
    try {
      if (videoOff) {
        await startVideo(selfElement)
      } else {
        await stopVideo(selfElement)
      }
      trackEvent?.(ZOOM_EVENTS.TOGGLE_VIDEO_SUCCESS, {
        [ZOOM_PROPERTIES.VIDEO_OFF]: !videoOff,
        [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
      })
    } catch (error) {
      trackEvent?.(ZOOM_EVENTS.TOGGLE_VIDEO_ERROR, {
        [ZOOM_PROPERTIES.ERROR]: getErrorObject(error),
        [ZOOM_PROPERTIES.VIDEO_OFF]: videoOff,
        [ZOOM_PROPERTIES.SESSION_STARTED]: sessionStarted,
      })
      Sentry.addBreadcrumb({
        category: 'Zoom',
        data: {
          selfElement,
          videoOff,
          sessionStarted,
          error,
          stream,
          prevSelfElement: prevSelfElement?.current,
        },
      })
      if (error.type === 'VIDEO_USER_FORBIDDEN_CAPTURE') {
        setShowPermissionsModal(true)
      }
      if (error.type === 'INVALID_OPERATION' && error.reason === 'camera is closed') {
        try {
          await stream.startVideo()
        } catch {
          Sentry.captureException(error)
        }
      }

      Sentry.captureException(error)
    }
  }, [trackEvent, stream, videoOff, sessionStarted, selfElement, toggleLocalVideo, startVideo, stopVideo])

  useEffect(() => {
    async function switchVideo() {
      if (
        (sessionVideoOff && localVideoOff) ||
        !sessionStarted ||
        !selfElement ||
        selfElement.isEqualNode(prevSelfElement.current)
      ) {
        if (!selfElement && prevSelfElement.current) {
          await stopVideo(prevSelfElement.current)
        }
        return
      }

      if (prevSelfElement.current) {
        await stopVideo(prevSelfElement.current).catch((err) => {
          Sentry.addBreadcrumb({
            category: 'Zoom',
            data: {
              sessionStarted,
              sessionVideoOff,
              localVideoOff,
              selfElement,
              prevSelfElement: prevSelfElement?.current,
              videoOff,
              sessionInfo: client.getSessionInfo,
            },
          })
          Sentry.captureException(err)
        })
      }
      setTimeout(
        () =>
          startVideo(selfElement).catch((err) => {
            Sentry.addBreadcrumb({
              category: 'Zoom',
              data: {
                sessionStarted,
                sessionVideoOff,
                localVideoOff,
                selfElement,
                prevSelfElement: prevSelfElement?.current,
                videoOff,
                sessionInfo: client.getSessionInfo,
              },
            })
            Sentry.captureException(err)
          }),
        1000,
      )
    }

    switchVideo()
  }, [localVideoOff, selfElement, sessionStarted, sessionVideoOff, startVideo, stopVideo, videoOff])

  useEffect(() => {
    async function switchLocalVideo() {
      if (
        (sessionVideoOff && localVideoOff) ||
        sessionStarted ||
        !previewVideoElement ||
        previewVideoElement === prevPreviewElement.current
      ) {
        if (!previewVideoElement && prevPreviewElement.current) {
          await stopLocalVideo()
        }
        return
      }

      await restartLocalVideo(videoInput)
      setLocalVideoOff(false)
    }

    switchLocalVideo()
  }, [
    localVideoOff,
    previewVideoElement,
    restartLocalVideo,
    sessionStarted,
    sessionVideoOff,
    startLocalVideo,
    stopLocalVideo,
    videoEffect,
    videoInput,
    videoOff,
  ])

  const getNextCameraDevice = useCallback(() => {
    if (!stream || cameraList.length === 0) {
      return null
    }

    const activeCamera = stream.getActiveCamera()
    if (!activeCamera) {
      return cameraList[0].deviceId
    }

    if (activeCamera === 'default') {
      return cameraList.length > 1 ? cameraList[1].deviceId : cameraList[0].deviceId
    }
    const currCameraIdx = cameraList.findIndex((c) => c.deviceId === activeCamera)
    if (currCameraIdx === -1) {
      return cameraList[0].deviceId
    }

    return cameraList[(currCameraIdx + 1) % cameraList.length].deviceId
  }, [cameraList, stream])

  const setVideoEffect = useCallback(
    async (effect: VideoCallEffect) => {
      if (!stream) {
        return
      }

      const url = getVideoEffectURL(effect)
      await stream.updateVirtualBackgroundImage(url)
      setVideoEffectEnum(effect)
    },
    [stream],
  )

  const switchCameraAndEffectLocal = useCallback(
    async (deviceId: string | null) => {
      await restartLocalVideo(deviceId)
      setVideoInput(deviceId)
    },
    [restartLocalVideo],
  )

  const switchCameraAndVideoEffect = useCallback(
    async (deviceId: string | null = null, videoEffect: VideoCallEffect) => {
      if (videoOff) {
        setVideoInput(deviceId)
        setVideoEffectEnum(videoEffect)
        return
      }

      if (!sessionStarted) {
        if (deviceId !== videoInput) {
          await switchCameraAndEffectLocal(deviceId)
        }
        setVideoEffectEnum(videoEffect)
        return
      }
      if (!stream) {
        return
      }

      if (!deviceId) {
        deviceId = getNextCameraDevice()
      }

      if (!deviceId) {
        return
      }
      await stream.switchCamera(deviceId)
      setVideoInput(deviceId)
      await setVideoEffect(videoEffect)
    },
    [videoOff, sessionStarted, stream, setVideoEffect, videoInput, switchCameraAndEffectLocal, getNextCameraDevice],
  )

  const stopAllVideo = useCallback(async () => {
    try {
      await localVideoTrack.current?.stop()
    } catch (error) {}
    try {
      await client.getMediaStream()?.stopVideo()
    } catch (error) {}

    setLocalVideoOff(true)
    setSessionVideoOff(true)
  }, [client])

  useEffect(() => {
    client.init('en-US', 'Global', {
      patchJsMedia: true,
      leaveOnPageUnload: true,
      stayAwake: true,
      enforceVirtualBackground: supportsVirtualBackground,
    })
    return () => {
      const destroyClient = async () => {
        await stopAllVideo()
        ZoomVideo.destroyClient()
      }
      destroyClient()
    }
  }, [client, stopAllVideo, supportsVirtualBackground])

  const joinSession = useCallback(
    (params: JoinSessionParams) =>
      client
        .join(params.sessionName, params.token, params.userName, params.sessionPassword)
        .then(() => setJoinTime(new Date())),
    [client],
  )

  const leaveSession = useCallback(
    async (params: LeaveSessionParams | undefined) => {
      const end = params?.end ?? false
      const rejoining = params?.rejoining ?? false
      await stopAllVideo()
      if (!inSession) {
        sessionClosedCallback?.()
        return
      }
      if (end) {
        try {
          client
            .getCommandClient()
            .send(ZoomCommand.END_SESSION)
            .then(() => {
              setTimeout(() => {
                client.leave(true).then(() => {
                  onSessionClosedOrEndedCallback(rejoining)
                })
              }, LEAVE_DELAY_ON_END_COMMAND_SEND_MS)
            })
        } catch (err) {
          Sentry.addBreadcrumb({
            category: 'Zoom',
            data: {
              inSession,
              client,
              end,
              rejoining,
            },
          })
          Sentry.captureException(err)
        }
      } else {
        client.leave(false).then(() => {
          onSessionClosedOrEndedCallback(rejoining)
        })
      }
    },
    [client, inSession, onSessionClosedOrEndedCallback, sessionClosedCallback, stopAllVideo],
  )
  const onUsersChange = useCallback(() => {
    const users = client.getAllUser()
    const self = client.getCurrentUserInfo()
    setRemoteParticipants((prevRemoteParticipants) => {
      const prevMap = Object.fromEntries(
        prevRemoteParticipants.map((prevParticipant) => [prevParticipant.userId, prevParticipant]),
      )

      return users
        .filter((user) => user.userId !== self?.userId)
        .map((user) => ({
          userId: user.userId,
          isVideoOn: user.bVideoOn,
          isMuted: user.muted ?? true,
          joinTime: user.userId in prevMap ? prevMap[user.userId].joinTime : new Date(),
          userIdentity: user.userIdentity,
        }))
    })
  }, [client, setRemoteParticipants])

  const stopScreenshare = useCallback(() => {
    if (!stream) {
      return
    }

    stream.stopShareScreen().then(() => {
      setSharingUserId(null)
      stream.stopShareView()
      trackEvent?.(ZOOM_EVENTS.STOP_SCREENSHARE)
    })
  }, [stream])

  const startScreenshare = useCallback(() => {
    if (!stream || !screenshareElement || screenshareElement instanceof HTMLCanvasElement) {
      return
    }

    stream
      .startShareScreen(screenshareElement, {
        controls: { monitorTypeSurfaces: 'exclude', selfBrowserSurface: 'include' },
      })
      .then(() => {
        trackEvent?.(ZOOM_EVENTS.START_SCREENSHARE)
        setSharingUserId(client.getCurrentUserInfo()?.userId)
        if (screenshareElement.srcObject instanceof MediaStream) {
          if (screenshareElement.srcObject.getTracks()[0].getSettings()?.displaySurface !== 'browser') {
            addZoomToast(ZoomToast.SCREENSHARE)
            stopScreenshare()
          }
        }
      })
  }, [stream, screenshareElement, client, addZoomToast, stopScreenshare])

  const onPeerShareStateChange = useCallback((payload: { userId: number; action: 'Start' | 'Stop' }) => {
    setSharingUserId(payload.action === 'Start' ? payload.userId : null)
  }, [])

  const onPassivelyStopShare = useCallback(() => {
    setSharingUserId(null)
    stream?.stopShareView()
    trackEvent?.(ZOOM_EVENTS.STOP_SCREENSHARE)
  }, [stream])

  const onCommandChannelMessage = useCallback(({ text: command }: { text: ZoomCommand }) => {
    switch (command) {
      case ZoomCommand.START_SESSION:
        setSessionStarted(true)
        break
    }
  }, [])

  const startSession = useCallback(() => {
    client
      .getCommandClient()
      .send(ZoomCommand.START_SESSION)
      .then(() => {
        setSessionStarted(true)
      })
  }, [client])

  const remoteParticipant = useMemo(
    () => (remoteParticipants.length > 0 ? remoteParticipants[0] : null),
    [remoteParticipants],
  )

  useEffect(() => {
    async function handleParticipantChange() {
      if (!participantElement || !stream) {
        return
      }

      if (prevParticipantElement.current && currentDisplayedParticipant.current) {
        await stream.stopRenderVideo(prevParticipantElement.current, currentDisplayedParticipant.current)
        currentDisplayedParticipant.current = null
        prevParticipantElement.current = null
      }
      if (remoteParticipant?.isVideoOn) {
        const width = Math.min(1920, Dimensions.get('window').width)
        await stream.renderVideo(
          participantElement,
          remoteParticipant.userId as number,
          width,
          width * (9 / 16),
          0,
          0,
          stream.isSupportHDVideo() && !isMobileBrowser ? VideoQuality.Video_720P : VideoQuality.Video_360P,
        )
        currentDisplayedParticipant.current = remoteParticipant.userId as number
        prevParticipantElement.current = participantElement
      }
    }
    handleParticipantChange()
  }, [stream, remoteParticipant?.isVideoOn, remoteParticipant?.userId, participantElement, isMobileBrowser])

  useEffect(() => {
    if (!videoInput) {
      if (isMobileBrowser) {
        setVideoInput(MobileVideoFacingMode.User)
      } else if (cameraList.length > 0) {
        setVideoInput(cameraList[0].deviceId)
      }
    }
  }, [cameraList, isMobileBrowser, videoInput])

  const switchMicrophone = useCallback(
    (deviceId: string) => {
      if (!sessionStarted) {
        setAudioInput(deviceId)
        return
      }

      if (!stream) {
        return
      }
      stream.switchMicrophone(deviceId).then(() => setAudioInput(deviceId))
    },
    [sessionStarted, stream],
  )

  const switchSpeaker = useCallback(
    (deviceId: string) => {
      if (!sessionStarted) {
        setAudioOutput(deviceId)
        return
      }

      if (!stream) {
        return
      }
      stream.switchSpeaker(deviceId).then(() => setAudioOutput(deviceId))
    },
    [sessionStarted, stream],
  )

  const updateSettings = useCallback(
    (settings: DeviceSettings) => {
      if (settings.videoInput && (settings.videoInput !== videoInput || settings.videoEffect !== videoEffect)) {
        switchCameraAndVideoEffect(settings.videoInput, settings.videoEffect ?? VideoCallEffect.NONE)
      }

      if (settings.audioInput && settings.audioInput !== audioInput) {
        switchMicrophone(settings.audioInput)
      }

      if (settings.audioOutput && settings.audioOutput !== audioOutput) {
        switchSpeaker(settings.audioOutput)
      }
    },
    [audioInput, audioOutput, switchCameraAndVideoEffect, switchMicrophone, switchSpeaker, videoEffect, videoInput],
  )

  const onActiveShareChange = useCallback(
    async (payload: { state: 'Active' | 'Inactive'; userId: number }) => {
      trackEvent?.(payload.state === 'Active' ? ZOOM_EVENTS.RECEIVE_SCREENSHARE : ZOOM_EVENTS.STOP_RECEIVE_SCREENSHARE)
      if (!participantScreenshareElement || !stream) {
        return
      }
      if (payload.state === 'Active') {
        try {
          stream.startShareView(participantScreenshareElement, payload.userId)
        } catch (err) {
          Sentry.captureException(err)
        }
        trackEvent?.(ZOOM_EVENTS.RENDER_SCREENSHARE)
        if (isMobileBrowser) {
          stream.updateSharingCanvasDimension(Dimensions.get('window').width, (Dimensions.get('window').width * 9) / 16)
        }
      } else {
        stream.stopShareView()
        trackEvent?.(ZOOM_EVENTS.STOP_RENDER_SCREENSHARE)
      }
    },
    [isMobileBrowser, participantScreenshareElement, stream, trackEvent],
  )

  useEffect(() => localStorage.setItem(IO_DEVICES.CAMERA, videoInput || ''), [videoInput])
  useEffect(() => localStorage.setItem(IO_DEVICES.MICROPHONE, audioInput || ''), [audioInput])
  useEffect(() => localStorage.setItem(IO_DEVICES.SPEAKER, audioOutput || ''), [audioOutput])
  useEffect(() => localStorage.setItem(IO_DEVICES.EFFECT, videoEffect), [videoEffect])

  const onNetworkQualityChange = useCallback(
    (payload: { userId: number; type: 'uplink' | 'downlink'; level: number }) => {
      const isSelf = payload.userId === client.getCurrentUserInfo()?.userId
      if (payload.level <= 2) {
        trackEvent?.(ZOOM_EVENTS.BAD_CONNECTION, {
          [ZOOM_PROPERTIES.IS_SELF]: isSelf,
          [ZOOM_PROPERTIES.LEVEL]: payload.level,
          [ZOOM_PROPERTIES.CONNECTION_TYPE]: payload.type,
        })
      }
      if (isSelf && payload.type === 'uplink') {
        const quality = NETWORK_QUALITY_MAP[payload.level]
        setNetworkQuality(quality)
        if (quality === ZoomNetworkQuality.BAD) {
          addZoomToast(ZoomToast.BAD_CONNECTION)
        }
      }
    },
    [addZoomToast, client, setNetworkQuality, trackEvent],
  )

  useEffect(() => {
    client.on('connection-change', onConnectionChange)
    client.on('current-audio-change', onCurrentAudioChange)
    client.on('video-capturing-change', onVideoCapturingChange)
    client.on('user-added', onUsersChange)
    client.on('user-removed', onUsersChange)
    client.on('user-updated', onUsersChange)
    client.on('peer-share-state-change', onPeerShareStateChange)
    client.on('passively-stop-share', onPassivelyStopShare)
    client.on('command-channel-message', onCommandChannelMessage)
    client.on('active-share-change', onActiveShareChange)
    client.on('network-quality-change', onNetworkQualityChange)
    return () => {
      client.off('connection-change', onConnectionChange)
      client.off('current-audio-change', onCurrentAudioChange)
      client.off('video-capturing-change', onVideoCapturingChange)
      client.off('user-added', onUsersChange)
      client.off('user-removed', onUsersChange)
      client.off('user-updated', onUsersChange)
      client.off('peer-share-state-change', onPeerShareStateChange)
      client.off('passively-stop-share', onPassivelyStopShare)
      client.off('command-channel-message', onCommandChannelMessage)
      client.off('active-share-change', onActiveShareChange)
      client.off('network-quality-change', onNetworkQualityChange)
    }
  }, [
    client,
    onConnectionChange,
    onCurrentAudioChange,
    onVideoCapturingChange,
    onDeviceChange,
    onUsersChange,
    onPeerShareStateChange,
    onPassivelyStopShare,
    onCommandChannelMessage,
    onActiveShareChange,
    onNetworkQualityChange,
  ])

  const settings = useMemo<DeviceSettings>(
    () => ({
      audioInput: audioInput ?? undefined,
      audioOutput: audioOutput ?? undefined,
      videoInput: videoInput ?? undefined,
      videoEffect,
    }),
    [audioInput, audioOutput, videoEffect, videoInput],
  )

  return useMemo(
    () => ({
      muted,
      toggleMute,
      videoOff,
      toggleVideo,
      cameraList,
      userId: client.getCurrentUserInfo()?.userId,
      joinSession,
      remoteParticipants,
      leaveSession,
      inSession,
      setSelfElement,
      sharingUserId,
      startScreenshare,
      stopScreenshare,
      setScreenshareElement,
      videoEffect,
      setVideoEffect,
      sessionStarted,
      startSession,
      setSessionStarted,
      startAudio,
      setParticipantElement,
      setPreviewVideoElement,
      setPreviewCanvasElement: () => {},
      isPreviewVBMode: false,
      updateSettings,
      settings,
      setParticipantScreenshareElement,
      stream,
      setCameraRef: () => {},
      joinTime,
      userIdentity: client.getCurrentUserInfo()?.userIdentity,
      supportsVirtualBackground,
      showPermissionsModal,
      closePermissionsModal: () => setShowPermissionsModal(false),
    }),
    [
      muted,
      toggleMute,
      videoOff,
      toggleVideo,
      cameraList,
      client,
      joinSession,
      remoteParticipants,
      leaveSession,
      inSession,
      sharingUserId,
      startScreenshare,
      stopScreenshare,
      videoEffect,
      setVideoEffect,
      sessionStarted,
      startSession,
      startAudio,
      updateSettings,
      settings,
      stream,
      joinTime,
      supportsVirtualBackground,
      showPermissionsModal,
    ],
  )
}
