import React, { useState } from 'react'
import { FormattedDate } from 'react-intl'
import { View } from 'react-native'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isAfter, isBefore, subDays, subMinutes, subMonths } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { Map } from 'immutable'
import { isNil, union } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import {
  AIDraftStatus,
  Appointment,
  AppointmentSummary,
  ClientObject,
  getProgramNameFromId,
  Note,
  NoteStatusStates,
  noteSummaryStatusText,
  NoteTypes,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { ChevronIcon, Dashlet, RefreshButton } from '@lyrahealth-inc/ui-core'
import {
  BodyText,
  BodyTextSize,
  IconBadge,
  IconBadgeCorners,
  NotesBadge,
  PressableOpacity,
  ThemeType,
  tID,
  toJS,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents, sessionNoteTypes } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { appointmentStatuses, programConfig } from '../../../common/constants/appConstants'
import { CLIENT_NOTES } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { getClientNotesAllNotes, getClientNotesAppointmentSummaries } from '../../../data/lyraTherapy/clientSelectors'
import { getAllNotes, getAppointmentSummaries } from '../../clientNotes/data/clientNotesAutoActions'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getLTVideoAppointments } from '../../data/ltVideoSelectors'

const DashletItemContainer = styled.View<{ theme: ThemeType; first: boolean }>(({ theme, first }) => ({
  border: 0,
  width: '100%',
  padding: theme.spacing['4px'],
  borderTopWidth: first ? 0 : '1px',
  borderTopColor: theme.colors.borderDefault,
}))

const DashletItemContent = styled(PressableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '61px',
  height: 'auto',
  backgroundColor: 'white',
  border: 'none',
  margin: '0',
})

const AppointmentInfo = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMinWidthDesktopL ? 'row' : 'column',
}))

const AppointmentTime = styled.View({
  flexDirection: 'column',
})

const NoteInfo = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: theme.breakpoints.isMinWidthDesktopL ? theme.spacing['32px'] : 0,
  alignItems: 'flex-start',
}))

const BadgeSectionContainer = styled.View({
  flexDirection: 'row',
})

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: 'fit-content',
  padding: `${theme.spacing['8px']} 0`,
  marginRight: theme.spacing['8px'],
}))

const RightContainer = styled.View({
  flexDirection: 'row',
})

const EmptyDashlet = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  textAlign: 'center',
  margin: `${theme.spacing['16px']} 0`,
}))

const UnfinishedNotesDashlet: React.FC<UnfinishedNotesDashletProps> = ({
  activeClientsFetched,
  allNotes,
  appointments,
  clients,
  providerId,
  actions: { getAllNotes, getAppointmentSummaries, selectLtClient },
}) => {
  const navigate = useNavigate()
  const { colors } = useTheme()

  const [isRefreshingNotes, setIsRefreshingNotes] = useState(false)
  const { enableAINotesFeatures, isPreferredNameEnabled, showAINotesFeatures, showAINotesForAUD } = useFlags()

  const appointmentSummaries = useSelector(getClientNotesAppointmentSummaries)

  const MONTHS_OF_NOTES_TO_FETCH = 7
  const notesQueryParams = new URLSearchParams()
  notesQueryParams.append('provider_id', providerId)
  notesQueryParams.append('excludedFields', 'body')
  notesQueryParams.append('excludedFields', 'content_meta_data')
  notesQueryParams.append('note_type', NoteTypes.INITIAL)
  notesQueryParams.append('note_type', NoteTypes.SESSION)
  notesQueryParams.append('note_type', NoteTypes.REFERRAL)
  // 'months' represents the max amount of months into the past from today where we want to fetch notes from
  notesQueryParams.append('months', `${MONTHS_OF_NOTES_TO_FETCH}`)
  const [loading, , fetched] = useFetcher(
    [
      [getAllNotes, { params: notesQueryParams }],
      [getAppointmentSummaries, { providerId, statusOnly: true }, !!enableAINotesFeatures],
    ],
    [enableAINotesFeatures, providerId],
  )

  // To avoid showing appointments that happened before we added Notes to our platform, we will filter out any appoitnments that happened before 2021
  const pastAppointmentsWithoutNotes = appointments.filter((appt: Appointment) => {
    const apptDateTimeToUTC = zonedTimeToUtc(new Date(`${appt.startDate} ${appt.startTime}`), appt.timeZone)
    return (
      ![appointmentStatuses.canceled, appointmentStatuses.missed].includes(appt.appointmentStatus) &&
      isBefore(apptDateTimeToUTC, new Date()) &&
      !allNotes.find(({ appointment_id }: any) => appointment_id === appt.appointmentId) &&
      isAfter(apptDateTimeToUTC, subMonths(new Date(), 6))
    )
  })

  const appointmentsWithDraftNotes = appointments.filter((appt: Appointment) => {
    return (
      ![appointmentStatuses.canceled, appointmentStatuses.missed].includes(appt.appointmentStatus) &&
      allNotes.find((note: Note) => note.appointment_id === appt.appointmentId && note.status === 'draft') &&
      isAfter(new Date(appt.startDate), subMonths(new Date(), 6))
    )
  })

  const referralDraftNotes = allNotes.filter(
    (note: Note) => note.status === NoteStatusStates.DRAFT && note.note_type === NoteTypes.REFERRAL,
  )

  const incompleteAndMissingSessionNotes = union(pastAppointmentsWithoutNotes, appointmentsWithDraftNotes).sort(
    (a: Appointment, b: Appointment) => {
      const firstDateTimeToUTC = zonedTimeToUtc(new Date(`${a.startDate} ${a.startTime}`), a.timeZone)
      const secondDateTimeToUTC = zonedTimeToUtc(new Date(`${b.startDate} ${b.startTime}`), b.timeZone)
      return isBefore(firstDateTimeToUTC, secondDateTimeToUTC) ? -1 : 1
    },
  )

  const getAppointmentListItemData = (appt: Appointment) => {
    const clientForAppointment = clients.find(({ id }: { id: string }) => appt.userId === id)
    const appointmentTime = zonedTimeToUtc(`${appt.startDate} ${appt.startTime}`, appt.timeZone)
    const noteType = appt.sessionNumber === 0 ? sessionNoteTypes.initial : sessionNoteTypes.session
    const threeDaysAndAppointmentMinutesEarlier = subMinutes(subDays(new Date(), 3), appt.appointmentDuration)
    const overdueNote = isBefore(appointmentTime, threeDaysAndAppointmentMinutesEarlier)
    const programName = getProgramNameFromId(appt.programId) ?? ''
    const aiSummaryStatus =
      programConfig[programName]?.aiNotesEnabled &&
      (programName !== 'AlcoholUseDisorderTherapy' ||
        (programName === 'AlcoholUseDisorderTherapy' && showAINotesForAUD)) &&
      appointmentSummaries?.find(
        (summary: AppointmentSummary) => summary.appointment_id === appt.appointmentId.toString(),
      )?.status

    return {
      client: clientForAppointment,
      time: appointmentTime,
      sessionNumber: appt.sessionNumber,
      overdueNote,
      aiSummaryStatus,
      noteType,
      appointmentId: appt.appointmentId.toString(),
    }
  }

  const getReferralNoteListItemData = (note: Note) => {
    const clientForNote = clients.find(({ id }: any) => note.patient_id === id)
    const lastEditTime = new Date(note.update_date)
    const overdueNote = isBefore(new Date(note?._create_date), subDays(new Date(), 3))

    return { client: clientForNote, time: lastEditTime, overdueNote, noteType: sessionNoteTypes.referral }
  }

  const renderListItem = (
    {
      client,
      time,
      sessionNumber,
      overdueNote = false,
      aiSummaryStatus,
      noteType,
      appointmentId,
    }: {
      client: ClientObject | undefined
      time: Date
      sessionNumber?: number
      overdueNote?: boolean
      aiSummaryStatus?: AIDraftStatus
      noteType?: string
      appointmentId?: string
    },
    index: number,
  ) => {
    if (!client) {
      // In the case that a client cannot be found, don't show a list item
      return
    }

    const onClickHandler = () => {
      track({
        event: mixpanelEvents.BUTTON_PRESS,
        action: actions.UNFINISHED_NOTES_DASHLET_OPEN_NOTE,
        details: {
          ...(aiSummaryStatus && { summaryStatus: noteSummaryStatusText[aiSummaryStatus] }),
          overdueNote: overdueNote,
          noteType: noteType,
          appointmentId,
        },
      })
      selectLtClient(client)
      navigate(CLIENT_NOTES.route)
    }

    return (
      <DashletItemContainer
        key={`${client.first_name}${time}`}
        testID={tID(`UnfinishedNotes-list-item-${client.first_name}-${time}`)}
        first={index === 0}
      >
        <DashletItemContent key={`${client.first_name}${time}`} onPress={onClickHandler}>
          <View>
            <AppointmentInfo>
              <AppointmentTime>
                <BodyText
                  text={<FormattedDate value={time} year='numeric' month='2-digit' day='2-digit' />}
                  size={BodyTextSize.SMALL}
                  color={colors.textPrimary}
                />
                <BodyText
                  text={<FormattedDate value={time} hour='numeric' minute='2-digit' />}
                  size={BodyTextSize.SMALL}
                  color={colors.textInactive}
                />
              </AppointmentTime>
              <NoteInfo>
                <BodyText
                  text={getClientFullName(client, isPreferredNameEnabled)}
                  size={BodyTextSize.SMALL}
                  color={colors.textPrimary}
                />
                <BodyText
                  text={!isNil(sessionNumber) && sessionNumber >= 0 ? `Session ${sessionNumber}` : 'Care Referral'}
                  size={BodyTextSize.SMALL}
                  color={colors.textInactive}
                />
              </NoteInfo>
            </AppointmentInfo>
            <BadgeSectionContainer>
              {showAINotesFeatures && aiSummaryStatus && (
                <BadgeContainer>
                  <NotesBadge status={aiSummaryStatus} />
                </BadgeContainer>
              )}
              {overdueNote && (
                <BadgeContainer>
                  <IconBadge
                    color={colors.backgroundError}
                    text={<BodyText size={BodyTextSize.CAPTION} text='overdue' color={colors.textError} />}
                    cornerType={IconBadgeCorners.CURVED}
                    testId='IconBadge-overdue'
                  />
                </BadgeContainer>
              )}
            </BadgeSectionContainer>
          </View>
          <RightContainer>
            <ChevronIcon isFilled fillColor={colors.textPrimary} direction='right' />
          </RightContainer>
        </DashletItemContent>
      </DashletItemContainer>
    )
  }

  return (
    <View testID={tID('UnfinishedNotesDashlet-container')}>
      <Dashlet
        title={`Unfinished notes${
          activeClientsFetched ? ` (${incompleteAndMissingSessionNotes.length + referralDraftNotes.length})` : ''
        }`}
        buttons={
          <RefreshButton
            size={'20'}
            isLoading={isRefreshingNotes}
            onClick={() => {
              setIsRefreshingNotes(true)
              if (enableAINotesFeatures) {
                getAppointmentSummaries({ providerId, statusOnly: true })
              }
              getAllNotes({ params: notesQueryParams }).then(() => {
                setIsRefreshingNotes(false)
              })
            }}
          />
        }
        isLoading={!activeClientsFetched || loading || !fetched}
      >
        {incompleteAndMissingSessionNotes.length === 0 && referralDraftNotes.length === 0 ? (
          <EmptyDashlet>No unfinished notes</EmptyDashlet>
        ) : (
          union(
            referralDraftNotes.map(getReferralNoteListItemData),
            incompleteAndMissingSessionNotes.map(getAppointmentListItemData),
          ).map(renderListItem)
        )}
      </Dashlet>
    </View>
  )
}

type UnfinishedNotesDashletProps = {
  activeClientsFetched: boolean
  allNotes: Note[]
  appointments: Appointment[]
  clients: ClientObject[]
  providerId: string
  actions: any
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    allNotes: getClientNotesAllNotes($$state),
    appointments: getLTVideoAppointments($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getAllNotes, getAppointmentSummaries, selectLtClient }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(UnfinishedNotesDashlet))
