import { differenceInWeeks, format, parse, parseISO } from 'date-fns'
import { Iterable, List } from 'immutable'
import { escapeRegExp, get, includes, intersection, isEmpty, isNil, merge } from 'lodash-es'
import SumoLogger from 'sumo-logger'

import {
  Capacity,
  ChargeCopyPrepopInfo,
  ClientListClientObject,
  CustomerInfo,
  Diagnosis,
  isImmutable,
  logToSumoLogic as log,
  ProviderUser,
  utilizationQuarters,
} from '@lyrahealth-inc/shared-app-logic'
import { dateUtils } from '@lyrahealth-inc/ui-core'

import { Alert } from '../../data/alertTypes'
import {
  defaultErrorMessages,
  diagnoses,
  LTsessionTypes,
  meetingFormatChargeModalityMap,
  PAYMENT_FORM,
  programNameTitleMap,
  programs,
  ROLES,
  sumologicConfig,
} from '../constants/appConstants'
import * as ROUTES from '../constants/routingConstants'

export const getErrorText = (statusCode: any) => {
  return defaultErrorMessages[statusCode]
}

// true if at least one of the user's roles exists
// in the array of supported roles for the given route
export const hasPermission = (roles: any, config: any, pathName: any) => {
  const routes = ROUTES
  const route = Object.values(routes).find(({ route }) => pathName === route)
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const isRoleAllowed = intersection(route.roles, roles).length > 0
  const isFeatureEnabled = config[(route as any).feature] !== false
  return isRoleAllowed && isFeatureEnabled
}

// true if the role, or roles, being searched for
// exist in the user's roles array
export const hasRole = (roles: any, search: any) => {
  if (Iterable.isIterable(roles)) {
    roles = roles.toJS()
  }

  if (typeof search === 'string' || search instanceof String) {
    // single string role
    return includes(roles, search)
  } else {
    // array of role strings
    return intersection(roles, search).length > 0
  }
}

// shared amongst: request payment, new payment (provider), new payment (practice)
export const getChargeCopyPrepopInfo = (chargeInfo: any, customers: any) => {
  const prepopInfo: ChargeCopyPrepopInfo = {}
  let initialCompany: CustomerInfo[] = []
  let initialDiagnoses: Diagnosis[] = []
  if (chargeInfo && !chargeInfo.is_guaranteed_time) {
    // cherry pick what we care about and parse as needed
    if (!!chargeInfo.relationship && chargeInfo.relationship !== 'employee') {
      prepopInfo.eligible_member_first_name = chargeInfo.eligible_member_first_name
      prepopInfo.eligible_member_last_name = chargeInfo.eligible_member_last_name
      if (chargeInfo.eligible_member_dob) {
        prepopInfo.eligible_member_dob = dateUtils.getDisplayDate(chargeInfo.eligible_member_dob, 'MM/DD/YYYY')
      }
    }

    prepopInfo.provider_lyra_id = chargeInfo.provider_lyra_id
    prepopInfo.first_name = chargeInfo.first_name
    prepopInfo.last_name = chargeInfo.last_name

    if (chargeInfo.date_of_birth) {
      prepopInfo.date_of_birth = dateUtils.getDisplayDate(chargeInfo.date_of_birth, 'MM/DD/YYYY')
    }
    if (chargeInfo.gender) {
      prepopInfo.gender = mapGenderToLetter(chargeInfo.gender)
    }
    const initialSessionType = chargeInfo.session_type
    if (initialSessionType) {
      prepopInfo.session_type = initialSessionType
    }
    const initialModality = chargeInfo.modality
    if (initialModality) {
      prepopInfo.modality = initialModality
    }
    const locationState = chargeInfo.provider_location_state
    const locationZip = chargeInfo.provider_location_zipcode
    if (prepopInfo.modality === 'in_person' && locationState && locationZip) {
      prepopInfo.location = {
        state: locationState,
        zipcode: locationZip,
      }
    }
    const comp = chargeInfo.company
    if (comp && customers) {
      initialCompany = customers.filter((customer: any) => {
        return customer.value === comp
      })
      if (get(initialCompany, '[0].lyra_code_enabled')) {
        prepopInfo.lyra_code = chargeInfo.lyra_code ?? ''
      }
      prepopInfo.company = initialCompany
    }
    if (chargeInfo.relationship) {
      prepopInfo.relationship = chargeInfo.relationship
    }
    let diag = chargeInfo.diagnosis
    if (diag) {
      diag = diag.toLowerCase()
      const diagArray = diag.replace(/(,\s)/g, ',').split(',')
      initialDiagnoses = diagnoses.filter((obj) => {
        return includes(diagArray, obj.value)
      })
      prepopInfo.diagnosis = initialDiagnoses
    }
  } else if (chargeInfo && chargeInfo.is_guaranteed_time) {
    prepopInfo.is_guaranteed_time = true
    const comp = chargeInfo.company
    if (comp && customers) {
      initialCompany = customers.filter((customer: CustomerInfo) => {
        return customer.value === comp
      })
    }
    prepopInfo.company = initialCompany

    return {
      prepopInfo: prepopInfo,
      initialCompany: initialCompany,
    }
  }

  return {
    prepopInfo: prepopInfo,
    initialCompany: initialCompany,
    initialDiagnoses: initialDiagnoses,
  }
}

const {
  BlendedCareTherapy,
  MedicationManagement,
  Coaching,
  SingleSessionCoaching,
  AlcoholUseDisorderTherapy,
  ClinicalLeaveEvaluation,
  TeensTherapy,
} = programs
export const getLTSessionTypeAndRate = (programName: any, sessionNumber: any, sessionsRates = {}) => {
  let sessionType, sessionRate
  switch (programName) {
    case BlendedCareTherapy:
      sessionType = LTsessionTypes.BCT_SESSION
      sessionRate = '0'
      break
    case MedicationManagement:
      sessionType = sessionNumber > 1 ? LTsessionTypes.FOLLOWUP_MEDS_SESSION : LTsessionTypes.INITIAL_MEDS_SESSION
      sessionRate = '0'
      break
    case Coaching:
      sessionType = LTsessionTypes.SMP_SESSION
      sessionRate = sessionsRates[sessionType] || '0'
      break
    case SingleSessionCoaching:
      sessionType = LTsessionTypes.SSC_SESSION
      sessionRate = sessionsRates[sessionType] || '0'
      break
    case AlcoholUseDisorderTherapy:
      sessionType = LTsessionTypes.AUD_SESSION
      sessionRate = '0'
      break
    case ClinicalLeaveEvaluation:
      sessionType = sessionNumber > 1 ? LTsessionTypes.FOLLOWUP_CLE_SESSION : LTsessionTypes.INITIAL_CLE_SESSION
      sessionRate = '0'
      break
    case TeensTherapy:
      sessionType = LTsessionTypes.LCTT_SESSION
      sessionRate = '0'
      break
  }
  return { sessionType, sessionRate }
}

export const getBCCharge = (values = {}, provider: any, client: any, appt: any, episode = {}, sessionsRates = {}) => {
  logToSumoLogic('getBCCharge', provider.id, {
    action: 'Get BC Charge',
    clientId: client.id,
    values,
  })
  const formData = new FormData()
  Object.entries(values).forEach(([key, val]) => {
    if (key === 'diagnosis') val = (val as any).map((d: any) => d.value).toString()
    if (key.indexOf('icd10') !== -1) val = (val as any).map((obj: any) => obj.code).join(',')
    if (key === 'attestation_data') val = JSON.stringify(val)
    formData.append(key, val as any)
  })
  const { sessionType, sessionRate } = getLTSessionTypeAndRate(
    (episode as any)?.program_name,
    appt.sessionNumber,
    sessionsRates,
  )
  // Populate required fields
  formData.append('provider_lyra_id', provider.id)
  formData.append('company', client.employer)
  formData.append('session_rate', sessionRate)
  formData.append('session_type', sessionType as any)
  formData.append('visit_date', appt.startDate)
  formData.append('session_duration', appt.appointmentDuration)
  formData.append('appointment_id', appt.appointmentId)
  formData.append('modality', meetingFormatChargeModalityMap[appt.meetingFormat])
  formData.append('first_name', client.first_name)
  formData.append('last_name', client.last_name)
  formData.append('date_of_birth', client.date_of_birth)
  formData.append('episode_state', (values as any).episode_state || (episode as any).state)
  // We pass 'relationship' because it's required, but the BE will set the correct value
  formData.append('relationship', 'employee')
  formData.append('lyra_code', client?.lyra_code)
  formData.append('patient_lyra_id', client.id)
  return formData
}
// currently used on request payment. placed here in case we need to share with new payment (provider), new payment (practice)
export const getPatientPrepopInfo = (patient: any, customers: any) => {
  const prepopInfo = {}
  let outcomesAgreed = true
  let employer

  if (patient) {
    const propVal = patient.email_consent ?? true
    outcomesAgreed = propVal === null || propVal === true
    ;(prepopInfo as any).first_name = patient.first_name
    ;(prepopInfo as any).last_name = patient.last_name
    ;(prepopInfo as any).date_of_birth = dateUtils.getDisplayDate(patient.date_of_birth, 'MM/DD/YYYY')

    // cherry pick what we care about and parse as needed
    ;(prepopInfo as any).gender = mapGenderToLetter(patient.gender)
    const comp = patient.employer
    if (comp && customers) {
      employer = customers.filter((customer: any) => {
        return customer.value === comp
      })
      ;(prepopInfo as any).company = employer
    }
  }

  return {
    patientPrepopInfo: prepopInfo,
    patientEmployer: employer,
    patientOutcomesAgreed: outcomesAgreed,
  }
}

export const mapGenderToLetter = (genderString: any) => {
  if (!genderString) return genderString // null breaks the below code, but won't break the form feature - field will just remain empty
  switch (genderString.toLowerCase()) {
    case 'male' || 'm':
      return 'm'
    case 'female' || 'f':
      return 'f'
    case 'unspecified' || 'u':
      return 'u'
    case 'decline' || 'd' || 'prefer not to say':
      return 'd'
    case 'non-binary' || 'b':
      return 'b'
    case 'other' || 'o':
      return 'o'
    default:
      return genderString
  }
}

export const hasErrorForAction = ($$alerts: Alert[] | List<Alert>, action: string) => {
  const alerts: Alert[] = isImmutable({ value: $$alerts }) ? ($$alerts as List<Alert>).toJS() : $$alerts
  return alerts.some((alert) => alert.action === action)
}

/**
 * Trims a string with a safety check.
 *
 * Useful to trim fields value in forms before validation.
 *
 * @param {string} value The string to trim.
 */
export const trim = function (value: any) {
  return typeof value === 'string' ? value.trim() : value
}

/**
 * Makes a string camelCase after trimming it.
 * @param {string} str The string to set in camelCase.
 */
export const toCamelCase = (str: any) => {
  str = str.trim()
  return str.replace(/\W+(.)/g, (match: any, chr: any) => chr.toUpperCase())
}

/**
 * Prevents to enter a white space at the beginning of a string value.
 *
 * Useful to prevent users fill a string field with a white space.
 *
 * @param {string} value The string to trim.
 */
export const trimInitialWhiteSpace = function (value: any) {
  if (typeof value === 'string' && value.charAt(0) === ' ') {
    return value.trim()
  } else {
    return value
  }
}

const sumoLogger = new SumoLogger(sumologicConfig)

export const logToSumoLogic = (sourceCategory: any, userId: any, data: any) => {
  log(sumoLogger, sumologicConfig, sourceCategory, 'providerPortal', userId, data)
}

export const sanitizeLyraCode = (lyraCode: any) => {
  if (typeof lyraCode === 'string' && lyraCode) {
    return lyraCode.replace(/-|_/g, '')
  } else {
    return ''
  }
}

export const getEpisodeDropdownText = (episode: any) => {
  return `${programNameTitleMap[episode.program_name]} Episode ${format(parseISO(episode.start_date), 'MMM yyyy')} - ${
    isEmpty(episode.end_date) ? 'current' : format(parseISO(episode.end_date), 'MMM yyyy')
  }`
}

export const getEpisodeDropdownItems = (episodes: any, selectHandler: any) => {
  let dropdownItems = []

  dropdownItems = episodes.map((ep: any) => ({
    id: ep.id,
    text: getEpisodeDropdownText(ep),
    selectHandler: () => selectHandler({ episode: ep }),
  }))

  return dropdownItems
}

export const prepopulateAttestation = (paymentsData: any) => {
  if (isEmpty(paymentsData)) {
    return
  }
  const mostRecent = paymentsData
    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    .toSorted((a: any, b: any) => new Date(b.created_at) - new Date(a.created_at))
    .find((item: any) => item.attestation_data)
  const prepopulateFields = mostRecent?.attestation_data ? JSON.parse(mostRecent.attestation_data) : {}
  return { attestation_data: prepopulateFields ?? {} }
}

export const searchForClients = ({
  clients,
  event,
  showAll = false,
  searchValue,
}: {
  clients?: any
  event?: any
  showAll?: boolean
  searchValue?: string
}) => {
  const target = event?.target
  const searchString = searchValue ? escapeRegExp(searchValue) : escapeRegExp(target?.value?.trim())

  if (isEmpty(searchString) || isNil(searchString)) {
    return showAll ? clients : []
  }

  if (isNil(clients)) {
    return []
  }

  return clients.filter((client: any) => {
    const isFirstNameMatch = client.first_name?.match(new RegExp(searchString, 'i'))
    const isLastNameMatch = client.last_name?.match(new RegExp(searchString, 'i'))
    const isFullNameMatch = `${client.first_name} ${client.last_name}`.match(new RegExp(searchString, 'i'))
    const isLyraIdMatch = `${client.id}`.match(new RegExp(searchString, 'i'))
    const isPreferredFirstNameMatch = client.preferred_first_name?.match(new RegExp(searchString, 'i'))
    const isPreferredLastNameMatch = client.preferred_last_name?.match(new RegExp(searchString, 'i'))
    const isFullPreferredNameMatch = `${client.preferred_first_name ?? client.first_name} ${
      client.preferred_last_name ?? client.last_name
    }`.match(new RegExp(searchString, 'i'))
    return (
      isFirstNameMatch ||
      isLastNameMatch ||
      isFullNameMatch ||
      isLyraIdMatch ||
      (client.employer === 'apple' &&
        (isPreferredFirstNameMatch || isPreferredLastNameMatch || isFullPreferredNameMatch))
    )
  })
}

// TODO: This function will eventually be implemented inside the metadata framework.
// When we finish these two tickets, we can remove: PROVIDER-2124 and PROVIDER-2123
export const isOneArrayItemTrue = (items: any, values: any) => {
  let atLeastOneSelected = false
  Object.keys(items).forEach((item) => {
    const splitUp = item.split('.')
    const formName = splitUp[0]
    const fieldName = splitUp[1]
    const form = values[formName]
    if (form && Object.prototype.hasOwnProperty.call(form, fieldName) && form[fieldName] === true) {
      atLeastOneSelected = true
    }
  })
  return atLeastOneSelected
}

// TODO: This function will eventually be implemented inside the metadata framework.
// When we finish these two tickets, we can remove: PROVIDER-2124 and PROVIDER-2123
export const getItemsErrors = (items: any) => {
  let errors = {}
  Object.keys(items).forEach((item) => {
    const splitUp = item.split('.')
    const formName = splitUp[0]
    const fieldName = splitUp[1]
    errors = merge(errors, { [formName]: { [fieldName]: 'At least one field in this group must be selected' } })
  })
  return errors
}

// Used by RequestPayment and HealthPlanInfo
export const convertToFormData = (vals: any) => {
  const formData = new FormData()
  Object.keys(vals).forEach((key) => {
    let val = vals[key]
    if (
      key === PAYMENT_FORM.FIELDS.DATE_OF_BIRTH ||
      key === PAYMENT_FORM.FIELDS.VISIT_DATE ||
      key === PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB
    ) {
      val = dateUtils.getDisplayDate(val, 'YYYY-MM-DD')
    }
    if (
      key === PAYMENT_FORM.FIELDS.DIAGNOSIS ||
      key === PAYMENT_FORM.FIELDS.COMPANY ||
      key === PAYMENT_FORM.FIELDS.PRIMARY_CONDITION ||
      key === PAYMENT_FORM.FIELDS.SECONDARY_CONDITION ||
      key === PAYMENT_FORM.FIELDS.EBT_TYPE
    ) {
      val = val.map((d: any) => d.value).toString()
    }
    if (key === PAYMENT_FORM.FIELDS.LYRA_CODE) {
      val = sanitizeLyraCode(val)
    }
    if (key === PAYMENT_FORM.FIELDS.LOCATION) {
      if (Array.isArray(val)) {
        val = val[0]
      }
      // Only send over the state and zipcode of provider in-person address
      val = JSON.stringify({
        state: val.state,
        zipcode: val.zipcode,
      })
    }
    if (key === 'attestation_data') {
      if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
        val = JSON.stringify(val)
      }
    }
    formData.append(key, val)
  })
  return formData
}

export const getGSCDuration = (startDate: string | undefined, maxDuration: number): number | null => {
  if (!startDate) {
    return null
  }

  return Math.min(differenceInWeeks(new Date(), parse(startDate, 'yyyy-MM-dd', new Date())) + 1, maxDuration)
}

export const getPreviousQuartersSorted = ({
  availableQuarters,
  howManyQuartersToFetch,
}: {
  availableQuarters?: utilizationQuarters
  howManyQuartersToFetch?: number
}): string[] => {
  if (!availableQuarters) return []
  const retrievedQuartersYears = Object.keys(availableQuarters)
  const sortedQuarters = retrievedQuartersYears.sort((a, b) => {
    const aYearQuarter = a.split('_').reverse().join('') // i.e. 2021Q3
    const bYearQuarter = b.split('_').reverse().join('') // i.e. 2021Q1
    return bYearQuarter < aYearQuarter ? -1 : 1
  })
  if (howManyQuartersToFetch) {
    return sortedQuarters.slice(0, howManyQuartersToFetch)
  }
  return sortedQuarters
}

export const getDropdownItemsFromQuarters = (
  sortedQuarters: string[],
  handler: (quarter: string, isCurrent: boolean) => void,
) => {
  return sortedQuarters.map((quarter, index) => {
    let quarterDisplayName = quarter.replace('_', ' ')
    if (index === 0) {
      quarterDisplayName += ' Current'
    }
    return {
      text: quarterDisplayName,
      id: quarter,
      selectHandler: () => {
        handler(quarter, index === 0)
      },
    }
  })
}

export const getYearMonthDayLocaleByDate = (targetDate: Date) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(targetDate)
  const month = new Intl.DateTimeFormat(locale, { month: '2-digit' }).format(targetDate)
  const day = new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(targetDate)
  return `${year}-${month}-${day}`
}

export const getTotalCapacity = (allCapacities: Capacity) => {
  if (!allCapacities) {
    return 0
  }
  const allPrograms = Object.keys(allCapacities)
  const initialTotalCapacity = 0
  const sumWithInitial = allPrograms.reduce(
    (accumulator, currentProgramName) => accumulator + allCapacities[currentProgramName],
    initialTotalCapacity,
  )
  return sumWithInitial
}

export const isECDCProvider = (loggedInUser: ProviderUser) => {
  // ECDC is visible to all providers EXCEPT, Practice Providers and Practice Admins unless they are an onsite provider
  return loggedInUser.onsite || !hasRole(loggedInUser.roles, [ROLES.PRACTICE_PROVIDER, ROLES.PRACTICES_ADMIN])
}

export function shouldCheckDependentEligibilityUsingDependentInfo(
  isChildPatient: boolean,
  customerInfo: CustomerInfo | undefined,
): boolean {
  if (customerInfo == null) {
    return false
  }

  return (
    (!isChildPatient && customerInfo.check_adult_dependent_eligibility_using_dependent_info === 'true') ||
    (isChildPatient && customerInfo.check_child_dependent_eligibility_using_dependent_info === 'true')
  )
}

export function shouldShowPaymentFormEligibleMemberDetails(
  relationship: 'employee' | 'dependent',
  isChildPatient: boolean,
  customerInfo: CustomerInfo | undefined,
) {
  return (
    relationship === 'dependent' &&
    // if dependent is adult and customer prop is enabled to use dependent info for eligibility check,
    // we don't need to collect eligible member information because eligible member is the patient themselves
    // if dependent is minor, we need to collect eligible member for HPI eligibility checking.
    (isChildPatient || !shouldCheckDependentEligibilityUsingDependentInfo(false, customerInfo))
  )
}

export const getClientFullName = (client: ClientListClientObject, isPreferredNameEnabled: boolean) => {
  if (isPreferredNameEnabled && client?.employer === 'apple') {
    return (
      (client?.preferred_first_name ?? client?.first_name) + ' ' + (client?.preferred_last_name ?? client?.last_name)
    )
  }
  return client?.first_name + ' ' + client?.last_name
}
