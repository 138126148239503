import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { CancelToken } from 'axios'
import classNames from 'classnames'
import { Map } from 'immutable'
import { isEmpty, isNull } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  ClientListClientObject,
  Content,
  Curriculum,
  Episode,
  ProviderUser,
  TrackSession,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  DefaultButton,
  DropdownButton,
  FormContainer,
  InfoSheet,
  Lesson,
  LtIcon,
} from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './configTrackContainer.module.scss'
import ElectiveErrorContent from './ElectiveErrorContent'
import { CLIENT_HOME, CLIENTS_TRACK_LIBRARY } from '../../common/constants/routingConstants'
import { getAuthUser } from '../../data/auth/authSelectors'
import { getClientDetailsData, getClientSelectedEpisode } from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import { getAssignments } from '../assignments/data/assignmentsAutoActions'
import { assignCurriculum, updateCurriculum } from '../episodes/data/episodesAutoActions'
import MiniBackNav from '../miniBackNav/MiniBackNav'

const ConfigTrackContainer: React.FC<ConfigTrackContainerProps> = ({
  clientDetails,
  contents,
  selectedEpisode,
  actions: { getAssignments, updateCurriculum, assignCurriculum },
}) => {
  const user: ProviderUser = useSelector(getAuthUser)
  const location = useLocation()
  const sessionCount = location?.state?.sessionCount
  const track = location?.state?.track
  const navigate = useNavigate()

  const curriculum = track?.curriculumSchedule || []

  const originalElectiveTitles = curriculum.reduce((electives: string[], session: TrackSession) => {
    return electives.concat([isEmpty(session.contentOptions) ? session.title : ''])
  }, [])

  const [selectedElectives, setSelectedElectives] = useState(
    curriculum.reduce((electives: { name: string; title: string }[], session: TrackSession) => {
      return electives.concat([
        {
          name: isEmpty(session.contentOptions) ? session.name : 'selectElective',
          title: isEmpty(session.contentOptions) ? session.title : 'Select elective',
        },
      ])
    }, []),
  )
  const [modalContent, setModalContent] = useState<{ type: string | null; body: JSX.Element } | null>(null)
  const [isSubmittingTrack, setIsSubmittingTrack] = useState(false)

  const areElectivesSet = () => {
    return !selectedElectives
      .reduce((names: string[], elective: { name: string; title: string }) => {
        return names.concat(elective.name)
      }, [])
      .includes('selectElective')
  }

  const setElectiveInformation = (elective: { name: string; title: string }, index: number) => {
    curriculum[index].title = elective.title
    curriculum[index].name = elective.name
    curriculum[index].contents = curriculum[index].contentOptions[elective.name].contents
  }

  const [electivesChosen, setElectivesChosen] = useState(areElectivesSet)

  const showElectiveErrorModal = ({ title, body }: { title: string; body: string }) => {
    setModalContent({
      type: null,
      body: <ElectiveErrorContent title={title} body={body} closeFunc={() => setModalContent(null)} />,
    })
  }

  const previewContent = (assignment: Content) => {
    const selectedContent = contents.find((content) => content.name === assignment.name)
    if (selectedContent?.group === 'lesson') {
      setModalContent({
        type: 'lesson',
        body: (
          <Lesson
            metaData={selectedContent.meta_data}
            instructions={selectedContent.instructions}
            title={selectedContent.title}
            userRole='provider'
          />
        ),
      })
    } else if (selectedContent?.group === 'infosheet') {
      setModalContent({ type: null, body: <InfoSheet content={selectedContent} /> })
    } else {
      setModalContent({ type: null, body: <FormContainer content={selectedContent} preview userRole='provider' /> })
    }
  }

  const handleBackClicked = () => {
    navigate(CLIENTS_TRACK_LIBRARY.route, { state: { sessionCount: sessionCount } })
  }

  const dropdownOptions = curriculum.map((session: TrackSession, index: number) =>
    session.contentOptions
      ? Object.values(session.contentOptions).reduce(
          (options, content) =>
            options.concat({
              // @ts-expect-error TS(2769): No overload matches this call.
              id: content.name,
              text: content.title,
              selectHandler: () => {
                const newElectives = selectedElectives
                newElectives[index].name = content.name
                newElectives[index].title = content.title
                setSelectedElectives([...newElectives])
                setElectivesChosen(areElectivesSet)
                setElectiveInformation({ name: content.name, title: content.title }, index)
              },
            }),
          [],
        )
      : [],
  )

  const assignTrack = () => {
    return updateCurriculum({ id: selectedEpisode.id, curriculum })
      .then(() => assignCurriculum({ id: selectedEpisode.id }))
      .then(() => getAssignments({ patient_id: clientDetails.id, provider_id: user.id }))
      .then(() => navigate(CLIENT_HOME.route))
  }

  const submitElectives = () => {
    if (!electivesChosen) {
      showElectiveErrorModal({
        title: 'Please choose additional activities.',
        body: 'Additional activities are required as part of the care plan.',
      })
    } else if (new Set(selectedElectives.map(({ name }: { name: string }) => name)).size !== selectedElectives.length) {
      // check that no electives are repeated
      showElectiveErrorModal({
        title: 'Please choose unique additional activities.',
        body: 'Additional activities are required as part of the curriculum track. They must be unique, so please ensure you haven’t added the same content to weeks 4 and 6.',
      })
    } else {
      setIsSubmittingTrack(true)
      assignTrack().finally(() => setIsSubmittingTrack(false))
    }
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1'>
      <div className={styles.container}>
        <MiniBackNav backFunc={handleBackClicked} subText={`Share new content`} />
        <div style={{ width: '100%' }}>
          <div className={styles['header-container']}>
            <h2>{track?.title}</h2>
            <DefaultButton
              onClick={submitElectives}
              isLoading={isSubmittingTrack}
              data-test-id='ConfigTrackContainer-addTrackButton'
            >
              Add Care Plan
            </DefaultButton>
          </div>
          {curriculum.map((session: TrackSession, index: number) => (
            <div
              className={styles['week-container']}
              key={index}
              data-test-id={`ConfigTrackContainer-week${index + 1}-container`}
            >
              <div className={styles['week-list-item']}>
                <div
                  className={styles['week-header-container']}
                  data-test-id={`ConfigTrackContainer-week${index + 1}-header`}
                >
                  <div className={styles['title-container']} data-test-id='ConfigTrackContainer-weekHeader'>
                    Week {index + 1} - {originalElectiveTitles[index] || 'Required elective'}
                  </div>
                  {!originalElectiveTitles[index] && (
                    // @ts-expect-error TS(2322): Type '{ children: Element; name: string; className... Remove this comment to see the full error message
                    <div name='selectElective-dropdown' className={styles['dropdown-container']}>
                      <DropdownButton
                        className={styles['dropdown-button']}
                        styling='inline'
                        id='selectElective'
                        dropdownItems={dropdownOptions[index]}
                        data-test-id={`ConfigTrackContainer-dropdownButton-${selectedElectives[index].title}`}
                      >
                        <span
                          className={classNames(
                            selectedElectives[index].name === 'selectElective'
                              ? styles['track-placeholder']
                              : styles['track-select'],
                          )}
                        >
                          {selectedElectives[index].title}
                        </span>
                      </DropdownButton>
                    </div>
                  )}
                </div>
              </div>
              {session.contents &&
                Object.values(session.contents).map((content) => (
                  <div
                    className={classNames(styles['week-list-item'], styles['clickable-item'])}
                    onClick={() => previewContent(content)}
                    onKeyDown={() => previewContent(content)}
                    data-test-id={`ConfigTrackContainer-week${index + 1}-${content.name}Content`}
                    key={content.name}
                    role='none'
                  >
                    <div className={styles.name}>
                      <div className={styles.icon}>
                        <LtIcon type={content.group} />
                      </div>
                      {content.title}
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
      <BaseModal
        data-test-id='ConfigTrackContainer-modal'
        isOpen={!isNull(modalContent)}
        body={modalContent?.body ?? <div />}
        closeModal={() => setModalContent(null)}
        modalClass={modalContent?.type === 'lesson' && modalContent ? 'digital-lesson' : undefined}
      />
    </BootstrapContainer>
  )
}

type ConfigTrackContainerProps = {
  clientDetails: ClientListClientObject
  selectedEpisode: Episode
  contents: Content[]
  actions: {
    assignCurriculum: ({ id }: { id: string }) => Promise<any>
    getAssignments: ({
      patient_id,
      provider_id,
      cancelToken,
    }: {
      patient_id?: string
      provider_id?: string
      cancelToken?: CancelToken
    }) => Promise<any>
    updateCurriculum: ({ id, curriculum }: { id: string; curriculum: Curriculum }) => Promise<any>
  }
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    clientDetails: getClientDetailsData($$state),
    contents: getLyraTherapyContentsData($$state),
    selectedEpisode: getClientSelectedEpisode($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        getAssignments,
        updateCurriculum,
        assignCurriculum,
      },
      dispatch,
    ),
  }
}

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: ConfigTr... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(toJS(ConfigTrackContainer))
