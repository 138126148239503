import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  CalendarOpenSpotsIllustration,
  ExternalLinkIcon,
  PrimaryButton,
  Subhead,
  TextButton,
} from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderCalendarSetupCardProps = {
  onSetupPressed: () => void
  onLearnMorePressed: () => void
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  padding: theme.spacing['32px'],
  borderRadius: '16px',
  border: `1px solid ${theme.colors.borderDefault}`,
  alignSelf: 'flex-start',
  width: '100%',
  alignItems: 'center',
}))

const Content = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing['48px'],
  width: '500px',
}))

const Header = styled.View({
  flexDirection: 'column',
  alignItems: 'center',
})

const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['16px'],
  alignSelf: 'flex-end',
  alignItems: 'center',
}))

const LinkButton = styled(TextButton)({
  alignSelf: 'center',
})

const HeaderText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

export const ProviderCalendarSetupCard: FunctionComponent<ProviderCalendarSetupCardProps> = ({
  onSetupPressed,
  onLearnMorePressed,
}) => {
  const theme = useTheme() as ThemeType
  return (
    <Container>
      <Content>
        <Header>
          <CalendarOpenSpotsIllustration height={176} width={176} />
          <HeaderText
            textAlign='center'
            size={SubheadSize.MEDIUM}
            text={
              <FormattedMessage
                defaultMessage='Set up your Lyra Calendar'
                description='Subhead for provider calendar setup card'
              />
            }
          />
          <BodyText
            textAlign='center'
            color={theme.colors.textSecondary}
            text={
              <FormattedMessage
                defaultMessage='Manage your schedule and availability in one place.'
                description='Body text for provider calendar setup card'
              />
            }
          />
        </Header>
        <Row>
          <LinkButton
            rightIcon={<ExternalLinkIcon strokeColor={theme.colors.iconActive} />}
            text={<FormattedMessage defaultMessage='Learn more' description='Button to learn more about calendar' />}
            onPress={onLearnMorePressed}
          />
          <PrimaryButton
            testID={tID('ProviderCalendarSetupCard-setup-button')}
            text={<FormattedMessage defaultMessage='Set up calendar' description='Button to set up calendar' />}
            onPress={onSetupPressed}
          />
        </Row>
      </Content>
    </Container>
  )
}
