import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react'
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native'

import { cloneDeep, get, has, isEmpty, mapValues, merge, set } from 'lodash-es'

import { Contentmetadata } from '@lyrahealth-inc/shared-app-logic'
import { InputField, PrimaryButton, SecondaryButton, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './formSchemaBuilder.module.scss'

type GroupedFieldsFormSchemaBuilderProps = {
  constraints: any
  maxNumberOfFieldGroups: number
  metadata: Contentmetadata
  name: string
  title: string
}

const GroupedFieldsFormSchemaBuilder: ForwardRefRenderFunction<
  GroupedFieldsFormSchemaBuilderRef,
  GroupedFieldsFormSchemaBuilderProps
> = ({ constraints, maxNumberOfFieldGroups, metadata, name, title }, ref) => {
  const getCustomFields = () => {
    let customFields = {}
    const fieldsSchema = metadata?.schema?.properties || {}
    const fieldsUiSchema = metadata?.uiSchema || {}
    Object.entries(fieldsSchema).forEach(([fieldName, schema]) => {
      if ((schema as any).custom) {
        customFields = set(customFields, fieldName, { schema, uiSchema: fieldsUiSchema[fieldName] })
      }
    })
    return customFields
  }
  const [addingCustomField, setAddingCustomField] = useState<boolean>(false)
  const [customFields, setCustomFields] = useState(getCustomFields())
  const [customFieldTitle, setCustomFieldTitle] = useState<string>('')
  const [numberOfFieldGroups, setNumberOfFieldGroups] = useState<number>(0)

  useImperativeHandle(ref, () => ({
    configure() {
      return configure()
    },
  }))

  const incrementNumberOfFieldGroups = () => {
    setNumberOfFieldGroups((count) => count + 1)
  }

  const decrementNumberOfFieldGroups = () => {
    setNumberOfFieldGroups((count) => count - 1)
  }

  const configure = () => {
    let metadataClone = cloneDeep(metadata)
    // We first start by grabbing the 'properties' of the fields. Such as, min value and max value.
    let fieldsMetaData = get(metadataClone, ['schema', 'properties'])
    if (!isEmpty(customFields)) {
      // We want to append the custom fields properties onto our original metadata array.
      fieldsMetaData = merge(
        fieldsMetaData,
        mapValues(customFields, (customField: any) => get(customField, 'schema')),
      )
      // Now update the metadata as well as the display layer for the client
      metadata = set(metadataClone, ['schema', 'properties'], fieldsMetaData)
    }

    // Make sure every field has a default value. Integers should be 0.
    metadataClone = set(
      metadataClone,
      'initialValues',
      mapValues(fieldsMetaData, (fieldMeta: any) => (get(fieldMeta, 'type') === 'integer' ? 0 : undefined)),
    )
    // We want to be absolutely sure we're maintaining the original metadata by going through these series of gets and sets.
    // Maintain the original UI widgets as well as append any customFields.
    // Else if there is no predefined metadata uiSchema, we will default to an empty map
    // in order to have the ability to use mergeDeep with custom fields.
    metadataClone = set(
      metadataClone,
      'uiSchema',
      merge(
        get(metadata, 'uiSchema', {}),
        mapValues(customFields, (customField: any) => get(customField, 'uiSchema')),
      ),
    )

    // Now we ensure we display the original fields as well as the custom fields
    metadataClone = set(
      metadataClone,
      'report.fieldsToDisplay',
      merge(get(metadataClone, 'report.fieldsToDisplay', []), Object.keys(customFields)),
    )

    return metadataClone
  }

  // The field creation is handled by createSliderField
  const renderCustomFieldConfig = () => {
    return (
      <div className={styles['add-custom-field-row']}>
        <div style={{ marginTop: '10px', flexGrow: 2, marginRight: '10px' }}>
          <InputField
            name={`goal${Object.keys(customFields).length}`}
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => setCustomFieldTitle(e.nativeEvent.text)}
          />
        </div>
        <PrimaryButton
          onPress={createCustomFieldGroup}
          disabled={!customFieldTitle}
          testID={tID('FormSchemaBuilder-save-field')}
          text='Save'
          style={{ marginTop: 10 }}
        />
      </div>
    )
  }

  // Field names need to have a common group so that they can be deleted together
  const getFieldName = (index: number) => {
    const contentName = name
    let customName
    let i = 0
    while (!customName) {
      customName = has(customFields, `${contentName}Group${i}Field${index}`)
        ? null
        : `${contentName}Group${i}Field${index}`
      i++
    }
    return customName
  }

  const createCustomFieldGroup = () => {
    constraints.forEach((field: Dict, index: number) => {
      if (field.type === 'select') {
        createDropDownField(field, index)
      }

      if (field.type === 'range') {
        createSliderField(field, index)
      }
    })
    incrementNumberOfFieldGroups()
  }

  const createDropDownField = (field: Dict, index: number) => {
    const customField = {}
    const fieldName = getFieldName(index)
    const { enums, enumNames, noTitle, placeholder } = field

    ;(customField as any).schema = {
      custom: true,
      placeholder,
      enum: enums,
      enumNames,
      name: fieldName,
      title: noTitle ? '' : customFieldTitle,
      type: 'integer',
    }
    ;(customField as any).uiSchema = {
      'ui:widget': 'select',
    }

    const newCustomFields = set(customFields, fieldName, customField)

    setCustomFields(newCustomFields)
    setAddingCustomField(false)
    setCustomFieldTitle('')
  }

  const createSliderField = (field: Dict, index: number) => {
    const customField = {}
    const fieldName = getFieldName(index)
    const { minValue, maxValue, minLabel, maxLabel, noTitle, step } = field
    ;(customField as any).schema = {
      custom: true,
      maximum: maxValue,
      minimum: minValue,
      step: step,
      minLabel,
      maxLabel,
      name: fieldName,
      title: noTitle ? '' : customFieldTitle,
      type: 'integer',
    }
    ;(customField as any).uiSchema = {
      'ui:widget': 'range',
    }

    const newCustomFields = set(customFields, fieldName, customField)

    setCustomFields(newCustomFields)
    setAddingCustomField(false)
    setCustomFieldTitle('')
  }

  const removeField = (field: any) => {
    const fieldName = get(field, 'schema.name')
    const groupName = fieldName.split(name).pop().slice(0, 6) // groupName can only be 'Groupx'

    const newCustomFields = { ...customFields }

    for (const [key] of Object.entries(customFields)) {
      if (key.includes(groupName)) {
        // Remove the custom field from component state
        delete newCustomFields[key]

        // Remove the custom field from all the metadata if it was added by config()
        if (metadata?.schema?.properties?.key) {
          delete metadata.schema.properties[key]
        }

        if (metadata?.uiSchema?.key) {
          delete metadata.uiSchema[key]
        }

        if (metadata?.initialValues?.key) {
          delete metadata.initialValues[key]
        }

        if (metadata?.report?.fieldsToDisplay) {
          const fieldsToDisplayIndex = metadata.report?.fieldsToDisplay.indexOf(key)
          metadata.report.fieldsToDisplay.splice(fieldsToDisplayIndex)
        }
      }
    }

    decrementNumberOfFieldGroups()
    setCustomFields(newCustomFields)
  }

  return (
    <div className={styles.content}>
      <h2>{`Configure ${title}:`}</h2>
      <ol>
        {Object.values(customFields).map((field: any) => {
          return (
            field.schema.title && (
              <li key={field.schema.name}>
                {`${field.schema.title}`}
                <SecondaryButton
                  isSmall
                  onPress={() => removeField(field)}
                  style={{ marginLeft: 20 }}
                  testID={tID(`FormSchemaBuilder-delete-${field.schema.name}`)}
                  text='Delete'
                />
              </li>
            )
          )
        })}
      </ol>
      {addingCustomField ? renderCustomFieldConfig() : ''}
      <PrimaryButton
        disabled={numberOfFieldGroups >= maxNumberOfFieldGroups || addingCustomField}
        onPress={() => setAddingCustomField(true)}
        style={{ marginTop: 20 }}
        testID={tID('FormSchemaBuilder-add-field')}
        text='+ Add'
      />
    </div>
  )
}

export type GroupedFieldsFormSchemaBuilderRef = {
  configure: () => void
}

export default forwardRef(GroupedFieldsFormSchemaBuilder)
