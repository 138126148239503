import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { CancelToken } from 'axios'
import { Map } from 'immutable'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components/native'

import {
  Assignment,
  ClientObject,
  Episode,
  EpisodeStates,
  ProgramNames,
  ProviderAdminProviderInfo,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  ChartIcon,
  DefaultButton,
  DropdownButton,
  GearIcon,
  LoadingIndicator,
} from '@lyrahealth-inc/ui-core'
import { BodyText, ThemeType, toJS, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientProgress.module.scss'
import ClientOutcomes from '../../clients/individualClient/ClientOutcomes'
import { programs, ROLES } from '../../common/constants/appConstants'
import { getEpisodeDropdownItems, getEpisodeDropdownText, hasRole } from '../../common/utils/utils'
import { getAuthIsLTSupervisor } from '../../data/auth/authSelectors'
import { getClientAssignmentsData, getClientDetailsData } from '../../data/lyraTherapy/clientSelectors'
import { getProviderDetailsData } from '../../providers/data/providerSelectors'
import AssessmentTrackSettingsModal from '../clients/AssessmentTrackSettingsModal'
import { getAllClientEpisodes } from '../episodes/data/episodesAutoActions'

const EpisodeSelectorTextContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: `${theme.spacing['24px']}`,
  marginBottom: '10px',
  display: 'block',
}))

const ClientProgress: React.FC<ClientProgressProps> = ({
  clientDetails,
  providerDetailsData,
  assignments,
  getAllClientEpisodes,
}) => {
  const isLtSupervisor: boolean = useSelector(getAuthIsLTSupervisor)
  const [isAssessmentTrackModalOpen, setAssessmentTrackModalOpen] = useState(false)
  const [, episodes = [], hasFetchedEpisodes] = useFetcher([getAllClientEpisodes, { clientId: clientDetails?.id }, []])
  const [filteredEpisodes, setFilteredEpisodes] = useState<Episode[]>([])
  const [selectedProgressEpisode, setSelectedProgressEpisode] = useState<{ episode: Episode }>()
  const isProviderCoach = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
  const isProviderLTCoach = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH])
  const { isCFPEnabled } = useFlags()
  const isProviderAbleToSeeSharedOutcomes = hasRole(providerDetailsData?.roles, [
    ROLES.LT_CLINICAL_LEAVE_EVALUATOR,
    ROLES.LT_PRESCRIBER,
    ROLES.LT_PRESCRIBER_SUPERVISOR,
    ROLES.LT_THERAPIST,
    ROLES.LT_SUPERVISOR,
    ROLES.LT_FELLOW_THERAPIST,
    ROLES.LT_FELLOW_SUPERVISOR,
  ])
  const currentPrograms = episodes.map((episode: Episode) => episode.program_name)
  const isClientInAUDTherapy = currentPrograms?.includes(ProgramNames.AlcoholUseDisorderTherapy)
  const outcomes =
    ((isClientInAUDTherapy && !isProviderCoach) || isProviderAbleToSeeSharedOutcomes) &&
    selectedProgressEpisode?.episode?.state === EpisodeStates.IN_PROGRESS &&
    clientDetails?.outcomes
      ? clientDetails.outcomes.filter(
          (outcome) =>
            outcome.response_date &&
            outcome.program_name &&
            outcome.episode_id === selectedProgressEpisode?.episode?.id &&
            ![programs.Coaching, programs.SingleSessionCoaching].includes(outcome.program_name),
        )
      : clientDetails?.outcomes &&
        clientDetails.outcomes.filter(
          (outcome) => outcome.response_date && outcome.episode_id === selectedProgressEpisode?.episode?.id,
        )

  const assignmentIdsFromOutcomes = outcomes?.map((outcome) => outcome.assignment_id)
  const assignmentsWithSelfReflection = assignments.filter(
    (assignment) =>
      assignmentIdsFromOutcomes?.includes(assignment.id) &&
      assignment?.assignment_responses?.[0]?.response?.self_reflection,
  )

  useEffect(() => {
    const isCoaching = hasRole(providerDetailsData?.roles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
    const filteredEpisodes = episodes.filter((episode: Episode) => {
      if (isCoaching) {
        return (
          episode.program_name === ProgramNames.Coaching || episode.program_name === ProgramNames.SingleSessionCoaching
        )
      } else {
        return (
          episode.program_name !== ProgramNames.Coaching && episode.program_name !== ProgramNames.SingleSessionCoaching
        )
      }
    })
    if (!isEmpty(filteredEpisodes)) {
      setFilteredEpisodes(filteredEpisodes as Episode[])
    }
  }, [episodes, providerDetailsData?.roles])

  useEffect(() => {
    const activeEpisode = filteredEpisodes.find(
      (episode: Episode) =>
        episode.state === EpisodeStates.IN_PROGRESS && episode.provider_id === providerDetailsData.id,
    )
    setSelectedProgressEpisode({ episode: activeEpisode || filteredEpisodes[0] })
  }, [filteredEpisodes, providerDetailsData.id])

  if (!hasFetchedEpisodes) {
    return (
      <div className={styles['loading-container']}>
        <LoadingIndicator size={45} />
      </div>
    )
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
      {filteredEpisodes.length > 1 && selectedProgressEpisode?.episode && (
        <>
          <EpisodeSelectorTextContainer text={'Episode'} />
          <DropdownButton
            data-test-id='Assignments-select-episode'
            id='selectEpisode'
            dropdownItems={getEpisodeDropdownItems(filteredEpisodes, setSelectedProgressEpisode)}
            pullLeft={true}
          >
            <span styleName='episode-select'>{getEpisodeDropdownText(selectedProgressEpisode.episode)}</span>
          </DropdownButton>
        </>
      )}
      {isEmpty(outcomes) ? (
        <div className={styles['no-data-container']} data-test-id='ClientProgress-noData'>
          <div className={styles['text-container']}>
            <h3>No data yet</h3>
            <p className={styles.body}>
              Your client hasn’t submitted assessments yet. Encourage your client to complete them and check back to
              track their progress.
            </p>
          </div>
          <ChartIcon />
        </div>
      ) : (
        <>
          {(isLtSupervisor || (isProviderLTCoach && isCFPEnabled)) && selectedProgressEpisode?.episode && (
            <>
              <div className={styles['float-container']}>
                <div className={styles['aligning-container']}>
                  <DefaultButton
                    isOutlined
                    data-test-id='ClientProgress-assessmentTrackButton-update'
                    customClass={styles['track-settings-button']}
                    onClick={() => setAssessmentTrackModalOpen(true)}
                  >
                    <GearIcon isFilled />
                    Assessment track settings
                  </DefaultButton>
                </div>
              </div>
              <div>
                <BaseModal
                  isOpen={isAssessmentTrackModalOpen}
                  body={
                    <AssessmentTrackSettingsModal
                      selectedEpisode={selectedProgressEpisode.episode}
                      closeModal={() => setAssessmentTrackModalOpen(false)}
                      isProviderLTCoach={isProviderLTCoach}
                    />
                  }
                  closeModal={() => setAssessmentTrackModalOpen(false)}
                />
              </div>
            </>
          )}

          <div style={{ marginTop: '50px' }}>
            <ClientOutcomes
              outcomes={outcomes}
              assignmentsWithSelfReflection={assignmentsWithSelfReflection}
              clientFullName={clientDetails?.full_name}
              clientFirstName={clientDetails?.first_name}
              selectedProgressEpisode={selectedProgressEpisode?.episode}
            />
          </div>
        </>
      )}
    </BootstrapContainer>
  )
}

type ClientProgressProps = {
  clientDetails?: ClientObject
  providerDetailsData: ProviderAdminProviderInfo
  assignments: Assignment[]
  getAllClientEpisodes: ({ clientId }: { clientId?: string; cancelToken?: CancelToken }) => Promise<{}>
}

const mapStateToProps = ($$state: Map<string, any>): any => {
  return {
    assignments: getClientAssignmentsData($$state),
    clientDetails: getClientDetailsData($$state),
    providerDetailsData: getProviderDetailsData($$state),
  }
}

export default connect(mapStateToProps, { getAllClientEpisodes })(toJS(ClientProgress))
