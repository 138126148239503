import { MEETING_FORMATS } from '../session/Session'

export type TimeStamp = string

export type ProviderAvailability = {
  start: TimeStamp
  end: TimeStamp
}

// NOT_FOUND = 'Non connected calendar providers'
export enum PROVIDER_AVAILABILITY_ERROR_DETAIL {
  UNKNOWN = 'UNKNOWN',
  NOT_FOUND = 'NOT_FOUND',
  NO_WRITEABLE_CALENDAR = 'NO_WRITEABLE_CALENDAR',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
}

export type ProviderAvailabilities = {
  provider_id: string
  status: 'VALID' | 'ERROR'
  error_detail?: PROVIDER_AVAILABILITY_ERROR_DETAIL
  lead_time_hours: number
  appointment_duration_minutes: number
  availability: Array<ProviderAvailability>
  local_time_zone: string | null
}

export type AvailabilitiesResponse = {
  provider_ids: Array<string>
  provider_availability: Array<ProviderAvailabilities>
}

export type AvailabilityDate = {
  date: string
  times: Array<string | null>
}

export type ParsedAvailability = {
  appointmentDurationMinutes: number
  firstAvailability: TimeStamp
  lastAvailability: TimeStamp
  dates: Array<AvailabilityDate>
}

export type ProviderCalendarConfiguration = {
  calendar_id: string
  calendar_system: string
  id: string
  status: 'VALID' | 'ERROR'
  read_only: boolean
  time_zone: string
}

export type ProviderCalendarEvent = {
  event_type: string
  event_origin: string
  id: string
  provider_calendar_configuration_id: string
  external_id: string
  creator_email: string
  location: string | null
  title: string
  description: string
  start_datetimetz: string
  end_datetimetz: string
  time_zone: string
  recurring_event_id: string | null
  is_blocked: boolean
  extended_properties?: {
    private?: Dict
    shared?: Dict
  }
}

export type LyraEventExtendedProps = {
  lyraEventType: 'session'
  lyraProgramId: string | null | undefined
  appointmentClass: 'initial' | 'recurring'
  lyraAppointmentId: number
  clientFirstName: string | undefined
  clientLastName: string | undefined
  clientPhoneNumber: string | undefined
  appointmentSessionNumber: number
  appointmentMeetingFormat: MEETING_FORMATS
  clientTimeZone: string | null
  clientId: string | null
  isNoShow: boolean
}

export type LyraEventExtendedPropsNoTZ = Omit<LyraEventExtendedProps, 'clientTimeZone'>

export type BookableEventExtendedProps = {
  lyraEventType: 'bookable' | 'bookable_recurring'
  externalId: string
}

export type GenericEventExtendedProps = {
  lyraEventType: 'generic'
  description: string | null
  externalId: string
  calendarConfigurationId: string
  hasZoomMeeting: boolean
  clientTimeZone: string | null
  lyraAppointmentId: number | null
}

export type EventExtendedPropsBase = LyraEventExtendedProps | BookableEventExtendedProps | GenericEventExtendedProps

export type EventExtendedProps = EventExtendedPropsBase & {
  hovered?: boolean
  hasConflict?: boolean
}

type ConferenceDataEntryPoint = {
  entryPointType: string
  label: string
  meetingCode: string
  passcode: string
  uri: string
}
export type ProviderGoogleEvent = {
  kind: string
  etag: string
  id: string
  status: string
  htmlLink: string
  created: string
  updated: string
  summary: string
  description: string
  creator: {
    email: string
  }
  organizer: {
    email: string
    displayName: string
    self: boolean
  }
  start: {
    dateTime: string
    timeZone: string
  }
  end: {
    dateTime: string
    timeZone: string
  }
  iCalUID: string
  sequence: number
  attendees: {
    email: string
    displayName: string
    organizer: boolean
    self: boolean
    responseStatus: string
  }[]
  reminders: {
    useDefault: boolean
  }
  eventType: string
  conferenceData?: {
    entryPoints: ConferenceDataEntryPoint[]
  }
}

export enum BookingMode {
  FREEBUSY = 'freebusy',
  BOOKABLE = 'bookable',
}
export type CalendarProvider = {
  booking_mode: BookingMode
}
