import React from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { addWeeks, isAfter, isBefore, min } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { partition, uniq, uniqueId } from 'lodash-es'
import styled from 'styled-components/native'

import {
  Appointment,
  Assignment,
  Episode,
  getFriendlyDate,
  isAssignmentHidden,
  Provider,
} from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { CollapsibleSection } from '../../molecules/collapsibleSection/CollapsibleSection'
import { AssignmentsList, ItemAction } from '../../organisms/assignmentsList/AssignmentsList'
import { AssignmentsListItem } from '../../organisms/assignmentsList/AssignmentsListItem'
import { ThemeType, tID } from '../../utils'

export type SSCAssignmentsListProps = {
  activities: Assignment[]
  activityActions?: Array<ItemAction>
  addTrackHandler?: () => void
  appointments: Appointment[]
  assessments: Assignment[]
  currentEpisode: Episode
  isCareOnboardingPhase2Enabled?: boolean
  itemClickHandler?: (val: any) => void
  providers?: Provider[]
  sessionCount?: number
  userRole: string
  combineLinkedAssignments?: boolean
  simplifyStatus?: boolean
}

const CollapsibleSectionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const CurrentAssessmentsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

export const SSCAssignmentsList: React.FC<SSCAssignmentsListProps> = ({
  activities,
  activityActions = [],
  addTrackHandler,
  appointments,
  assessments,
  combineLinkedAssignments = false,
  currentEpisode,
  isCareOnboardingPhase2Enabled,
  itemClickHandler,
  providers = [],
  sessionCount,
  simplifyStatus = false,
  userRole,
}) => {
  const { formatMessage } = useIntl()
  const [currentEpisodeAssessments, otherEpisodeAssessments] = partition(assessments, ['episode_id', currentEpisode.id])
  const assignments = [...activities, ...currentEpisodeAssessments]

  const distinctWeeks: number[] =
    currentEpisode.curriculum[0].contents.length === 0
      ? [0]
      : uniq(assignments.map(({ session_period }) => Number(session_period) - 1)).sort((a: number, b: number) => a - b)

  const firstAppointmentDateTime = min(
    appointments
      .filter((appointment: Appointment) => appointment.episodeId === currentEpisode.id)
      .map(
        (appointment) =>
          new Date(zonedTimeToUtc(`${appointment.startDate}T${appointment.startTime}`, appointment.timeZone)),
      ),
  )

  const getAssignmentsToDisplay = (week: number) => {
    const weekAssignments = assignments.filter(
      (assignment: Assignment) => assignment.session_period === Number(week) + 1,
    )

    const contentsNames: string[] =
      week > 0 ? currentEpisode?.curriculum[week - 1].contents.map((content) => content.name) : []

    const weekCurriculumAssignments = weekAssignments
      .filter((assignment: Assignment) => contentsNames.includes(assignment.content.name))
      .sort(
        (assignmentA: Assignment, assignmentB: Assignment) =>
          contentsNames.indexOf(assignmentA.content.name) - contentsNames.indexOf(assignmentB.content.name),
      )
    const weekNonCurriculumAssessments = weekAssignments.filter(
      (assignment: Assignment) =>
        assignment.content.group === 'assessment' && !contentsNames.includes(assignment.content.name),
    )
    const weekNonCurriculumOtherAssignments = weekAssignments.filter(
      (assignment: Assignment) =>
        assignment.content.group !== 'assessment' && !contentsNames.includes(assignment.content.name),
    )

    const weekAllAssignments = [
      ...weekCurriculumAssignments,
      ...weekNonCurriculumAssessments,
      ...weekNonCurriculumOtherAssignments,
    ]

    const filteredAssignments: Assignment[] = combineLinkedAssignments
      ? [...weekCurriculumAssignments, ...weekNonCurriculumAssessments].filter(
          (assignment) =>
            !isAssignmentHidden({
              currentAssignment: assignment,
              assignments: weekAllAssignments,
              isCareOnboardingPhase2Enabled,
            }),
        )
      : [...weekCurriculumAssignments, ...weekNonCurriculumAssessments]

    const weekNumberedAssignments: Assignment[] = filteredAssignments.map((assignment: Assignment, index: number) => ({
      ...assignment,
      content: { ...assignment.content, title: (index + 1 + '. ').concat(assignment.content.title) },
    }))

    const weekDisplayedAssignments = weekNumberedAssignments.concat(
      combineLinkedAssignments
        ? weekNonCurriculumOtherAssignments.filter(
            (assignment) =>
              !isAssignmentHidden({
                currentAssignment: assignment,
                assignments: weekAllAssignments,
                isCareOnboardingPhase2Enabled,
              }),
          )
        : weekNonCurriculumOtherAssignments,
    )

    return { weekDisplayedAssignments, weekAllAssignments }
  }

  const getDueDate = (week: number) => addWeeks(firstAppointmentDateTime, week)

  const isCurrentWeek = (week: number) => {
    const currentDateTime = new Date()
    return (
      (isAfter(currentDateTime, getDueDate(week - 1)) && isBefore(currentDateTime, getDueDate(week))) ||
      (week === 6 && isAfter(currentDateTime, getDueDate(6)))
    )
  }

  const getWeekItem = (week: number) => {
    const {
      weekDisplayedAssignments,
      weekAllAssignments,
    }: { weekDisplayedAssignments: Assignment[]; weekAllAssignments: Assignment[] } = getAssignmentsToDisplay(week)

    const completedAssignments = weekDisplayedAssignments.reduce(
      (completed, assignment) =>
        completed +
        (assignment.status === 'completed' ||
        (assignment?.assignment_responses &&
          assignment?.assignment_responses.filter((response) => response.status === 'completed').length > 0)
          ? 1
          : 0),
      0,
    )

    const title =
      week < 1
        ? formatMessage({
            defaultMessage: 'To complete before session',
            description: 'Title on pre-session assignments list for Guided Self Care',
          })
        : formatMessage(
            {
              defaultMessage: 'Week {number} - {title}',
              description: "Title on week's assignments list for Guided Self Care",
            },
            {
              number: week,
              title: currentEpisode?.curriculum[week - 1]?.title,
            },
          )

    return (
      <CollapsibleSectionContainer>
        <CollapsibleSection
          title={title}
          subtitle={`Complete by ${getFriendlyDate({ date: getDueDate(week), displayTime: false })}`}
          initiallyOpen={distinctWeeks.length === 1 || isCurrentWeek(week)}
          rightItems={`${completedAssignments}/${weekDisplayedAssignments.length}`}
          content={
            <>
              {weekDisplayedAssignments.map((assignment: Assignment, idx: number) => (
                <AssignmentsListItem
                  key={uniqueId('assignmentListItem')}
                  assignment={assignment}
                  assignments={weekAllAssignments}
                  itemActions={activityActions}
                  itemClickHandler={itemClickHandler}
                  sessionCount={sessionCount}
                  showDueDate={false}
                  showProviderName={false}
                  userRole={userRole}
                  isFirstItem={false}
                  isLastItem={idx === weekDisplayedAssignments.length - 1}
                  combineLinkedAssignments={combineLinkedAssignments}
                  simplifyStatus={simplifyStatus}
                  isCareOnboardingPhase2Enabled={isCareOnboardingPhase2Enabled}
                />
              ))}
            </>
          }
        />
      </CollapsibleSectionContainer>
    )
  }

  return (
    <View testID={tID('SSCAssignmentsList-currentAssignments')}>
      <View testID={tID('SSCAssignmentsList-weekAssignmentsContainer')}>
        {distinctWeeks.map((week: number) => getWeekItem(week))}
      </View>
      {userRole === 'client' && otherEpisodeAssessments.length > 0 && (
        <CurrentAssessmentsContainer testID={tID('SSCAssignmentsList-currentAssessmentsContainer')}>
          <Subhead
            selectable={false}
            text={formatMessage({
              defaultMessage: 'Current assessments',
              description: 'Header for list of assessments from other episodes that GSC client needs to complete',
            })}
            size={SubheadSize.MEDIUM}
            testID={tID('SSCAssignmentsList-currentAssessmentstitle')}
            wrap
            noRole
          />
          <AssignmentsList
            assignments={otherEpisodeAssessments}
            userRole={userRole}
            itemActions={activityActions}
            itemClickHandler={itemClickHandler}
            combineLinkedAssignments={combineLinkedAssignments}
            simplifyStatus={simplifyStatus}
            providers={providers}
            isCareOnboardingPhase2Enabled={isCareOnboardingPhase2Enabled}
          />
        </CurrentAssessmentsContainer>
      )}
      {userRole === 'provider' && distinctWeeks.length === 1 && addTrackHandler && (
        <PrimaryButton
          testID={tID('SSCAssignmentsList-addTrackBtn')}
          onPress={addTrackHandler}
          text={formatMessage({
            defaultMessage: 'Add Care Plan',
            description: 'Button to add a track for a GSC client',
          })}
        />
      )}
    </View>
  )
}
