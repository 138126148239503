import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useBlocker, useLocation, useNavigate } from 'react-router-dom'

import { Map } from 'immutable'
import { cloneDeep, get, isEmpty, noop } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components/native'

import {
  Assignment,
  assignmentHasCompletedResponse,
  assignmentHasProviderDraft,
  AssignmentResponse,
  AssignmentStatuses,
  Curriculum,
  getProviderDraftResponse,
  ProviderUser,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  BootstrapContainer,
  dateUtils,
  DefaultButton,
  InfoSheet,
  Lesson,
  LoadingIndicator,
  // TODO: use UI-CC UIMetadata from https://lyrahealth.atlassian.net/browse/ICE-4495 when Worksheet is transposed as well
  UIMetadata,
} from '@lyrahealth-inc/ui-core'
import {
  ActivityEntry,
  ExerciseConfigDraftItem,
  ExerciseConfigStartDraft,
  FullScreenOverlay,
  InputFieldRFF,
  SelectFieldRFF,
  ThemeType,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { AddActivityModal } from './AddActivityModal'
import styles from './configAssignmentContainer.module.scss'
import ConfirmSessionForAssignment from './ConfirmSessionForAssignment'
import { DeleteExerciseDraftModal } from './DeleteExerciseDraftModal'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { CLIENT_HOME, LT_CLIENTS } from '../../common/constants/routingConstants'
import { getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientAppointmentsData,
  getClientAssignmentDetails,
  getClientAssignmentResponse,
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientSelectedEpisodeId,
  getSelectedEpisodeCurriculum,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../data/lyraTherapy/contentSelectors'
import {
  clearAssignmentResponse,
  deleteAssignmentResponse,
  setAssignment,
  setAssignmentDraftResponseToAssignment,
  unsetAssignment,
  updateAssignment,
} from '../assignments/data/assignmentsAutoActions'
import ProviderExerciseDraft from '../assignments/ProviderExerciseDraft'
import ConfigureCheckIn, { ConfigureCheckInRef } from '../configureCheckIn/ConfigureCheckIn'
import ConfigureMedsInfoSheet, { ConfigureMedsInfoSheetRef } from '../configureMedsInfoSheet/ConfigureMedsInfoSheet'
import { setToastContent } from '../data/ltToastAutoActions'
import { getLTVideoSessionOpen } from '../data/ltVideoSelectors'
import { updateCurriculum } from '../episodes/data/episodesAutoActions'
import FormSchemaBuilder, { FormSchemaBuilderRef } from '../formSchemaBuilder/FormSchemaBuilder'
import GroupedFieldsFormSchemaBuilder, {
  GroupedFieldsFormSchemaBuilderRef,
} from '../formSchemaBuilder/GroupedFieldsFormSchemaBuilder'
import MiniBackNav from '../miniBackNav/MiniBackNav'

type ConfigAssignmentContainerProps = ConnectedProps<typeof connector> & {
  actions: any
}

const StartDraftContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['32px']} 0px`,
}))

const ConfigAssignmentContainer: FunctionComponent<ConfigAssignmentContainerProps> = ({
  clientDetails,
  actions: {
    clearAssignmentResponse,
    deleteAssignmentResponse,
    setAssignment,
    setAssignmentDraftResponseToAssignment,
    setToastContent,
    updateCurriculum,
    updateAssignment,
    unsetAssignment,
  },
  curriculum,
  currentSessionCount,
  contents,
  appointments,
  programConfig,
  episodeId,
  videoSessionOpen,
  currentAssignmentResponse,
  currentAssignmentDetails,
}) => {
  const user: ProviderUser = useSelector(getAuthUser)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [addActivityModalOpen, setAddActivityModalOpen] = useState<boolean>(false)

  // 'content' can be a 'Content' object or an 'Assignment'
  // state is the navigation.state value set in Client.tsx
  const { content, editMode, sessionCount } = state || {}
  const { formatDate, formatTime } = useIntl()
  const providerDraftResponse = getProviderDraftResponse(content)
  const clientFirstName = clientDetails?.first_name

  // Fallback set up to handle both cases
  const group = get(content, 'content.group') || content?.group
  const type = get(content, 'content.content_type') || content?.content_type
  const name = get(content, 'content.name') || content?.name
  const metaData =
    get(content, 'content_meta_data') ??
    content?.meta_data ??
    contents.find((content: any) => content.name === name)?.meta_data
  const title = get(content, 'content.title') || content?.title
  const instructions =
    get(content, 'instructions') || contents.find((content: any) => content.name === name)?.instructions
  const contentId = content?.content_id || content?.id || contents.find((content: any) => content.name === name)?.id
  const assignmentId = content?.content_id ? content?.id : undefined
  const assignmentStatus = content?.status

  // We should try to use the assignment response that is in state as this
  // will be the most up to date.
  const selectedAssignmentResponse = !isEmpty(currentAssignmentResponse)
    ? currentAssignmentResponse
    : providerDraftResponse

  const adjustedDate = formatDate(selectedAssignmentResponse?.update_date ?? new Date(), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const adjustedTime = formatTime(selectedAssignmentResponse?.update_date ?? new Date())

  const [modalContent, setModalContent] = useState<React.ReactElement | null>(null)
  const [showingPreview, setShowingPreview] = useState<boolean>(false)

  const specificConfig = useRef<ConfigureCheckInRef | ConfigureMedsInfoSheetRef | FormSchemaBuilderRef | null>(null)

  const initialSession = sessionCount <= currentSessionCount ? currentSessionCount : sessionCount
  const initialSessionValue = !programConfig?.canPlanFutureAssignments ? content?.session_period - 1 : initialSession
  const initialValues = {
    session: !isNaN(initialSessionValue) ? initialSessionValue.toString() : '',
    instructions,
  }

  const isCurrentResponseProviderDraft =
    currentAssignmentResponse?.provider_started &&
    currentAssignmentResponse?.status === AssignmentStatuses.provider_draft &&
    !currentAssignmentResponse.assignment_id

  useEffect(() => {
    return () => {
      // if the provider leaves the config assignment, clear the assignment response in the Redux store
      clearAssignmentResponse()
    }
  }, [clearAssignmentResponse])

  const navigationBlocker = useBlocker(({ currentLocation, nextLocation }): boolean => {
    if (
      isCurrentResponseProviderDraft &&
      !currentAssignmentDetails?.id &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      setAddActivityModalOpen(true)
      return true
    } else {
      return false
    }
  })

  const onPreviewClick = (values: Dict) => {
    track({ event: 'BUTTON_PRESS', action: 'PREVIEW_ACTIVITY' })
    setModalContent(getPreview(values))
    setShowingPreview(true)
  }

  const goClientHome = () => {
    navigate(CLIENT_HOME.route, { state: { sessionCount } })
  }

  const navigateAfterAssignmentCreation = () => {
    if (navigationBlocker.state === 'blocked') {
      document.body.style.overflow = 'auto'
      navigationBlocker.proceed()
    } else {
      goClientHome()
    }
  }

  const submitDisabled = (values: Record<string, any>) => {
    let assignmentExists = false
    if (editMode) return false
    if (Number(values.session) > currentSessionCount) {
      const futureCurriculum = curriculum.filter((curr: Curriculum) => curr.session > currentSessionCount)
      const sessionToUpdate = futureCurriculum.findIndex(
        (sessionObject: any) => sessionObject.session === Number(values.session),
      )
      if (sessionToUpdate > -1) {
        assignmentExists = futureCurriculum[sessionToUpdate].contents.some(
          (assignment: any) => assignment.name === name,
        )
      }
    } else if (Number(values.session) === currentSessionCount) {
      // future Assignments logic
    }
    return assignmentExists
  }

  const generateAssignmentData = (values: Dict) => {
    const contentMetadata = specificConfig.current ? specificConfig.current.configure(values) : metaData
    const assignmentContent = cloneDeep(content)
    assignmentContent.meta_data = contentMetadata
    assignmentContent.schema = contentMetadata.schema
    assignmentContent.uiSchema = contentMetadata.uiSchema
    const assignmentInstructions: string = values.instructions as string
    const assignmentName = assignmentContent.name || assignmentContent.content.name

    return {
      assignmentContent,
      assignmentInstructions,
      assignmentName,
    }
  }

  const getPreview = (values: Dict) => {
    const { assignmentContent, assignmentInstructions, assignmentName } = generateAssignmentData(values)

    if (group === 'lesson') {
      return <Lesson metaData={metaData} instructions={assignmentInstructions} title={title} userRole='provider' />
    } else if (group === 'infosheet') {
      assignmentContent.instructions = assignmentInstructions
      assignmentContent.title = title
      return <InfoSheet content={assignmentContent} />
    } else {
      return (
        <ActivityEntry
          content={assignmentContent}
          deleteDraft={noop}
          exit={() => {
            setModalContent(null)
            setShowingPreview(false)
          }}
          focused={false}
          instructions={assignmentInstructions}
          name={assignmentName}
          saveForm={noop}
          showAllPages
          title={title}
          withPageBreaks={false}
          isProviderPreview
          videoSessionOpen={videoSessionOpen}
        />
      )
    }
  }

  const renderExerciseDraft = (values: Dict, assignmentResponse?: AssignmentResponse) => {
    const { assignmentContent, assignmentInstructions, assignmentName } = generateAssignmentData(values)

    setShowingPreview(true)
    setModalContent(
      <ProviderExerciseDraft
        assignmentId={assignmentId}
        content={assignmentContent}
        editMode={editMode}
        instructions={assignmentInstructions}
        name={assignmentName}
        onExit={() => {
          setModalContent(null)
          setShowingPreview(false)
        }}
        response={editMode ? selectedAssignmentResponse?.response : assignmentResponse?.response}
        responseId={editMode ? selectedAssignmentResponse?.id : assignmentResponse?.id}
        title={title}
      />,
    )
  }

  const renderDeleteExerciseDraftModal = () => {
    const onDelete = () => {
      if (assignmentHasProviderDraft(content)) {
        const draftResponse = content?.assignment_responses.find(
          (response: AssignmentResponse) => response.status === 'provider_draft',
        )
        if (draftResponse) {
          deleteAssignmentResponse(draftResponse)
          const updatedAssignmentResponse = cloneDeep(content)
          updatedAssignmentResponse.assignment_responses = updatedAssignmentResponse.assignment_responses.filter(
            (response: AssignmentResponse) => {
              return response.status !== 'provider_draft'
            },
          )
          navigate(window.location.pathname, {
            replace: true,
            state: { sessionCount, editMode, content: updatedAssignmentResponse },
          })
        }
      }
      clearAssignmentResponse()
      setModalContent(null)
      setToastContent({
        text: 'The saved draft has been deleted.',
        id: 'MessageDeleted-Toast-Success',
        toastType: 'success',
      })
    }

    setModalContent(
      <DeleteExerciseDraftModal
        adjustedDate={adjustedDate}
        onCancel={() => setModalContent(null)}
        onDelete={onDelete}
      />,
    )
  }

  const renderConfigView = (form: any, values: Dict) => {
    if (!curriculum) return false
    const futureCurriculum = curriculum.filter((curr: Curriculum) => curr.session > currentSessionCount)
    const showInstructionField = !['medsInfoSheet', 'baseInfoSheet'].includes(name)

    const currentSessionDropdownOption = {
      label: `${programConfig?.sessionLabel} ${
        !programConfig?.canPlanFutureAssignments ? content?.session_period - 1 : currentSessionCount
      }`,
      value: (!programConfig?.canPlanFutureAssignments ? content?.session_period - 1 : currentSessionCount).toString(),
    }

    const sessionDropdownOptions = !editMode
      ? futureCurriculum.map((sessionObject: any) => {
          return {
            label: `${programConfig?.sessionLabel} ${sessionObject.session}`,
            value: sessionObject.session.toString(),
          }
        })
      : []

    sessionDropdownOptions.unshift(currentSessionDropdownOption)

    return (
      <div className={styles['config-container']}>
        <div className={styles.sessionSelect}>
          <Field
            name='session'
            component={SelectFieldRFF}
            label='Session: '
            options={sessionDropdownOptions}
            readOnly={editMode}
            baseInputStyle={{ marginBottom: 10 }}
            useStringValue
          />
        </div>

        {group === 'exercise' && !assignmentHasCompletedResponse(content) && (
          <StartDraftContainer>
            {!isEmpty(selectedAssignmentResponse) ? (
              <ExerciseConfigDraftItem
                title={adjustedDate}
                timeSubText={adjustedTime}
                clientFirstName={clientFirstName ?? ''}
                renderExerciseDraft={() => renderExerciseDraft(values, selectedAssignmentResponse)}
                onDeleteExerciseDraft={() => renderDeleteExerciseDraftModal()}
                allowActions={selectedAssignmentResponse.status !== 'draft'}
              />
            ) : (
              <ExerciseConfigStartDraft onStartDraftPress={() => renderExerciseDraft(values)} />
            )}
          </StartDraftContainer>
        )}

        {showInstructionField && (
          <Field
            name='instructions'
            label='Instructions'
            placeholder='Enclose links in <> to make them clickable...'
            component={InputFieldRFF}
            multiline
            numberOfLines={10}
          />
        )}
        {renderFormSpecificConfig(form, values)}

        {addActivityModalOpen && navigationBlocker.state === 'blocked' && (
          <AddActivityModal
            onPrimaryButtonPress={() => {
              setAddActivityModalOpen(false)
              validateSelectedSession(values)
            }}
            onRequestClose={() => {
              navigationBlocker.reset()
            }}
            onTertiaryButtonPress={() => navigationBlocker.proceed()}
          />
        )}
      </div>
    )
  }

  const renderFormSpecificConfig = (form: any, formValues: Dict) => {
    const { initialize, restart } = form

    if (['checkIn', 'coachingCheckIn', 'medsCheckIn', 'bctCheckIn', 'bccCheckIn', 'bcmCheckIn'].includes(name)) {
      // CheckIn is a special snowflake
      return (
        <ConfigureCheckIn
          initialize={initialize}
          formValues={formValues}
          metadata={metaData}
          ref={(component: ConfigureCheckInRef) => {
            specificConfig.current = component as ConfigureCheckInRef | null
          }}
          form={form}
        />
      )
    } else if (name === 'medsInfoSheet' || name === 'baseInfoSheet') {
      return (
        <ConfigureMedsInfoSheet
          initialize={initialize}
          formValues={formValues}
          metadata={metaData}
          ref={(component) => {
            specificConfig.current = component as ConfigureMedsInfoSheetRef | null
          }}
          restart={restart}
          form={form}
        />
      )
    } else if (name === 'baseYouthTopProblems') {
      const fields = get(UIMetadata, `${name}.customFields.fields`)
      const maxNumberOfFieldGroups = get(UIMetadata, `${name}.customFields.maxNumberOfFieldGroups`)
      return fields ? (
        <GroupedFieldsFormSchemaBuilder
          constraints={fields}
          maxNumberOfFieldGroups={maxNumberOfFieldGroups || 10}
          metadata={metaData}
          ref={(component) => {
            specificConfig.current = component as GroupedFieldsFormSchemaBuilderRef | null
          }}
          name={name}
          title={title}
        />
      ) : null
    } else {
      const fields = get(UIMetadata, `${name}.customFields.fields`)
      return fields ? (
        <FormSchemaBuilder
          constraints={fields}
          metadata={metaData}
          ref={(component) => {
            specificConfig.current = component as FormSchemaBuilderRef | null
          }}
          name={name}
          title={title}
        />
      ) : null
    }
  }

  const validateSelectedSession = (values: any) => {
    const selectedSession = parseInt(get(values, 'session'))
    const selectedAppointment = appointments.find((appt: any) => appt.sessionNumber === selectedSession)
    if (
      selectedSession === currentSessionCount &&
      programConfig?.shouldConfirmSameSessionAssignment &&
      dateUtils.getAppointmentDateTimeObject(selectedAppointment).subtract(24, 'hours').isBefore()
    ) {
      setModalContent(
        <ConfirmSessionForAssignment
          selectedSession={selectedSession}
          appointmentDate={dateUtils.getAppointmentDateTimeObject(selectedAppointment).format('ddd, MMM D h:mm A')}
          assignContent={(session) => assignContent(values, session)}
          showNextSessionOption={selectedSession !== curriculum[curriculum.length - 1].session}
        />,
      )
    } else {
      assignContent(values, selectedSession)
    }
  }

  const assignContent = (values: any, selectedSession: number) => {
    const contentMetadata = specificConfig.current ? specificConfig.current.configure(values) : metaData
    const isExerciseForFutureSessionWithProviderDraft =
      group === 'exercise' && selectedSession > currentSessionCount && isCurrentResponseProviderDraft

    const sessionPeriod = !programConfig?.canPlanFutureAssignments
      ? content?.session_period
      : isExerciseForFutureSessionWithProviderDraft
      ? selectedSession
      : currentSessionCount

    const data = {
      provider_id: user.id,
      patient_id: clientDetails?.id,
      content_id: contentId,
      content_meta_data: contentMetadata,
      instructions: values.instructions,
      session_period: sessionPeriod,
    }

    let assignmentToUpdate = -1

    const curriculumCopy = cloneDeep(curriculum)
    // if the session is the current session, update the assignment data
    if (selectedSession === currentSessionCount || !programConfig?.canPlanFutureAssignments) {
      if (editMode) {
        const assignmentData = {
          id: assignmentId,
          status: assignmentStatus,
        }
        return updateAssignment(Object.assign(data, assignmentData)).then(goClientHome())
      }
      return setAssignment(data).then((assignment: Assignment) => {
        // If the currentAssignmentResponse is a draft started by the Provider then we need to update the
        // assignment response with the Exercise id so the draft can be associated with the Exercise.
        if (isCurrentResponseProviderDraft) {
          setAssignmentDraftResponseToAssignment(
            Object.assign(cloneDeep(currentAssignmentResponse), {
              assignment_id: assignment.id,
            }),
          ).then(() => {
            clearAssignmentResponse()
            navigateAfterAssignmentCreation()
          })
        } else {
          goClientHome()
        }
      })
    } else if (isExerciseForFutureSessionWithProviderDraft && !assignmentId) {
      const sessionToUpdate = curriculumCopy.findIndex((sessionObject) => sessionObject.session === selectedSession)

      // If the Exercise has a Provider draft and the Exercise is being added to a future Session,
      // we need to create the Assignment, add the Provider draft to it and update the curriculum
      return setAssignment(data)
        .then((assignment: Assignment) => {
          return setAssignmentDraftResponseToAssignment(
            Object.assign(cloneDeep(currentAssignmentResponse), {
              assignment_id: assignment.id,
            }),
          )
        })
        .then((response: any) => {
          const curriculumContent = {
            name,
            title,
            group,
            content_type: type,
            instructions: values.instructions,
            content_meta_data: contentMetadata,
            id: response.assignment_id,
          }
          if (editMode) {
            assignmentToUpdate = curriculumCopy[sessionToUpdate].contents.findIndex(
              (assignment: any) => assignment.name === name,
            )
            curriculumCopy[sessionToUpdate].contents[assignmentToUpdate] = curriculumContent
          } else {
            curriculumCopy[sessionToUpdate].contents.push(curriculumContent)
          }

          return updateCurriculum({ id: episodeId, curriculum: curriculumCopy })
        })
        .then(() => {
          clearAssignmentResponse()
          navigateAfterAssignmentCreation()
        })
    } else {
      // If we are editing a future Session Assignment with an ID we want to update it and not touch the Curriculum
      if (editMode && assignmentId) {
        const assignmentData = {
          id: assignmentId,
          status: assignmentStatus,
          session_period: selectedSession,
        }
        return updateAssignment(Object.assign(data, assignmentData)).then(goClientHome())
      }

      // if the session is in the future, update the curriculum for the corresponding session
      const sessionToUpdate = curriculumCopy.findIndex(
        (sessionObject: any) => sessionObject.session === selectedSession,
      )

      if (editMode) {
        if (sessionToUpdate > -1) {
          assignmentToUpdate = curriculumCopy[sessionToUpdate].contents.findIndex(
            (assignment: any) => assignment.name === name,
          )
        }
        curriculumCopy[sessionToUpdate].contents[assignmentToUpdate].content_meta_data = contentMetadata
        curriculumCopy[sessionToUpdate].contents[assignmentToUpdate].instructions = values.instructions
      } else {
        curriculumCopy[sessionToUpdate].contents.push({
          name,
          title,
          group,
          content_type: type,
          instructions: values.instructions,
          content_meta_data: contentMetadata,
          content_id: contentId,
        })
      }
      if (assignmentId) unsetAssignment({ id: assignmentId })
      return updateCurriculum({ id: episodeId, curriculum: curriculumCopy }).then(goClientHome())
    }
  }

  const isLoading = !clientDetails || !curriculum
  if (!clientDetails || isEmpty(clientDetails)) navigate(LT_CLIENTS.route)

  return (
    <div style={{ marginTop: '30px' }}>
      <BootstrapContainer col='col-md-10 col-md-offset-1'>
        <div style={{ display: 'flex' }}>
          <MiniBackNav backFunc={() => navigate(-1)} subText='' />
          {isLoading ? (
            <div className={styles['loading-container']}>
              <LoadingIndicator size={45} />
            </div>
          ) : (
            <div className={styles['details-card']}>
              <Form
                onSubmit={(values) => validateSelectedSession(values)}
                initialValues={initialValues}
                render={({ form, handleSubmit, submitting, values }) => {
                  return (
                    <>
                      <div className={styles.header}>
                        <h1>{title}</h1>
                        <div className={styles['button-container']}>
                          <DefaultButton
                            type='button'
                            isOutlined
                            onClick={() => onPreviewClick(values)}
                            data-test-id='ConfigAssignmentContainer-preview'
                          >
                            Preview activity
                          </DefaultButton>
                          <DefaultButton
                            data-test-id={`ConfigAssignmentContainer-${editMode ? `UpdateActivity` : `AddActivity`}`}
                            disabled={submitDisabled(values)}
                            isLoading={submitting}
                            type='submit'
                            onClick={() => handleSubmit()}
                            onKeyDown={() => handleSubmit()}
                          >
                            {editMode ? 'Update activity' : 'Add activity'}
                          </DefaultButton>
                        </div>
                      </div>
                      {renderConfigView(form, values)}
                    </>
                  )
                }}
              />
            </div>
          )}
        </div>
      </BootstrapContainer>

      {group === 'exercise' && showingPreview ? (
        <FullScreenOverlay
          isOpen={group === 'exercise' && showingPreview && !!modalContent}
          customStyles={videoSessionOpen ? { top: '90px', zIndex: '10' } : {}}
        >
          {modalContent}
        </FullScreenOverlay>
      ) : (
        <BaseModal
          isOpen={!!modalContent}
          body={modalContent ?? <div />}
          closeModal={() => {
            setModalContent(null)
            setShowingPreview(false)
          }}
          modalClass={group === 'lesson' && showingPreview ? 'digital-lesson' : undefined}
        />
      )}
    </div>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  const episodeId = getClientSelectedEpisodeId($$state) || ''
  const appointments = getClientAppointmentsData($$state).filter((appt: any) => appt.episodeId === episodeId)

  return {
    clientDetails: getClientDetailsData($$state),
    currentSessionCount: getClientCurrentSessionCount($$state) || 0,
    curriculum: getSelectedEpisodeCurriculum($$state) || [],
    episodeId,
    contents: getLyraTherapyContentsData($$state) || [],
    appointments,
    programConfig: getClientEpisodeProgramConfig($$state),
    videoSessionOpen: getLTVideoSessionOpen($$state),
    currentAssignmentResponse: getClientAssignmentResponse($$state),
    currentAssignmentDetails: getClientAssignmentDetails($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        clearAssignmentResponse,
        deleteAssignmentResponse,
        setAssignment,
        setAssignmentDraftResponseToAssignment,
        setToastContent,
        updateAssignment,
        updateCurriculum,
        unsetAssignment,
      },
      dispatch,
    ),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ConfigAssignmentContainer)
