import { createSelector } from '@reduxjs/toolkit'

import calendarReducer from './calendarReducer'

export const getCalendarState = (state: Map<string, any>) =>
  state?.get('calendar') as ReturnType<typeof calendarReducer>

export const getCalendarRequiresAuthorization = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.requiresAuthorization,
)

export const getCalendarAvailabilitySlots = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.availabilitySlots,
)

export const getCalendarConfiguration = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.calendars?.[0] ?? null,
)

export const getCalendarGoogleEvents = createSelector(getCalendarState, (calendarState) => calendarState?.googleEvents)

export const getCalendarProvider = createSelector(getCalendarState, (calendarState) => calendarState?.provider)

export const getCalendarShowCalendarLiveModal = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.showCalendarLiveModal,
)
