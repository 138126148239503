import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  PermissionsIllustration,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  TextButton,
  XIcon,
} from '../../atoms'
import { Modal } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderCalendarAuthorizationModalProps = {
  onAuthorizePressed?: () => void
  onCancelPressed?: () => void
  isVisible?: boolean
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignSelf: 'flex-start',
  gap: theme.spacing['48px'],
  alignItems: 'center',
}))

const Header = styled.View({
  flexDirection: 'column',
  alignItems: 'center',
})

const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['16px'],
  alignSelf: 'flex-end',
  alignItems: 'center',
}))

const LinkButton = styled(TextButton)({
  alignSelf: 'center',
})

const HeaderText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

export const ProviderCalendarAuthorizationModal: FunctionComponent<ProviderCalendarAuthorizationModalProps> = ({
  onAuthorizePressed,
  onCancelPressed,
  isVisible = false,
}) => {
  const theme = useTheme() as ThemeType
  const ref = useRef<BottomSheetGorhom>(null)
  const openBottomSheet = useCallback(() => {
    ref.current?.expand()
  }, [])
  return (
    <Modal
      visible={isVisible}
      bottomSheetRef={ref}
      snapPoints={[550]}
      width='600px'
      onLayout={openBottomSheet}
      modalContents={
        <Container>
          <CloseButton hitSlop={60} onPress={() => onCancelPressed?.()}>
            <XIcon size={24} fillColor={theme.colors.iconDefault} />
          </CloseButton>
          <Header>
            <PermissionsIllustration />
            <HeaderText
              textAlign='center'
              size={SubheadSize.MEDIUM}
              text={
                <FormattedMessage
                  defaultMessage='Login to your Google account to authorize access to your calendar'
                  description='Subhead for provider calendar authorize card'
                />
              }
            />
            <BodyText
              textAlign='center'
              color={theme.colors.textSecondary}
              text={
                <FormattedMessage
                  defaultMessage='We’ll be able to show you Google Calendar events on your Lyra calendar'
                  description='Body text for provider calendar authorize card'
                />
              }
            />
          </Header>
          <Row>
            <LinkButton
              text={
                <FormattedMessage defaultMessage='Cancel' description='Button to cancel authorization of calendar' />
              }
              onPress={() => onCancelPressed?.()}
            />
            <PrimaryButton
              testID={tID('ProviderCalendarAuthorizationModal-authorize-button')}
              text={<FormattedMessage defaultMessage='Authorize' description='Button to authorize google calendar' />}
              onPress={() => onAuthorizePressed?.()}
            />
          </Row>
        </Container>
      }
      onRequestClose={() => onCancelPressed?.()}
      onCloseEnd={() => onCancelPressed?.()}
    />
  )
}
