import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { BodyText, BulletedList, PrimaryButton, Subhead, TertiaryButton } from '../../atoms'
import { BackButtonV2 } from '../../atoms/backButton/BackButtonV2'
import { Modal } from '../../organisms/modal/Modal'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type RecordConsentModalProps = {
  show: boolean
  onDontRecordSessionPressed: () => void
  onDontRecordEpisodePressed: () => void
  onRecordSessionPressed: () => void
  onClose: () => void
}

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing['16px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
}))
const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['12px'],
}))

export const RecordConsentModal: FunctionComponent<RecordConsentModalProps> = ({
  show,
  onDontRecordEpisodePressed,
  onDontRecordSessionPressed,
  onRecordSessionPressed,
  onClose,
}) => {
  const [isEpisodeModalOpen, setIsEpisodeModalOpen] = useState(false)

  const initialContents = (
    <>
      <Subhead
        text={
          <FormattedMessage
            defaultMessage='Remember to get consent for recording'
            description='Title of modal where provider is getting consent to record the session'
          />
        }
        size={SubheadSize.MEDIUM}
      />
      <ContentContainer>
        <BodyText
          text={
            <FormattedMessage
              defaultMessage='Let your client know:'
              description='Title for points that provider should share the client about recording'
            />
          }
        />
        <BulletedList
          spaceBetween={8}
          data={[
            {
              value: (
                <FormattedMessage
                  defaultMessage='Sessions are securely recorded for quality assurance purposes'
                  description='Bullet point describing a point for providers to share about recordings'
                />
              ),
            },
            {
              value: (
                <FormattedMessage
                  defaultMessage='Kept for 2 weeks then deleted'
                  description='Bullet point describing a point for providers to share about recordings'
                />
              ),
            },
            {
              value: (
                <FormattedMessage
                  defaultMessage='Only certain internal team members may review recordings, all bound by same commitment to
              confidentiality'
                  description='Bullet point describing a point for providers to share about recordings'
                />
              ),
            },
          ]}
        />
      </ContentContainer>
      <ButtonContainer>
        <TertiaryButton
          text={
            <FormattedMessage defaultMessage="Don't record" description='Button text to not record video session' />
          }
          onPress={() => setIsEpisodeModalOpen(true)}
          testID={tID('RecordConsentModal-dont-record-button')}
        />
        <PrimaryButton
          text={<FormattedMessage defaultMessage='Record' description='Button text to record video session' />}
          onPress={() => {
            onClose()
            onRecordSessionPressed()
          }}
          testID={tID('RecordConsentModal-record-button')}
        />
      </ButtonContainer>
    </>
  )

  const episodeContents = (
    <>
      <BackButtonV2 testID={tID('RecordConsentModal-back-button')} onPress={() => setIsEpisodeModalOpen(false)} />
      <Subhead
        text={
          <FormattedMessage
            defaultMessage='Turn off recordings for just this session or the entire episode?'
            description='Title of modal where provider is can decide to record session or entire episode'
          />
        }
        size={SubheadSize.MEDIUM}
      />

      <ButtonContainer>
        <PrimaryButton
          text={
            <FormattedMessage
              defaultMessage='This session'
              description='Button text that provider can select to record only the current session'
            />
          }
          onPress={() => {
            onClose()
            onDontRecordSessionPressed()
          }}
          testID={tID('RecordConsentModal-dont-record-session-button')}
        />
        <PrimaryButton
          text={
            <FormattedMessage
              defaultMessage='Entire episode'
              description='Button text that provider can select to record entire episode'
            />
          }
          onPress={() => {
            onClose()
            onDontRecordEpisodePressed()
          }}
          testID={tID('RecordConsentModal-dont-record-episode-button')}
        />
      </ButtonContainer>
    </>
  )
  return (
    <Modal
      visible={show}
      onCloseEnd={onClose}
      onRequestClose={onClose}
      disableBottomSheet
      modalContents={
        <Container testID={tID('RecordConsentModal')}>
          {isEpisodeModalOpen ? episodeContents : initialContents}
        </Container>
      }
    />
  )
}
