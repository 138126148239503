import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { addMilliseconds, differenceInHours, parseISO, subMilliseconds } from 'date-fns'
import { bindActionCreators } from 'redux'
import styledWeb from 'styled-components'
import styled from 'styled-components/native'

import {
  Appointment,
  BookingMode,
  getTimezoneOffset,
  isBookableEvent,
  LyraEventExtendedPropsNoTZ,
  ProviderCalendarEvent,
  ProviderGoogleEvent,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  LoadingIndicator,
  ProviderCalendar,
  ProviderCalendarRef,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import './providerCalendar.scss'
import { CalendarIsLiveConfirmationModal } from './CalendarIsLiveModal'
import CalendarSetupCard from './CalendarSetupCard'
import {
  getCalendarAvailabilitySlots,
  getCalendarEvents,
  getCalendarProvider,
  getCalendars,
  getCalendarToken,
  getGoogleEvent,
  patchCalendarEvent,
  postCalendarEvents,
} from './data/calendarActions'
import {
  getCalendarAvailabilitySlots as getCalendarAvailabilitySlotsSelector,
  getCalendarConfiguration,
  getCalendarGoogleEvents,
  getCalendarProvider as getCalendarProviderSelector,
  getCalendarRequiresAuthorization,
  getCalendarShowCalendarLiveModal,
} from './data/calendarSelectors'
import { getSessionExtendedProperties, mapEventToBookableEvent, mapExtendedProps } from './utils'
import { CLIENT_HOME, SETTINGS } from '../common/constants/routingConstants'
import { getAuthUserId } from '../data/auth/authSelectors'
import { getLTAppointments } from '../lyraTherapy/clients/clientDetails/data/appointmentsAutoActions'
import { getLtClient } from '../lyraTherapy/clients/clientDetails/data/ltClientDetailsAutoActions'
import { setToastContent } from '../lyraTherapy/data/ltToastAutoActions'
import { getLTVideoAppointments } from '../lyraTherapy/data/ltVideoSelectors'

const SetupCard = styled(CalendarSetupCard)(({ theme }) => ({
  paddingTop: theme.spacing['72px'],
}))

const CalendarContainer = styledWeb.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
})

const CurrentCalendar: FunctionComponent<CurrentCalendarProps> = ({
  actions: {
    getCalendarEvents,
    getCalendarToken,
    getCalendarAvailabilitySlots,
    getLTAppointments,
    getLtClient,
    patchCalendarEvent,
    getCalendars,
    setToastContent,
    postCalendarEvents,
    getGoogleEvent,
    getCalendarProvider,
    hideCalendarLiveModal,
  },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const userId = useSelector(getAuthUserId)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const availabilitySlots = useSelector(getCalendarAvailabilitySlotsSelector)
  const appointments = useSelector(getLTVideoAppointments)
  const calendarConfiguration = useSelector(getCalendarConfiguration)
  const googleEvents = useSelector(getCalendarGoogleEvents)
  const showCalendarLiveModal = useSelector(getCalendarShowCalendarLiveModal)
  const navigate = useNavigate()
  const calendarProvider = useSelector(getCalendarProviderSelector)
  const calendarRef = useRef<ProviderCalendarRef>(null)
  const appointmentsMap = useRef<{ [key: number]: Appointment }>({})

  useEffect(() => {
    appointmentsMap.current = appointments.reduce((acc, curr) => {
      acc[curr.appointmentId] = curr
      return acc
    }, {} as { [key: number]: Appointment })
  }, [appointments])

  useFetcher(
    [
      [getCalendarToken, { providerId: userId }, !!userId],
      [getLTAppointments, { id: userId }, !!userId],
      [getCalendarAvailabilitySlots, { providerId: userId }, !!userId],
      [getCalendars, { providerId: userId }, !!userId],
      [getCalendarProvider, { providerId: userId }, !!userId],
    ],
    [userId],
  )

  const getEventsForRange = useCallback(
    async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      const tzOffset = getTimezoneOffset(timeZone, startDate)
      const { data: events } = await getCalendarEvents({
        startDate: subMilliseconds(parseISO(startDate), tzOffset).toISOString(),
        endDate: subMilliseconds(parseISO(endDate), tzOffset).toISOString(),
        providerId: userId,
      })
      return events
        .filter((event) => {
          if (event.is_blocked) {
            return false
          }
          if (event.event_type === 'bookable_recurring') {
            return false
          }
          const duration = differenceInHours(parseISO(event.end_datetimetz), parseISO(event.start_datetimetz))
          return duration < 24
        })
        .map((event) => {
          const extendedProps = mapExtendedProps(event, appointmentsMap.current)
          return {
            id: event.id,
            title: event.title,
            start: addMilliseconds(parseISO(event.start_datetimetz), tzOffset).toISOString(),
            end: addMilliseconds(parseISO(event.end_datetimetz), tzOffset).toISOString(),
            startEditable: isBookableEvent(extendedProps),
            extendedProps,
          }
        })
    },
    [getCalendarEvents, timeZone, userId],
  )

  useEffect(() => {
    if (appointments.length > 0) {
      const extendedPropsMap = appointments.reduce((acc, curr) => {
        acc[curr.appointmentId] = getSessionExtendedProperties(curr)
        return acc
      }, {} as { [key: number]: LyraEventExtendedPropsNoTZ })
      calendarRef.current?.updateAppointments(extendedPropsMap)
    }
  }, [appointments])

  const onEventMoved = useCallback(
    (event: { externalId: string; startTime: string; endTime: string }, revert: () => void) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      patchCalendarEvent({
        external_id: event.externalId,
        body: {
          provider_calendar_configuration_id: calendarConfiguration.id,
          event: {
            start: event.startTime,
            end: event.endTime,
          },
        },
      })
        .then(() => {
          setToastContent({
            text: 'Availability updated this week',
            toastType: 'success',
            id: 'CurrentCalendar-move-success',
          })
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to update availability',
            toastType: 'error',
            id: 'CurrentCalendar-move-error',
          })
          revert()
        })
    },
    [calendarConfiguration, patchCalendarEvent, setToastContent],
  )

  const onEventsAdded = useCallback(
    (
      events: { startTime: string; endTime: string }[],
      revert: () => void,
      attachEventID: (events: { id: string }[]) => void,
    ) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      postCalendarEvents({
        provider_calendar_configuration_id: calendarConfiguration?.id,
        events: events.map((event) => mapEventToBookableEvent(event, timeZone, false)),
      })
        .then((response) => {
          setToastContent({
            text: 'Availability added this week',
            toastType: 'success',
            id: 'CurrentCalendar-add-success',
          })
          attachEventID(response.data)
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to add availability',
            toastType: 'error',
            id: 'CurrentCalendar-add-error',
          })
          revert()
        })
    },
    [calendarConfiguration?.id, postCalendarEvents, setToastContent, timeZone],
  )

  const onEventDeleted = useCallback(
    (event: { externalId: string }, revert: () => void) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      patchCalendarEvent({
        external_id: event.externalId,
        body: {
          provider_calendar_configuration_id: calendarConfiguration.id,
          event: {
            status: 'cancelled',
          },
        },
      })
        .then(() => {
          setToastContent({
            text: 'Availability deleted this week',
            toastType: 'success',
            id: 'CurrentCalendar-delete-success',
          })
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to delete availability',
            toastType: 'error',
            id: 'CurrentCalendar-delete-error',
          })
          revert()
        })
    },
    [calendarConfiguration, patchCalendarEvent, setToastContent],
  )

  const getOrFetchGoogleEvent = useCallback(
    async (externalId: string, calendarConfigurationId: string) => {
      if (externalId in googleEvents) {
        return googleEvents[externalId]
      }

      const event = await getGoogleEvent({
        external_id: externalId,
        provider_calendar_configuration_id: calendarConfigurationId,
      })
      return event.data
    },
    [getGoogleEvent, googleEvents],
  )

  const onOpenInGoogleCalendar = useCallback(
    (externalId: string, calendarConfigurationId: string) => {
      getOrFetchGoogleEvent(externalId, calendarConfigurationId)
        .then((event) => {
          window.open(event.htmlLink, '_blank')
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to open google calendar',
            toastType: 'error',
            id: 'CurrentCalendar-open-google-error',
          })
        })
    },
    [getOrFetchGoogleEvent, setToastContent],
  )

  const onOpenZoom = useCallback(
    (externalId: string, calendarConfigurationId: string) => {
      getOrFetchGoogleEvent(externalId, calendarConfigurationId)
        .then((event) => {
          const zoomUrl = event.conferenceData?.entryPoints.find(
            (entryPoint) => entryPoint.entryPointType === 'video',
          )?.uri
          if (zoomUrl) {
            window.open(zoomUrl, '_blank')
          } else {
            setToastContent({
              text: 'Failed to open zoom',
              toastType: 'error',
              id: 'CurrentCalendar-open-zoom-error',
            })
          }
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to open zoom',
            toastType: 'error',
            id: 'CurrentCalendar-open-zoom-error',
          })
        })
    },
    [getOrFetchGoogleEvent, setToastContent],
  )
  if (!isInProductCalendarEnabled) {
    return null
  }

  if (requiresAuth == null || availabilitySlots == null) {
    return <LoadingIndicator />
  }

  if (requiresAuth || availabilitySlots.length === 0 || calendarProvider?.booking_mode !== BookingMode.BOOKABLE) {
    return <SetupCard />
  }

  return (
    <>
      <CalendarContainer className='lc-calendar'>
        <ProviderCalendar
          ref={calendarRef}
          timeZone={timeZone}
          getEvents={getEventsForRange}
          loading={requiresAuth === undefined}
          onSettingsPressed={() => navigate(SETTINGS.route)}
          onClientProfilePressed={async (clientId) => {
            await getLtClient({ clientId, providerId: userId })
            navigate({ pathname: CLIENT_HOME.route })
          }}
          onEventMoved={onEventMoved}
          onEventsAdded={onEventsAdded}
          onEventDeleted={onEventDeleted}
          onOpenInGoogleCalendar={onOpenInGoogleCalendar}
          onOpenZoom={onOpenZoom}
        />
      </CalendarContainer>
      <CalendarIsLiveConfirmationModal visible={showCalendarLiveModal} onClosePress={hideCalendarLiveModal} />
    </>
  )
}

export type CurrentCalendarProps = {
  actions: {
    getCalendarToken: (params: Parameters<typeof getCalendarToken>[0]) => Promise<any>
    getCalendarEvents: (params: Parameters<typeof getCalendarEvents>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    getCalendarAvailabilitySlots: (params: Parameters<typeof getCalendarAvailabilitySlots>[0]) => Promise<any>
    getLTAppointments: (params: Parameters<typeof getLTAppointments>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    getLtClient: (params: Parameters<typeof getLtClient>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    patchCalendarEvent: (params: Parameters<typeof patchCalendarEvent>[0]) => Promise<any>
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    setToastContent: (params: Parameters<typeof setToastContent>[0]) => Promise<any>
    postCalendarEvents: (params: Parameters<typeof postCalendarEvents>[0]) => Promise<{ data: { id: string }[] }>
    getGoogleEvent: (params: Parameters<typeof getGoogleEvent>[0]) => Promise<{ data: ProviderGoogleEvent }>
    getCalendarProvider: (params: Parameters<typeof getCalendarProvider>[0]) => Promise<any>
    hideCalendarLiveModal: () => Promise<any>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendarEvents: getCalendarEvents as any,
      getCalendarToken: getCalendarToken as any,
      getCalendarAvailabilitySlots: getCalendarAvailabilitySlots as any,
      getLTAppointments: getLTAppointments as any,
      getLtClient: getLtClient as any,
      patchCalendarEvent: patchCalendarEvent as any,
      getCalendars: getCalendars as any,
      setToastContent: setToastContent as any,
      postCalendarEvents: postCalendarEvents as any,
      getGoogleEvent: getGoogleEvent as any,
      getCalendarProvider: getCalendarProvider as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CurrentCalendar)
