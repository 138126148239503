import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactElement } from 'react-markdown'

import styled, { useTheme } from 'styled-components/native'

import { EventExtendedProps, isOOOEvent } from '@lyrahealth-inc/shared-app-logic'

import { AlertIconStroke, CheckMarkInCircleIcon, Headline, IconBadge } from '../../atoms'
import { Corners } from '../../atoms/iconBadge/IconBadge'
import { HeadlineSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderCalendarEventBadgesProps = {
  title: string
  extendedProps: EventExtendedProps
}

const BadgeContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

export const ProviderCalendarEventBadges: FunctionComponent<ProviderCalendarEventBadgesProps> = ({
  title,
  extendedProps,
}) => {
  const { colors } = useTheme() as ThemeType
  const badgeColors = colors.components.providerCalendar.badge
  const badges = useMemo(() => {
    const badges: ReactElement[] = []
    if (isOOOEvent(title)) {
      badges.push(
        <IconBadge
          cornerType={Corners.CURVED}
          key='ooo'
          text={
            <Headline
              size={HeadlineSize.EYEBROW}
              text={
                <FormattedMessage
                  defaultMessage='Time off event found'
                  description='Label that appears above event when time off event is found'
                />
              }
              color={badgeColors.ooo.text}
            />
          }
          color={badgeColors.ooo.background}
          frontIcon={<CheckMarkInCircleIcon size={16} fillColor={badgeColors.ooo.text} />}
        />,
      )
    }

    if (extendedProps.hasConflict) {
      badges.push(
        <IconBadge
          cornerType={Corners.CURVED}
          key='conflict'
          text={
            <Headline
              size={HeadlineSize.EYEBROW}
              text={
                <FormattedMessage
                  defaultMessage='Conflict'
                  description='Label that appears above event when conflict is found'
                />
              }
              color={badgeColors.conflict.text}
            />
          }
          color={badgeColors.conflict.background}
          textColor={badgeColors.conflict.text}
          frontIcon={<AlertIconStroke size={16} fillColor={badgeColors.conflict.text} />}
        />,
      )
    }

    return badges
  }, [
    badgeColors.conflict.background,
    badgeColors.conflict.text,
    badgeColors.ooo.background,
    badgeColors.ooo.text,
    extendedProps.hasConflict,
    title,
  ])
  return <BadgeContainer testID={tID('ProviderCalendarEventBadges')}>{badges}</BadgeContainer>
}
