import { isString } from 'lodash-es'

import { formValidationConstants } from '@lyrahealth-inc/ui-core'

const providerRatesValidate = ($$values: any) => {
  const errors = {}

  const areNumbers = [
    {
      fieldName: 'individual_initial_evaluation',
      errorText: 'Individual initial evaluation',
    },
    {
      fieldName: 'individual_follow_up_session',
      errorText: 'Individual follow up',
    },
    {
      fieldName: 'couples_initial_evaluation',
      errorText: 'Couples initial evaluation',
    },
    {
      fieldName: 'couples_follow_up_session',
      errorText: 'Couples follow up',
    },
    {
      fieldName: 'family_initial_evaluation',
      errorText: 'Family initial evaluation',
    },
    {
      fieldName: 'family_follow_up_session',
      errorText: 'Family follow up',
    },
    {
      fieldName: 'group_initial_evaluation',
      errorText: 'Group initial evaluation',
    },
    {
      fieldName: 'group_follow_up_session',
      errorText: 'Group follow up',
    },
    {
      fieldName: 'medication_management_initial_evaluation',
      errorText: 'Medication management initial evaluation',
    },
    {
      fieldName: 'medication_management_follow_up_session',
      errorText: 'Medication management follow up',
    },
    {
      fieldName: 'child_initial_evaluation',
      errorText: 'CPP initial evaluation',
    },
    {
      fieldName: 'child_follow_up_session',
      errorText: 'CPP follow up',
    },
    {
      fieldName: 'smp_session',
      errorText: 'Stress Management Program',
    },
    {
      fieldName: 'minor_medication_management_initial_evaluation',
      errorText: 'Minor management initial evaluation',
    },
    {
      fieldName: 'minor_medication_management_follow_up_evaluation',
      errorText: 'Minor management follow up',
    },
    {
      fieldName: 'differential_pricing_in_person_modifier',
      errorText: 'In person modifier',
    },
  ]
  for (const r of areNumbers) {
    let value = $$values.get(r.fieldName)
    if (value) {
      value = isString(value) ? value : value.toString()
      if (!formValidationConstants.isValidCurrencyAmount(value)) {
        errors[r.fieldName] = r.errorText + ' rate must be a number'
      }
    }
  }

  return errors
}

export default providerRatesValidate
