import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { isEmpty, isNil } from 'lodash-es'
import styled from 'styled-components/native'

import { BRANCH_APP_LINK, ProgramCustomerPropertyId, useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  CareNavigatorCharacterSpotIllustration,
  colors,
  ControlledMedsIllustration,
  CopyToClipboard,
  GuidedSelfCareSecondaryRec,
  HeaderText,
  IndividualTherapy,
  ProfileBookingCoachingParentsIllustration,
  ProfileBookingLyraCommunityMedicationIllustration,
  ProfileBookingLyraCommunityTherapyIllustration,
  ProfileBookingShortTermDisabilityEvaluationIllustration,
  RenewIllustration,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  benefitsProgramNames,
  LWUrl,
  programBookingLinkProgramNames,
  programConfig,
  programNameTitleMap,
} from '../../../../common/constants/appConstants'
import { getClientDetailsData } from '../../../../data/lyraTherapy/clientSelectors'

const ProgramContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: theme.spacing['16px'],
  flexDirection: 'row',
  marginBottom: theme.spacing['16px'],
  padding: theme.spacing['16px'],
}))

const ProgramContainerOLD = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ProgramIconCotainer = styled.View({
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '72px',
})

const ProgramContainerContents = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginLeft: theme.spacing['16px'],
  overflow: 'wrap',
}))

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const CopyProfileLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: 0,
  marginTop: theme.spacing['16px'],
}))

const ProgramTypeHeading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const BenefitsEligibility: React.FC<BenefitsEligibilityProps> = ({
  currentProgramCoverageBreakdown,
  programCoverageName,
}) => {
  const clientDetails = useSelector(getClientDetailsData)
  const { showNewProgramBookingLinks } = useFlags()

  const coverageStates = {
    EAP: 'Your client can use EAP sessions.',
    EAP_HP: 'After using their EAP sessions, your client can use an eligible health plan to pay for sessions.',
    EAP_SPECIALTY:
      'Your client can use EAP sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions.',
    EAP_HP_SPECIALTY:
      'After using their EAP sessions, your client can use an eligible health plan to pay for sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions.',
    HP: 'Your client can use an eligible health plan to pay for sessions.',
    HP_SPECIALTY:
      'Your client can use an eligible health plan to pay for sessions. If they have an active substance use disorder, are at risk for hospitalization, or have treatment resistant conditions, they may continue past the recommended sessions',
    SPECIALTY:
      'If your client has an active substance use disorder, is at risk for hospitalization, or has treatment resistant conditions, they may continue with sessions.',
  }

  // Coaching for Parents has the same properties as the Coaching program, but it has its own Program Booking Links
  const modifiedProgramCoverageBreakdown = { ...currentProgramCoverageBreakdown }
  if (currentProgramCoverageBreakdown.hasOwnProperty('stressManagement')) {
    modifiedProgramCoverageBreakdown.coachingForParents = { ...currentProgramCoverageBreakdown.stressManagement }
  }

  const getProgramIconByProgramName = (programName: string) => {
    if (isNil(programName) || programName.length === 0) {
      return <></>
    }
    switch (programName) {
      case ProgramCustomerPropertyId.alcoholUseDisorderTherapy:
        return <RenewIllustration />
      case ProgramCustomerPropertyId.blendedCareMeds:
        return <ControlledMedsIllustration />
      case ProgramCustomerPropertyId.clinicalLeaveEvaluation:
        return <ProfileBookingShortTermDisabilityEvaluationIllustration />
      case ProgramCustomerPropertyId.considerLyraTherapy:
        return <IndividualTherapy size={72} />
      case ProgramCustomerPropertyId.stressManagement:
        return <CareNavigatorCharacterSpotIllustration size={72} />
      case ProgramCustomerPropertyId.coachingForParents:
        return <ProfileBookingCoachingParentsIllustration />
      case ProgramCustomerPropertyId.guidedSelfCareEnabled:
        return <GuidedSelfCareSecondaryRec />
      case ProgramCustomerPropertyId.directAccessTherapy:
        return <ProfileBookingLyraCommunityTherapyIllustration />
      case ProgramCustomerPropertyId.considerMeds:
        return <ProfileBookingLyraCommunityMedicationIllustration />
      default:
        return <></>
    }
  }

  const shouldShowProgramBookingLink = (programCoverageName: string, bookingLinkProgram: string) => {
    return programConfig[programCoverageName].programBookingLinksAllowed.includes(bookingLinkProgram)
  }

  const getIsProgramCovered = (program: string) => {
    if (showNewProgramBookingLinks) {
      return (
        program in modifiedProgramCoverageBreakdown &&
        !(
          !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        )
      )
    }
    return (
      program in currentProgramCoverageBreakdown &&
      !(
        !currentProgramCoverageBreakdown[program]?.eapSupported &&
        !currentProgramCoverageBreakdown[program]?.bhbSupported &&
        !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
      )
    )
  }

  const getProgramCoverageDescription = (program: string) => {
    if (showNewProgramBookingLinks) {
      if (program in modifiedProgramCoverageBreakdown) {
        if (
          !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.SPECIALTY
        } else if (
          !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.HP
        } else if (
          !modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.HP_SPECIALTY
        } else if (
          modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP
        } else if (
          modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          !modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_SPECIALTY
        } else if (
          modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          !modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_HP
        } else if (
          modifiedProgramCoverageBreakdown[program]?.eapSupported &&
          modifiedProgramCoverageBreakdown[program]?.bhbSupported &&
          modifiedProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_HP_SPECIALTY
        }
      }
    } else {
      if (program in currentProgramCoverageBreakdown) {
        if (
          !currentProgramCoverageBreakdown[program]?.eapSupported &&
          !currentProgramCoverageBreakdown[program]?.bhbSupported &&
          currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.SPECIALTY
        } else if (
          !currentProgramCoverageBreakdown[program]?.eapSupported &&
          currentProgramCoverageBreakdown[program]?.bhbSupported &&
          !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.HP
        } else if (
          !currentProgramCoverageBreakdown[program]?.eapSupported &&
          currentProgramCoverageBreakdown[program]?.bhbSupported &&
          currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.HP_SPECIALTY
        } else if (
          currentProgramCoverageBreakdown[program]?.eapSupported &&
          !currentProgramCoverageBreakdown[program]?.bhbSupported &&
          !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP
        } else if (
          currentProgramCoverageBreakdown[program]?.eapSupported &&
          !currentProgramCoverageBreakdown[program]?.bhbSupported &&
          currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_SPECIALTY
        } else if (
          currentProgramCoverageBreakdown[program]?.eapSupported &&
          currentProgramCoverageBreakdown[program]?.bhbSupported &&
          !currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_HP
        } else if (
          currentProgramCoverageBreakdown[program]?.eapSupported &&
          currentProgramCoverageBreakdown[program]?.bhbSupported &&
          currentProgramCoverageBreakdown[program]?.specialtyBhbSupported
        ) {
          return coverageStates.EAP_HP_SPECIALTY
        }
      }
    }
    return null
  }

  const getProgramBookingLinkByProgramName = (programName: string, employer: string) => {
    if (isEmpty(programName) || isEmpty(employer)) {
      return ''
    }
    let treatment = ''
    if (
      programName === ProgramCustomerPropertyId.considerLyraTherapy ||
      programName === ProgramCustomerPropertyId.alcoholUseDisorderTherapy
    ) {
      treatment = programNameTitleMap.Therapy
    } else if (
      programName === ProgramCustomerPropertyId.stressManagement ||
      programName === ProgramCustomerPropertyId.coachingForParents ||
      programName === ProgramCustomerPropertyId.guidedSelfCareEnabled
    ) {
      treatment = programNameTitleMap.Coaching
    } else if (programName === ProgramCustomerPropertyId.blendedCareMeds) {
      treatment = programBookingLinkProgramNames.MedicationManagement
    } else if (programName === ProgramCustomerPropertyId.clinicalLeaveEvaluation) {
      treatment = programBookingLinkProgramNames.Assessment
    }

    let offering = 'Default'
    if (programName === ProgramCustomerPropertyId.alcoholUseDisorderTherapy) {
      offering = programBookingLinkProgramNames.AlcoholUseDisorder
    } else if (programName === ProgramCustomerPropertyId.coachingForParents) {
      offering = programBookingLinkProgramNames.Parenting
    } else if (programName === ProgramCustomerPropertyId.guidedSelfCareEnabled) {
      offering = programBookingLinkProgramNames.SingleSession
    } else if (programName === ProgramCustomerPropertyId.clinicalLeaveEvaluation) {
      offering = programBookingLinkProgramNames.ClinicalLeave
    }
    // only the BRANCH_APP_LINK needs query params, it will be passed down to the fallback link
    const baseLink = `${BRANCH_APP_LINK}directPathBooking?directPath=true&clientele=Individual&treatment=${treatment}&partner=BlendedCare&offering=${offering}&directLinkIntent=DIRECT_LINK_CONCURRENT_CARE_REFERRAL&directLinkSource=provider_message&$fallback_url=${LWUrl(
      employer,
    )}/secure/onboard/match-location`
    return baseLink
  }

  const programLinks = [
    {
      name: ProgramCustomerPropertyId.considerLyraTherapy,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.considerLyraTherapy,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.alcoholUseDisorderTherapy,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.alcoholUseDisorderTherapy,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.stressManagement,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.stressManagement,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.coachingForParents,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.coachingForParents,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.guidedSelfCareEnabled,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.guidedSelfCareEnabled,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.blendedCareMeds,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.blendedCareMeds,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.clinicalLeaveEvaluation,
      category: 'lyraCare',
      bookingLink: getProgramBookingLinkByProgramName(
        ProgramCustomerPropertyId.clinicalLeaveEvaluation,
        clientDetails?.employer ?? '',
      ),
    },
    {
      name: ProgramCustomerPropertyId.directAccessTherapy,
      category: 'directAccess',
    },
    {
      name: ProgramCustomerPropertyId.considerMeds,
      category: 'directAccess',
    },
  ]
  if (showNewProgramBookingLinks) {
    const programCoverageBreakdownKeys = Object.keys(modifiedProgramCoverageBreakdown)
    programCoverageBreakdownKeys.sort((a, b) => {
      return (
        programLinks.map((item) => item.name).indexOf(a as ProgramCustomerPropertyId) -
        programLinks.map((item) => item.name).indexOf(b as ProgramCustomerPropertyId)
      )
    })

    const lyraCarePrograms = programCoverageBreakdownKeys.filter((item: string) =>
      programLinks.find((program) => program.category === 'lyraCare' && program.name === item),
    )
    const directAccessPrograms = programCoverageBreakdownKeys.filter((item: string) =>
      programLinks.find((program) => program.category === 'directAccess' && program.name === item),
    )
    const getProgramContainer = (programNames: Array<string>) => {
      return programNames
        .filter(
          (program: string) => Object.keys(benefitsProgramNames).includes(program) && getIsProgramCovered(program),
        )
        .map((program: string) => {
          const programBookingLink = programLinks.find((item) => item.name === program)?.bookingLink
          return (
            <ProgramContainer key={program} testID={tID(`BenefitsEligibility-${program}`)}>
              <ProgramIconCotainer>{getProgramIconByProgramName(program)}</ProgramIconCotainer>
              <ProgramContainerContents>
                <SubheadContainer>
                  <Subhead
                    testID={tID(`BenefitsEligibility-${program}-programName`)}
                    text={benefitsProgramNames[program]}
                    size={SubheadSize.XSMALL}
                    color={colors.ui_oatmeal6}
                  />
                </SubheadContainer>
                <BodyText
                  testID={tID(`BenefitsEligibility-${program}-coverageDescription`)}
                  text={getProgramCoverageDescription(program)}
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />

                {!isNil(programBookingLink) && shouldShowProgramBookingLink(programCoverageName, program) && (
                  <CopyProfileLinkContainer>
                    <CopyToClipboard
                      displayText='Program Booking Link'
                      testID={tID(`BenefitsEligibility-program-booking-link-${program}`)}
                      copyText={programBookingLink}
                    />
                  </CopyProfileLinkContainer>
                )}
              </ProgramContainerContents>
            </ProgramContainer>
          )
        })
    }
    return (
      <View>
        <ProgramTypeHeading testID={tID(`BenefitsEligibility-programingBookingLinksHeader-lyraCare`)}>
          <HeaderText text='Lyra Care' />
        </ProgramTypeHeading>
        {getProgramContainer(lyraCarePrograms)}
        <ProgramTypeHeading testID={tID(`BenefitsEligibility-programingBookingLinksHeader-directAccess`)}>
          <HeaderText text='Direct Access' />
        </ProgramTypeHeading>
        {getProgramContainer(directAccessPrograms)}
      </View>
    )
  } else {
    return (
      <View>
        {Object.keys(currentProgramCoverageBreakdown)
          .filter((program) => Object.keys(benefitsProgramNames).includes(program) && getIsProgramCovered(program))
          .map((program: string) => (
            <ProgramContainerOLD key={program} testID={tID(`BenefitsEligibility-${program}`)}>
              <SubheadContainer>
                <Subhead
                  testID={tID(`BenefitsEligibility-${program}-programName`)}
                  text={benefitsProgramNames[program]}
                  size={SubheadSize.XSMALL}
                  color={colors.ui_oatmeal6}
                />
              </SubheadContainer>
              <BodyText
                testID={tID(`BenefitsEligibility-${program}-coverageDescription`)}
                text={getProgramCoverageDescription(program)}
                size={BodyTextSize.DEFAULT}
                color={colors.ui_oatmeal5}
              />
            </ProgramContainerOLD>
          ))}
      </View>
    )
  }
}

export type ProgramCoverageBreakdown = {
  blendedCareMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  directAccessTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerLyraTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  alcoholUseDisorderTherapy?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  guidedSelfCareEnabled?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  considerMeds?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  stressManagement?: {
    bhbSupported: boolean
    eapSupported: boolean
    specialtyBhbSupported: boolean
  }
  coachingForParents?: {
    bhbSupported?: boolean
    eapSupported?: boolean
    specialtyBhbSupported?: boolean
  }
}

type BenefitsEligibilityProps = {
  currentProgramCoverageBreakdown: ProgramCoverageBreakdown
  programCoverageName: string
}

export default BenefitsEligibility
