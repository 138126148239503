import React, { FunctionComponent } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { noop } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { Appointment, CustomerInfo, ProviderUser } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, CardFullWidth, NavButton } from '@lyrahealth-inc/ui-core'
import { BookingSteps, EligibilityErrorBanner, SessionDetails, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { APPOINTMENT_OPERATIONS, getMeetingFormats } from '../../common/constants/appConstants'
import {
  CLIENTS_NEW_SESSION_DETAILS,
  CLIENTS_RESCHEDULE_SESSION_DETAILS,
  CLIENTS_SESSIONS,
} from '../../common/constants/routingConstants'
import { getAuthUser } from '../../data/auth/authSelectors'
import {
  getClientAppointmentDetails,
  getClientAppointments,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientLiveMessaging,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { getRequestPaymentClientCustomers } from '../../data/requestPayment/requestPaymentSelectors'
import useFetchClientEligibility from '../../hooks/useFetchClientEligibility'
import { checkEligibility } from '../../requestPayment/data/requestPaymentActions'
import {
  clearLTAppointments,
  clearSelectedAppointment,
  createAppointment,
  createRepeatingAppointment,
  getLTAppointments,
  getLTAppointmentsForPatient,
  setAppointment,
  setCurrentSessionCount,
  updateAppointment,
} from '../clients/clientDetails/data/appointmentsAutoActions'

/**
 * Allows a provider to specify details of the appointment they are scheduling between themselves
 * and a client. Details include:
 * * The modality of the appointment (video, phone)
 * * The duration of the appointment (25, 50, 80 minutes)
 *
 * Additionally, if the provider is scheduling a new appointment, they will be able to specify:
 * * The frequency for which the given appointment should repeat (none, biweekly)
 * * The number of sessions that should be created, if there is a repeating frequency specified
 * These details will not appear if the provider is rescheduling an existing appointment.
 */

const SessionDetailsContainer: FunctionComponent<SessionDetailsContainerProps> = ({
  operation,
  appointment,
  clientDetails,
  programConfig,
  selectedEpisode,
  liveMessagingEnabled,
  actions: { setAppointment, clearSelectedAppointment, checkEligibility },
}) => {
  const user: ProviderUser = useSelector(getAuthUser)
  const navigate = useNavigate()
  const customers: CustomerInfo[] = useSelector(getRequestPaymentClientCustomers)
  const clientCustomer = customers?.find(
    (customer: CustomerInfo) => customer?.value?.toLowerCase() === clientDetails?.employer?.toLowerCase(),
  )

  const eligibilityError = useFetchClientEligibility({
    shouldFetchEligibility: clientDetails?.country === 'US' && clientCustomer?.eap_eligibility_check_enabled === 'true',
    clientDetails,
    // @ts-expect-error TS(2322): Type '(data: any) => { action: string; request: { ... Remove this comment to see the full error message
    checkEligibility,
    programName: selectedEpisode?.program_name,
  })

  const onBack = () => {
    clearSelectedAppointment()
    navigate(CLIENTS_SESSIONS.route)
  }
  let displayRepeatingFields = false
  let onSessionSubmission = noop
  let appointmentType = 'new'
  let appointmentStatus
  // The following switch/case block allows for this component to manage both create new session and rescheduling session.
  switch (operation) {
    case APPOINTMENT_OPERATIONS.NEW:
      displayRepeatingFields = true
      onSessionSubmission = ($$values: any) => {
        setAppointment({ appointment: $$values })
        navigate(CLIENTS_NEW_SESSION_DETAILS.route)
      }
      appointmentStatus = 'newAppt'
      appointmentType = 'new'
      break
    case APPOINTMENT_OPERATIONS.RESCHEDULE:
      displayRepeatingFields = false
      onSessionSubmission = ($$values: any) => {
        setAppointment({ appointment: $$values })
        navigate(CLIENTS_RESCHEDULE_SESSION_DETAILS.route)
      }
      appointmentType = 'rescheduled'
      appointmentStatus = 'rescheduled'
      break
  }

  const appointmentDuration =
    appointmentType === 'rescheduled' ? appointment?.appointmentDuration : programConfig?.defaultAppointmentDuration
  const initialValues = {
    appointmentDuration: appointmentDuration != null ? appointmentDuration : undefined,
    meetingFormat: appointmentType === 'rescheduled' ? appointment?.meetingFormat : 'video',
    sessionFrequency: '0',
    appointmentStatus,
  }

  // liveMessagingEnabled is a customer property and text_sessions_enabled is a property belonging to the provider
  const isLiveMessagingEnabled = (liveMessagingEnabled && user?.text_sessions_enabled) || false
  // if live messaging is not enabled, filter out live_messaging as an option
  const enabledMeetingFormats = getMeetingFormats(isLiveMessagingEnabled ? {} : { exclusions: ['live_messaging'] })

  const CardFullWidthDetailsCard = styled(CardFullWidth)({
    padding: '40px',
    marginBottom: '80px',
  })

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
      <div style={{ marginTop: '50px' }}>
        {eligibilityError && <EligibilityErrorBanner />}
        <NavButton text='Sessions' styleType='back' onClick={onBack} />
        <CardFullWidthDetailsCard withShadow={false} roundCorners>
          <SessionDetails
            appointment={appointment as Appointment}
            appointmentType={appointmentType}
            displayRepeatingFields={displayRepeatingFields}
            meetingFormats={enabledMeetingFormats}
            displayAppointmentDuration
            initialValues={initialValues as any}
            onSubmitForm={onSessionSubmission}
            onCancel={() => navigate(CLIENTS_SESSIONS.route)}
            partyName={clientDetails?.full_name || ''}
            appointmentDurationOptions={programConfig?.appointmentDurationOptions}
            nextStep={BookingSteps.SCHEDULE}
            sessionFrequencyOptions={programConfig?.sessionFrequencyOptions}
          />
        </CardFullWidthDetailsCard>
      </div>
    </BootstrapContainer>
  )
}

type SessionDetailsContainerProps = ConnectedProps<typeof connector> & {
  operation: string
}

const mapStateToProps = ($$state: any) => {
  return {
    clientDetails: getClientDetailsData($$state),
    appointment: getClientAppointmentDetails($$state),
    appointments: getClientAppointments($$state) || [],
    liveMessagingEnabled: getClientLiveMessaging($$state) || false,
    programConfig: getClientEpisodeProgramConfig($$state),
    selectedEpisode: getClientSelectedEpisode($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getLTAppointmentsForPatient,
      setCurrentSessionCount,
      clearLTAppointments,
      getLTAppointments,
      createAppointment,
      createRepeatingAppointment,
      updateAppointment,
      setAppointment,
      clearSelectedAppointment,
      checkEligibility,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(SessionDetailsContainer))
