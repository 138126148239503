import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { differenceInMinutes } from 'date-fns'
import { Map } from 'immutable'
import { capitalize, isEmpty } from 'lodash-es'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import {
  Assignment,
  BRANCH_APP_LINK,
  convertToInternationalFormat,
  CustomerInfo,
  DEFAULT_COUNTRY_ISO_CODE,
  getClientPreferredNameIfExists,
  getPhoneNumberInE164Format,
  ProgramNames,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { dateUtils } from '@lyrahealth-inc/ui-core'
import {
  BodyText,
  BodyTextSize,
  colors,
  CopyToClipboard,
  EligibilityErrorBanner,
  FormBody,
  FormContentHeader,
  Headline,
  HeadlineSize,
  Modal,
  NoteIcon,
  PrimaryButton,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import BenefitsEligibility from './BenefitsEligibility'
import HealthPlanStatus from './HealthPlanStatus'
import UpdateEpisodeModal from '../../../../common/components/UpdateEpisode/UpdateEpisodeModal'
import {
  episodeStates,
  episodeTerminationStatuses,
  LWUrl,
  parentIntakeQualtricsUrl,
  providerInitialDirectBookingLink,
  ROLES,
} from '../../../../common/constants/appConstants'
import { LT_CLIENTS } from '../../../../common/constants/routingConstants'
import sessionResetDate from '../../../../common/content/sessionResetDate'
import { hasRole } from '../../../../common/utils/utils'
import { addAlert } from '../../../../data/alertActions'
import { getAuthConfig, getAuthRoles, getAuthUserId } from '../../../../data/auth/authSelectors'
import {
  getClientAppointmentsData,
  getClientAssignmentsData,
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientEpisodesData,
  getClientSelectedEpisode,
  getClientUnclosedPastAppointments,
} from '../../../../data/lyraTherapy/clientSelectors'
import { getRequestPaymentClientCustomers } from '../../../../data/requestPayment/requestPaymentSelectors'
import useFetchClientEligibility from '../../../../hooks/useFetchClientEligibility'
import { checkEligibility } from '../../../../requestPayment/data/requestPaymentActions'
import { updateEpisode } from '../../../episodes/data/episodesAutoActions'
import { getLTAppointmentsForPatient } from '../data/appointmentsAutoActions'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `0 auto ${theme.spacing['32px']} auto`,
  padding: `0 ${theme.spacing['64px']}`,
  maxWidth: theme.breakpoints.isMinWidthLaptop ? '1072px' : '992px',
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginBottom: theme.spacing['16px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative',
}))

const ColumnsSection = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const InfoActionsColumn = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '33%',
  paddingRight: theme.spacing['8px'],
}))

const BenefitsColumn = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '67%',
  paddingLeft: theme.spacing['8px'],
}))

const SectionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  padding: theme.spacing['32px'],
  backgroundColor: colors.white,
  borderRadius: '16px',
  border: `1px solid ${colors.ui_oatmeal3}`,
}))

const SectionHeader = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['16px'],
  borderBottomWidth: '1px',
  borderBottomColor: colors.ui_oatmeal3,
}))

const DataEntryContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const DataEntryValueContainer = styled.View({
  textAlign: 'center',
  flexDirection: 'row',
  alignItems: 'center',
})

const CopyToClipboardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const CopyProfileLinkContainer = styled.View({
  marginLeft: 0,
  marginTop: '16px',
})

const EligibilityErrorBannerContainer = styled.View({
  marginBottom: '-8px',
})

const CompanyInfoContainer = styled.View({
  flexDirection: 'row',
})

const CompanySessionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '50%',
  paddingRight: theme.spacing['8px'],
}))

const ResetDateContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '50%',
  paddingLeft: theme.spacing['8px'],
}))

const BenefitsEligibilityContainer = styled.View({
  marginTop: '22px',
})

const ViewIntakeButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const NoteIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const ClientProfile: FunctionComponent<ClientProfileProps> = ({
  clientDetails,
  intakeForm,
  selectedEpisode,
  currentSessionCount,
  customers,
  episodes,
  appointments,
  programConfig,
  pastAppointmentsToClose,
  actions: { addAlert, updateEpisode, checkEligibility, getLTAppointmentsForPatient },
}) => {
  const config = useSelector(getAuthConfig)
  const intl = useIntl()
  const userId = useSelector(getAuthUserId)
  const roles: string[] = useSelector(getAuthRoles)
  const { formatMessage } = useIntl()

  const appointmentToClose = pastAppointmentsToClose.find((appt: any) => {
    return appt.episodeId === selectedEpisode?.id
  })
  const [modalData, setModalData] = useState<null | JSX.Element>(null)
  const [isUpdatingEpisode, setIsUpdatingEpisode] = useState(false)
  const { isPreferredNameEnabled, shouldShowDirectProgramLinkInClientProfile, showNewProgramBookingLinks } = useFlags()
  const navigate = useNavigate()

  const isTherapyOrAUDProvider = hasRole(roles, [ROLES.LT_THERAPIST, ROLES.LT_AUD_THERAPIST, ROLES.LT_FELLOW_THERAPIST])
  const clientCustomer = customers?.find(
    (customer: CustomerInfo) => customer?.value?.toLowerCase() === clientDetails?.employer?.toLowerCase(),
  )
  const eligibilityError = useFetchClientEligibility({
    shouldFetchEligibility: clientDetails?.country === 'US' && clientCustomer?.eap_eligibility_check_enabled === 'true',
    clientDetails,
    // @ts-expect-error TS(2322): Type '(data: any) => { action: string; request: { ... Remove this comment to see the full error message
    checkEligibility,
    programName: selectedEpisode?.program_name,
  })

  useEffect(() => {
    if (!clientDetails?.id) {
      navigate(LT_CLIENTS.route, { replace: true })
    }
  }, [clientDetails?.id, navigate])

  const [, , fetchedAppointments] = useFetcher([
    getLTAppointmentsForPatient,
    { provider_id: userId, patient_id: clientDetails?.id },
  ])

  if (!clientDetails || !selectedEpisode) {
    return null
  }
  const modifyEpisode = (endEpisodeReason: string, comment: string) => {
    const currentEpisodeState = selectedEpisode?.state
    const updatedEpisodeFields = { state: endEpisodeReason, end_episode_note: comment }
    let successMessage = 'episode has been updated.'
    if (currentEpisodeState === episodeStates.IN_PROGRESS && endEpisodeReason !== episodeStates.IN_PROGRESS) {
      successMessage = 'current episode has been successfully ended.'
      ;(updatedEpisodeFields as any).end_date = moment().utc().format()
    } else if (currentEpisodeState !== endEpisodeReason) {
      successMessage = 'termination status has been updated.'
    }
    setIsUpdatingEpisode(true)
    ;(updateEpisode({ ...selectedEpisode, ...updatedEpisodeFields }) as any)
      .then(() => {
        return addAlert({
          show: true,
          contents: `${clientDetails.full_name}'s ${successMessage}`,
          style: 'success',
          expires: true,
        })
      })
      .finally(() => {
        setIsUpdatingEpisode(false)
        setModalData(null)
      })
  }

  const renderHealthPlanStatus = (healthPlanStatus: string) => {
    const hasEAPSessions = clientDetails.eap_session_limit !== 0
    const programName = selectedEpisode?.program_name
    const currentYear = moment().format('YYYY')
    const programCoverageBreakdown = customers.find(
      (customer: any) => customer.value.toLowerCase() === clientDetails.employer.toLowerCase(),
    )?.program_coverage_breakdown
    const currentProgramCoverageBreakdown = programCoverageBreakdown[currentYear]

    return (
      <p>
        <HealthPlanStatus
          program={programName}
          currentProgramCoverageBreakdown={currentProgramCoverageBreakdown}
          status={healthPlanStatus}
          hasEAPSessions={hasEAPSessions}
        />
      </p>
    )
  }

  const getTerminationStatuses = () => {
    return episodes.length > 1 && currentSessionCount === 2
      ? episodeTerminationStatuses.map((terminationStatus) => {
          return { label: terminationStatus.text, value: terminationStatus.value }
        })
      : episodeTerminationStatuses
          .filter((status) => status.value !== 'booster')
          .map((terminationStatus) => {
            return { label: terminationStatus.text, value: terminationStatus.value }
          })
  }

  const intakeResponse = intakeForm?.assignment_responses?.[0]
  const intakeModalContent = () => {
    const content_meta_data = intakeForm?.content_meta_data
    const response = intakeResponse?.response
    const date = intakeResponse?.submit_date
    const friendlyDate =
      differenceInMinutes(new Date(), date as any) < 1 ? 'just now' : dateUtils.getFriendlyDate(date, false, true)
    const dateDisplay = `Submitted ${friendlyDate}`
    const group = intakeForm?.content?.group
    const title = intakeForm?.content?.title
    return (
      <>
        <FormContentHeader title={title} group={group} dateDisplay={dateDisplay} />
        <FormBody
          schema={content_meta_data?.schema}
          initialValues={response}
          uiSchema={content_meta_data?.uiSchema}
          instructions={intakeForm?.instructions}
          name={intakeForm?.content?.name}
          saveForm={() => {}}
          readOnly
          intl={intl}
          formButton={undefined}
          withPageBreaks={false}
          useCustomizedActivityIntroduction={intakeForm?.content_meta_data?.useCustomizedActivityIntroduction}
          hasNewLookAndFeel
        />
      </>
    )
  }

  const updateEpisodeModalContent = (
    <UpdateEpisodeModal
      selectedEpisode={selectedEpisode}
      clientDetails={clientDetails}
      closeModal={() => setModalData(null)}
      updateEpisode={modifyEpisode}
      isUpdatingEpisode={isUpdatingEpisode}
      terminationStatuses={getTerminationStatuses()}
    />
  )
  const { IN_PROGRESS } = episodeStates
  const client = clientDetails
  const shouldShowPreferredName = isPreferredNameEnabled && client.employer === 'apple'

  const phoneNumberInE164Format = client.phone
    ? getPhoneNumberInE164Format({
        phoneNumber: client.phone,
        countryCode: DEFAULT_COUNTRY_ISO_CODE,
      })
    : undefined

  const currentEpisodeUpcomingAppointments = appointments?.filter(
    (appt: any) => appt.episodeId === selectedEpisode?.id && !appt.pastAppointment,
  )

  const currentProgramCoverageBreakdown =
    clientCustomer?.program_coverage_breakdown && clientCustomer?.program_coverage_breakdown[moment().format('YYYY')]

  const validProgramCoverage =
    programConfig &&
    selectedEpisode?.program_name &&
    currentProgramCoverageBreakdown &&
    programConfig?.programCoverageName in currentProgramCoverageBreakdown

  const currentProgramCoveredByEAP =
    programConfig &&
    validProgramCoverage &&
    currentProgramCoverageBreakdown[programConfig.programCoverageName]?.eapSupported

  const currentProgramCoveredByBHB =
    programConfig &&
    validProgramCoverage &&
    currentProgramCoverageBreakdown[programConfig.programCoverageName]?.bhbSupported

  const currentProgramCoveredBySpecialtyBHB =
    validProgramCoverage && currentProgramCoverageBreakdown[programConfig?.programCoverageName]?.specialtyBhbSupported

  const shouldDisplayParentIntakeLink =
    selectedEpisode?.program_name === ProgramNames.TeensTherapy && selectedEpisode?.external_ids?.qualtrics

  const DataEntry = ({
    headlineText,
    testID,
    text,
  }: {
    headlineText?: string
    testID: string | undefined
    text?: string | React.ReactElement
  }) => {
    return (
      <DataEntryContainer>
        {headlineText && <Headline text={headlineText} size={HeadlineSize.EYEBROW} color={colors.ui_oatmeal6} />}
        <BodyText testID={testID} text={text} size={BodyTextSize.DEFAULT} color={colors.ui_oatmeal5} />
      </DataEntryContainer>
    )
  }

  const PersonalSection = () => {
    return (
      <SectionContainer>
        <SectionHeader>
          <Subhead
            size={SubheadSize.SMALL}
            text={formatMessage({
              defaultMessage: 'Personal',
              description: 'header for personal info on client profile',
            })}
            color={colors.ui_oatmeal6}
          />
        </SectionHeader>
        {shouldShowPreferredName ? (
          <>
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Legal Name',
                description: "subheader for client's legal name",
              })}
              testID={tID('ClientProfile-name')}
              text={client.full_name}
            />
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Preferred Name',
                description: "subheader for client's preferred name",
              })}
              testID={tID('ClientProfile-preferred-name')}
              text={getClientPreferredNameIfExists(client)}
            />
          </>
        ) : (
          <DataEntry
            headlineText={formatMessage({
              defaultMessage: 'Name',
              description: "subheader for client's name",
            })}
            testID={tID('ClientProfile-name')}
            text={client.full_name}
          />
        )}
        {client.phone && (
          <DataEntry
            headlineText={formatMessage({
              defaultMessage: 'Phone',
              description: 'subheader for client phone number',
            })}
            testID={tID('ClientProfile-phone')}
            text={phoneNumberInE164Format}
          />
        )}
        <DataEntry
          headlineText={formatMessage({
            defaultMessage: 'Gender',
            description: 'subheader for client gender',
          })}
          testID={tID('ClientProfile-gender')}
          text={capitalize(client.gender)}
        />
        <DataEntry
          headlineText={formatMessage({
            defaultMessage: 'Date of birth',
            description: 'subheader for client birth date',
          })}
          testID={tID('ClientProfile-dateOfBirth')}
          text={moment(client.date_of_birth).format('MMMM Do, YYYY')}
        />
        {client.country !== 'US' && client.country_emergency_phone_numbers && (
          <DataEntry
            headlineText={formatMessage({
              defaultMessage: 'Emergency number(s)',
              description: 'subheader for client emergency number',
            })}
            testID={tID('ClientProfile-emergencyNumbers')}
            text={
              client.country_emergency_phone_numbers.map((number) => (
                <BodyText
                  key={number}
                  testID={tID(`ClientProfile-emergencyNumber-${number}`)}
                  text={convertToInternationalFormat(number, [client.country as string])}
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              )) as any
            }
          />
        )}
        <DataEntryContainer>
          <Headline
            text={formatMessage({
              defaultMessage: 'Lyra ID',
              description: 'subheader for client Lyra ID',
            })}
            size={HeadlineSize.EYEBROW}
            color={colors.ui_oatmeal6}
          />
          <DataEntryValueContainer>
            <BodyText
              testID={tID('ClientProfile-lyraId')}
              text={client.id}
              size={BodyTextSize.DEFAULT}
              color={colors.ui_oatmeal5}
            />
            <CopyToClipboardContainer>
              <CopyToClipboard copyText={client.id} />
            </CopyToClipboardContainer>
          </DataEntryValueContainer>
        </DataEntryContainer>
      </SectionContainer>
    )
  }

  const EpisodeActionsSection = () => {
    return (
      <SectionContainer>
        <SectionHeader>
          <Subhead
            size={SubheadSize.SMALL}
            text={formatMessage({
              defaultMessage: 'Current Episode',
              description:
                'header for a sidebar on the client profile that says this information is about the active episode occuring right now',
            })}
            color={colors.ui_oatmeal6}
          />
        </SectionHeader>

        {shouldShowDirectProgramLinkInClientProfile && !showNewProgramBookingLinks && client.country === 'US' && (
          <DataEntryContainer>
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Program Booking Links',
                description: 'subheader for link to book with a certain program',
              })}
              testID={tID('ClientProfile-share-direct-program-link')}
              text={`Share link with ${
                shouldShowPreferredName ? client.preferred_first_name ?? client.first_name : client.first_name
              } ${
                shouldShowPreferredName
                  ? client.preferred_last_name?.substring(0, 1) ?? client.last_name.substring(0, 1)
                  : client.last_name.substring(0, 1)
              } to help them book with another provider.`}
            />
            <CopyProfileLinkContainer testID={tID('ClientProfile-copy-direct-program-link-container')}>
              <CopyToClipboard
                displayText='Lyra Care Therapy Link'
                testID={tID('ClientProfile-CopyToClipboard-direct-program-link')}
                copyText={`${BRANCH_APP_LINK}directPathBooking?directPath=true&clientele=Individual&treatment=Therapy&partner=BlendedCare&offering=Default&directLinkSource=provider_message&$fallback_url=${LWUrl(
                  client.employer,
                )}/secure/onboard/match-location`}
              />
            </CopyProfileLinkContainer>
          </DataEntryContainer>
        )}

        {isTherapyOrAUDProvider && (
          <DataEntryContainer>
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Share your provider profile',
                description: 'subheader for provider profile',
              })}
              testID={tID('ClientProfile-share-your-provider-profile')}
              text={`This link will send ${
                shouldShowPreferredName ? client.preferred_first_name ?? client.first_name : client.first_name
              } ${
                shouldShowPreferredName
                  ? client.preferred_last_name?.substring(0, 1) ?? client.last_name.substring(0, 1)
                  : client.last_name.substring(0, 1)
              } to your provider profile`}
            />
            <CopyProfileLinkContainer testID={tID('ClientProfile-copyToClipboard-container')}>
              <CopyToClipboard
                displayText='Copy profile link'
                copyText={providerInitialDirectBookingLink({
                  employer: client.employer,
                  providerId: userId,
                })}
              />
            </CopyProfileLinkContainer>
          </DataEntryContainer>
        )}

        {shouldDisplayParentIntakeLink && (
          <DataEntryContainer>
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Parent Intake',
                description: 'subheader for Parent Intake form link to display',
              })}
              testID={tID('ClientProfile-parent-intake')}
              text={formatMessage({
                defaultMessage:
                  'This unique link is for the parent intake form. This link is for this specific episode and should not be shared with other clients.',
                description: 'Provider needs to close unclosed sessions before ending episode',
              })}
            />
            <CopyProfileLinkContainer testID={tID('ClientProfile-copyToClipboard-parent-intake')}>
              <CopyToClipboard
                displayText='Copy parent intake link'
                copyText={parentIntakeQualtricsUrl(selectedEpisode.external_ids?.qualtrics as any)}
              />
            </CopyProfileLinkContainer>
          </DataEntryContainer>
        )}

        <DataEntryContainer>
          <DataEntry
            headlineText={
              !showNewProgramBookingLinks
                ? formatMessage({
                    defaultMessage: 'End episode',
                    description: 'subheader for closing a client episode',
                  })
                : ''
            }
            testID={tID('ClientProfile-episodeActionsInfo')}
            text={
              isEmpty(appointmentToClose)
                ? formatMessage({
                    defaultMessage: "We'll ask some more questions about your experience with the client.",
                    description: 'Provider needs to answer questions when they start the process to end the episode',
                  })
                : formatMessage({
                    defaultMessage: 'You still have unclosed sessions. Please close them before ending the episode.',
                    description: 'Provider needs to close unclosed sessions before ending episode',
                  })
            }
          />
        </DataEntryContainer>
        {selectedEpisode?.state === IN_PROGRESS && (
          <>
            {currentEpisodeUpcomingAppointments?.length > 0 && (
              <DataEntryContainer>
                <BodyText
                  testID={tID('ClientProfile-closeUpcomingSession')}
                  text={formatMessage({
                    defaultMessage:
                      'If you have upcoming sessions when you close the episode, cancel them to avoid seeing unfinished notes',
                    description: 'reminder to provider to close upcoming sessions before closing episode',
                  })}
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              </DataEntryContainer>
            )}
            <DataEntryContainer>
              <PrimaryButton
                onPress={() => setModalData(updateEpisodeModalContent)}
                text={formatMessage({
                  defaultMessage: 'End Episode',
                  description: 'button on client profile to end episode',
                })}
                testID={tID('ClientProfile-endEpisodeButton')}
                disabled={!fetchedAppointments || !isEmpty(appointmentToClose)}
                fullWidth
                loading={!fetchedAppointments}
              />
            </DataEntryContainer>
          </>
        )}
      </SectionContainer>
    )
  }

  const PreviousEpisodeInformationSection = () => {
    return (
      <SectionContainer>
        <SectionHeader>
          <Subhead
            size={SubheadSize.SMALL}
            text={formatMessage({
              defaultMessage: 'Previous Episode Information',
              description: 'header for previous episode information on client profile',
            })}
            color={colors.ui_oatmeal6}
          />
        </SectionHeader>
        <DataEntry
          headlineText={formatMessage({
            defaultMessage: 'Termination Status',
            description: 'subheader for episode termination status',
          })}
          testID={tID('ClientProfile-episodeTerminationStatus')}
          text={episodeTerminationStatuses.find((status) => status.value === selectedEpisode?.state)?.text}
        />
        {selectedEpisode?.end_episode_note && (
          <DataEntry
            headlineText={formatMessage({
              defaultMessage: 'Termination Comment',
              description: 'subheader for episode termination comment',
            })}
            testID={tID('ClientProfile-endEpisodeNote')}
            text={selectedEpisode?.end_episode_note}
          />
        )}
      </SectionContainer>
    )
  }

  const BenefitsSection = () => {
    return (
      <SectionContainer>
        {eligibilityError && (
          <EligibilityErrorBannerContainer>
            <EligibilityErrorBanner />
          </EligibilityErrorBannerContainer>
        )}
        <SectionHeader>
          <Subhead
            size={SubheadSize.SMALL}
            text={formatMessage({
              defaultMessage: 'Benefits',
              description: 'header for benefits on client profile',
            })}
            color={colors.ui_oatmeal6}
          />
        </SectionHeader>
        <CompanyInfoContainer>
          <CompanySessionContainer>
            <DataEntryContainer>
              <Headline
                text={formatMessage({
                  defaultMessage: 'Sponsoring company',
                  description: 'subheader for sponsoring company name',
                })}
                size={HeadlineSize.EYEBROW}
                color={colors.ui_oatmeal6}
              />
              <DataEntryValueContainer>
                <BodyText
                  testID={tID('ClientProfile-sponsoringCompany')}
                  text={client.displayed_employer}
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              </DataEntryValueContainer>
            </DataEntryContainer>

            <DataEntryContainer testID={tID('ClientProfile-sessionCount')}>
              <Headline
                text={formatMessage({
                  defaultMessage: 'Sessions',
                  description: 'subheader for used and available sessions',
                })}
                size={HeadlineSize.EYEBROW}
                color={colors.ui_oatmeal6}
              />
              {currentProgramCoveredByEAP && (
                <BodyText
                  testID={tID('ClientProfile-eapSessionCount')}
                  text={
                    clientCustomer?.display_visits_per_issue_per_year ? (
                      <FormattedMessage
                        defaultMessage='{displayVisitsPerIssuePerYear} visits per issue per year. Issues determined between you and your client.'
                        description='how many visits per issue per year that a client receives'
                        values={{
                          displayVisitsPerIssuePerYear: clientCustomer.display_visits_per_issue_per_year,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage='{sessionsUsed} of {sessionLimit} EAP sessions used'
                        description='how many of the EAP sessions have been used so far'
                        values={{
                          sessionsUsed: client.sessions_used,
                          sessionLimit: client.sessions_limit,
                        }}
                      />
                    )
                  }
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              )}
              {currentProgramCoveredByBHB && (
                <BodyText
                  testID={tID('ClientProfile-bhbSessionCount')}
                  text={
                    <FormattedMessage
                      defaultMessage='{openBHBSessionCount} HPI {singleBHBSession, select, true {session} other {sessions}} completed'
                      description='number of HPI sessions that have been used so far'
                      values={{
                        openBHBSessionCount: client?.open_bhb_session_count ?? 0,
                        singleBHBSession: client?.open_bhb_session_count && client?.open_bhb_session_count === 1,
                      }}
                    />
                  }
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              )}
              {currentProgramCoveredBySpecialtyBHB && (
                <BodyText
                  testID={tID('ClientProfile-scSessionCount')}
                  text={
                    <FormattedMessage
                      defaultMessage='{specialtyCareSessionCount} high-risk sessions'
                      description='number of specialty care sessions that have been used so far'
                      values={{ specialtyCareSessionCount: client.speciality_care_session_count }}
                    />
                  }
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              )}
              {!currentProgramCoveredByEAP && !currentProgramCoveredByBHB && !currentProgramCoveredBySpecialtyBHB && (
                <BodyText
                  testID={tID('ClientProfile-noCoverage')}
                  text={
                    <FormattedMessage defaultMessage='No coverage' description='no session coverage by any programs' />
                  }
                  size={BodyTextSize.DEFAULT}
                  color={colors.ui_oatmeal5}
                />
              )}
            </DataEntryContainer>
          </CompanySessionContainer>
          <ResetDateContainer>
            <DataEntry
              headlineText={formatMessage({
                defaultMessage: 'Coverage Information',
                description: 'subheader for sponsoring company coverage information',
              })}
              testID={tID('ClientProfile-coverageInformationLimit')}
              text={sessionResetDate({ client })}
            />
          </ResetDateContainer>
        </CompanyInfoContainer>
        {client.health_plan_status && client.country === 'US' && (
          <DataEntry
            headlineText={formatMessage({
              defaultMessage: 'Health Plan',
              description: 'subheader for health plan coverage information',
            })}
            testID={tID('ClientProfile-healthPlanCoverage')}
            text={renderHealthPlanStatus(client.health_plan_status)}
          />
        )}
        {validProgramCoverage && (
          <BenefitsEligibilityContainer>
            <SectionHeader>
              <Subhead
                size={SubheadSize.SMALL}
                text={formatMessage({
                  defaultMessage: 'Benefits Eligibility',
                  description: 'subheader for benefits eligibility information',
                })}
                color={colors.ui_oatmeal6}
              />
            </SectionHeader>
            <DataEntryContainer>
              <BenefitsEligibility
                programCoverageName={programConfig.program}
                currentProgramCoverageBreakdown={currentProgramCoverageBreakdown}
              />
            </DataEntryContainer>
          </BenefitsEligibilityContainer>
        )}
        {!isEmpty(intakeResponse) && (
          <ViewIntakeButtonContainer>
            <SecondaryButton
              testID={tID('ClientProfile-intakeButton')}
              onPress={() => setModalData(intakeModalContent)}
              text={
                <DataEntryValueContainer>
                  <NoteIconContainer>
                    <NoteIcon fillColor={colors.teal5} />
                  </NoteIconContainer>
                  <BodyText
                    testID={tID('ClientProfile-intakeButtonText')}
                    text={formatMessage({
                      defaultMessage: 'View intake form',
                      description: 'Button on client profile to view intake',
                    })}
                    size={BodyTextSize.DEFAULT}
                    color={colors.teal5}
                  />
                </DataEntryValueContainer>
              }
            />
          </ViewIntakeButtonContainer>
        )}
      </SectionContainer>
    )
  }

  return (
    <Container>
      <HeaderContainer>
        <Subhead
          size={SubheadSize.LARGE}
          text={formatMessage({
            defaultMessage: 'Profile',
            description: 'header for client profile page',
          })}
          color={colors.ui_oatmeal6}
        />
      </HeaderContainer>

      <ColumnsSection>
        <InfoActionsColumn>
          <PersonalSection />
          {selectedEpisode?.state === IN_PROGRESS && config.bcPlatform && <EpisodeActionsSection />}
          {selectedEpisode?.state !== IN_PROGRESS && <PreviousEpisodeInformationSection />}
        </InfoActionsColumn>
        <BenefitsColumn>
          <BenefitsSection />
        </BenefitsColumn>
      </ColumnsSection>
      <Modal
        visible={!!modalData}
        modalContents={modalData || ''}
        onRequestClose={() => setModalData(null)}
        onCloseEnd={() => setModalData(null)}
        scrollable
        isHeaderTransparent
        scrollableModalHeight='auto'
      />
    </Container>
  )
}

type ClientProfileProps = ConnectedProps<typeof connector>

const mapStateToProps = (state: Map<string, any>) => {
  const programConfig = getClientEpisodeProgramConfig(state)
  const selectedEpisode = getClientSelectedEpisode(state)
  return {
    clientDetails: getClientDetailsData(state),
    selectedEpisode,
    programConfig,
    intakeForm: getClientAssignmentsData(state).find(
      (assignment: Assignment) =>
        [programConfig?.clientIntake, programConfig?.oldClientIntake].includes(assignment.content.name) &&
        assignment.episode_id === selectedEpisode?.id,
    ),
    currentSessionCount: getClientCurrentSessionCount(state),
    customers: getRequestPaymentClientCustomers(state),
    episodes: getClientEpisodesData(state),
    appointments: getClientAppointmentsData(state),
    pastAppointmentsToClose: getClientUnclosedPastAppointments(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      updateEpisode,
      addAlert,
      checkEligibility,
      getLTAppointmentsForPatient,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ClientProfile)
