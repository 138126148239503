import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number
}

export const RenewIllustration: FunctionComponent<Props> = ({ size = 80 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 81 80' fill='none'>
      <Path
        d='M40.2812 0.29921C34.7592 0.29921 29.4015 1.24616 24.3569 3.11319C12.3469 7.56497 3.9034 17.4344 1.1906 30.1906C-0.602123 38.5077 -0.372894 46.2003 1.89568 53.6968C4.79186 63.2707 11.9011 71.3443 21.4007 75.8466C26.1164 78.0868 31.5341 79.3657 37.5004 79.6471C38.2671 79.6819 39.0465 79.7009 39.8212 79.7009C44.6081 79.7009 49.4235 79.0448 54.1345 77.7516C54.4965 77.6615 54.8412 77.5682 55.2048 77.4607C59.4384 76.2213 63.2483 74.261 66.5081 71.6415C70.0477 68.8133 72.9613 65.2278 75.1587 60.991C75.5919 60.1563 76.0061 59.2774 76.463 58.2261C78.9766 52.3894 80.1907 45.8556 79.9757 39.3234C79.8745 36.4351 79.4445 33.365 78.7015 30.1906C77.4779 25.0401 75.4828 20.1963 72.9313 16.1745C72.2325 15.0947 71.5907 14.1873 70.9551 13.3716C66.369 7.45589 59.2234 3.30448 50.2977 1.37263C47.0189 0.661233 43.6485 0.30079 40.2828 0.30079L40.2812 0.29921Z'
        fill='white'
      />
      <Path
        d='M76.5437 39.4309C76.4457 36.6627 76.022 33.795 75.3549 30.9447C74.2641 26.3522 72.4713 21.8245 70.0131 17.9482C69.4424 17.0692 68.8574 16.2219 68.2219 15.4077C63.6121 9.46359 56.6261 6.15637 49.4758 4.60868C41.5113 2.88235 33.0424 3.38666 25.3862 6.22119C14.3105 10.3252 6.76968 19.3157 4.30982 30.8799C3.36445 35.2605 3.0878 40.2118 3.51148 44.9687H3.47828C3.72332 47.672 4.21181 50.3105 4.94376 52.7372C7.66447 61.7277 14.2757 68.8623 22.6971 72.8524C27.258 75.0182 32.3548 76.0932 37.5353 76.3383C42.8281 76.5833 48.2031 75.9146 53.1719 74.5471C53.4975 74.4665 53.8074 74.3843 54.1331 74.2863C58.0252 73.1465 61.413 71.3869 64.3124 69.0583C67.4726 66.5336 70.0779 63.3576 72.0983 59.4655C72.5377 58.6181 72.9282 57.7723 73.3029 56.9076C75.6805 51.3856 76.7397 45.3924 76.5279 39.4483L76.5437 39.4325V39.4309Z'
        fill='#F5F2EE'
      />
      <Path
        d='M59.8898 49.3534C62.8413 42.701 62.6421 32.9327 58.3738 26.8874C55.4112 22.6917 49.5935 19.4509 44.5046 18.9071C39.1897 18.3395 33.5317 18.7711 28.8649 21.4823C24.7641 23.8647 21.2972 27.4202 19.4033 31.8024C18.1797 34.6337 17.6327 37.7513 17.7876 40.8324C18.0959 46.9884 20.9494 53.2993 26.1869 56.6492C32.5152 60.6963 41.0725 62.391 48.2545 59.7572C52.6857 58.1321 57.7793 54.1087 59.8882 49.3534H59.8898Z'
        fill='#FFA338'
      />
      <Path
        d='M50.8435 27.7506C55.2067 31.2555 57.5907 36.5546 56.2169 42.7643C55.5245 45.8913 53.8725 48.7733 51.8173 51.2031C48.3062 55.3529 44.2101 57.7622 38.6375 57.0034C33.6261 56.322 28.1151 53.4748 25.6204 48.865C23.8182 45.534 24.1249 41.038 24.9043 37.451C25.524 34.5975 26.6607 31.8546 28.2637 29.4137C29.7576 27.1373 31.8808 25.5738 34.3675 24.4497C37.2826 23.1329 40.6183 23.2593 43.6426 24.113C46.3522 24.8782 48.8121 26.1192 50.8419 27.7506H50.8435Z'
        fill='#FFC03C'
      />
      <Path
        d='M51.7168 41.1928C52.1389 46.6295 48.6974 52.259 42.1272 53.2155C31.2064 54.8043 23.5486 43.5736 31.3882 34.8013C38.1054 27.2858 50.9217 30.9392 51.7168 41.1928Z'
        fill='#FFD974'
      />
      <Path
        d='M39.9643 48.5994C43.4524 48.5994 46.28 45.7718 46.28 42.2838C46.28 38.7957 43.4524 35.9681 39.9643 35.9681C36.4763 35.9681 33.6487 38.7957 33.6487 42.2838C33.6487 45.7718 36.4763 48.5994 39.9643 48.5994Z'
        fill='white'
      />
    </Svg>
  )
}
