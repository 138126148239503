import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'

import {
  DateSelectArg,
  DateSpanApi,
  DayHeaderContentArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridWeek from '@fullcalendar/timegrid'
import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  parseISO,
  startOfWeek,
  subMilliseconds,
  subMinutes,
} from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import { eventsOverlap, getAdjustedEvent, getTimezoneOffset } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Subhead } from '../../atoms'
import { ProviderCalendarEvent } from '../../molecules'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderBookableCalendarProps = {
  timeZone?: string
  onEventsChanged?: (events: { startTime: string; endTime: string }[]) => void
  disableEditing?: boolean
  initialEvents?: { startTime: string; endTime: string }[]
}

export type ProviderBookableCalendarRef = {
  reset: () => void
}

const DayContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['4px'],
}))

const START_TIME_ALLOWED = 7
const END_TIME_ALLOWED = 21

export const ProviderBookableCalendar = forwardRef<ProviderBookableCalendarRef, ProviderBookableCalendarProps>(
  ({ timeZone = 'America/Los_Angeles', onEventsChanged, disableEditing = false, initialEvents }, ref) => {
    const calendarRef = useRef<FullCalendar>(null)
    const theme = useTheme() as ThemeType
    const startingRange = useRef<{ startStr: string; endStr: string } | null>(null)

    const dayContentRenderer = useCallback(
      ({ date }: DayHeaderContentArg) => {
        const newDate = addMinutes(date, date.getTimezoneOffset())
        return (
          <DayContentContainer>
            <Subhead size={SubheadSize.XSMALL} text={format(newDate, 'eeee', { timeZone })} />
          </DayContentContainer>
        )
      },
      [timeZone],
    )

    const slotLabelContent = useCallback(
      ({ text }: SlotLabelContentArg) => {
        return <BodyText color={theme.colors.textSecondary} size={BodyTextSize.SMALL} text={text} />
      },
      [theme.colors.textSecondary],
    )

    const eventContent = useCallback(
      (args: EventContentArg) => {
        return (
          <ProviderCalendarEvent
            args={args}
            startingRange={startingRange}
            disableEditing={disableEditing}
            showPopover={false}
            isTemplate
          />
        )
      },
      [disableEditing],
    )

    const onSelect = useCallback((event: DateSelectArg) => {
      if (!event.start || !event.end) {
        return
      }
      const adjustedEvent = getAdjustedEvent(event, startingRange.current)
      let startTime = parseISO(adjustedEvent.start)
      const endTime = parseISO(adjustedEvent.end)
      const differenceHours = differenceInMinutes(endTime, startTime) / 60
      const numEvents = Math.ceil(differenceHours)
      for (let i = 0; i < numEvents; i++) {
        calendarRef.current?.getApi().addEvent({
          title: 'Available',
          start: startTime,
          end: addHours(startTime, 1),
          extendedProps: {
            lyraEventType: 'bookable',
          },
        })
        startTime = addHours(startTime, 1)
      }

      calendarRef.current?.getApi().unselect()
    }, [])

    const onSelectAllow = useCallback((args: DateSpanApi) => {
      const startTime = parseISO(args.startStr)
      const endTime = parseISO(args.endStr)
      if (startTime.getHours() < START_TIME_ALLOWED || endTime.getHours() > END_TIME_ALLOWED) {
        return false
      }
      if (startTime.getDate() !== endTime.getDate()) {
        return false
      }

      const adjustedEvent = getAdjustedEvent(args, startingRange.current)
      return !calendarRef.current
        ?.getApi()
        .getEvents()
        .some((event) =>
          eventsOverlap(
            {
              start: parseISO(event.startStr + 'Z'),
              end: parseISO(event.endStr + 'Z'),
            },
            {
              start: parseISO(adjustedEvent.start),
              end: parseISO(adjustedEvent.end),
            },
          ),
        )
    }, [])

    const onEventChange = useCallback(() => {
      onEventsChanged?.(
        calendarRef.current
          ?.getApi()
          .getEvents()
          .map((event) => {
            const tzOffset = getTimezoneOffset(timeZone, event.startStr)
            return {
              startTime: subMilliseconds(parseISO(event.startStr + 'Z'), tzOffset).toISOString(),
              endTime: subMilliseconds(parseISO(event.endStr + 'Z'), tzOffset).toISOString(),
            }
          }) ?? [],
      )
    }, [onEventsChanged, timeZone])

    const convertDateToSameTimeInCurrentWeek = useCallback(
      (date: Date) => {
        const currentTime = new Date()
        const originalInZone = utcToZonedTime(date, timeZone)
        const targetInZone = utcToZonedTime(currentTime, timeZone)

        const startOfOriginalWeek = startOfWeek(originalInZone, { weekStartsOn: 0 })
        const startOfTargetWeek = startOfWeek(targetInZone, { weekStartsOn: 0 })

        const dayDifference = differenceInDays(originalInZone, startOfOriginalWeek)
        const targetDateWithSameDay = addDays(startOfTargetWeek, dayDifference)

        const originalTime = format(originalInZone, 'HH:mm:ss.SSS', {
          timeZone,
        })
        const targetDateWithSameTime = new Date(
          `${format(targetDateWithSameDay, 'yyyy-MM-dd', {
            timeZone,
          })}T${originalTime}`,
        )

        return zonedTimeToUtc(targetDateWithSameTime, timeZone)
      },
      [timeZone],
    )
    const resetEvents = useCallback(() => {
      if (!calendarRef.current || !initialEvents) {
        return
      }
      calendarRef.current?.getApi().removeAllEvents()
      calendarRef.current?.getApi().addEventSource({
        events: initialEvents.map((event) => {
          const startTime = convertDateToSameTimeInCurrentWeek(parseISO(event.startTime))
          const endTime = convertDateToSameTimeInCurrentWeek(parseISO(event.endTime))
          return {
            title: 'Available',
            start: subMinutes(startTime, startTime.getTimezoneOffset()),
            end: subMinutes(endTime, endTime.getTimezoneOffset()),
            extendedProps: {
              lyraEventType: 'bookable',
            },
          }
        }),
      })
      onEventChange()
    }, [convertDateToSameTimeInCurrentWeek, initialEvents, onEventChange])

    useImperativeHandle(
      ref,
      () => ({
        reset: resetEvents,
      }),
      [resetEvents],
    )

    useEffect(() => {
      resetEvents()
    }, [resetEvents])

    const eventAllow = useCallback((span: DateSpanApi) => {
      const startTime = parseISO(span.startStr)
      if (startTime.getHours() < START_TIME_ALLOWED) {
        return false
      }

      if (
        startTime.getHours() > END_TIME_ALLOWED ||
        (startTime.getHours() === END_TIME_ALLOWED && startTime.getMinutes() > 0)
      ) {
        return false
      }

      return true
    }, [])

    return (
      <FullCalendar
        ref={calendarRef}
        headerToolbar={false}
        plugins={[timeGridWeek, interactionPlugin]}
        initialView='timeGridWeek'
        allDaySlot={false}
        dayHeaderContent={dayContentRenderer}
        selectMirror
        slotLabelContent={slotLabelContent}
        eventColor='transparent'
        expandRows={true}
        contentHeight={1280}
        stickyHeaderDates={true}
        selectable={!disableEditing}
        select={onSelect}
        timeZone={timeZone}
        eventContent={eventContent}
        eventOverlap={false}
        eventChange={onEventChange}
        eventRemove={onEventChange}
        eventAdd={onEventChange}
        eventAllow={eventAllow}
        eventStartEditable={!disableEditing}
        eventMouseEnter={(args) => {
          args.event.setExtendedProp('hovered', true)
        }}
        eventMouseLeave={(args) => {
          args.event.setExtendedProp('hovered', false)
        }}
        selectAllow={onSelectAllow}
        slotMinTime='07:00:00'
        slotMaxTime='22:00:00'
      />
    )
  },
)
