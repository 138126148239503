import React from 'react'
import { CellInfo } from 'react-table'

import { format, parseISO } from 'date-fns'

import styles from './worksheet/worksheet.module.scss'
import MessageIcon from '../atoms/icons/MessageIcon'

export const UIMetadata = {
  // Check Ins such coachingCheckIn, bctCheckIn, bccCheckIn, bcmCheckIn
  checkIn: {
    report: {
      customColumns: [
        {
          Header: 'Notes',
          accessor: 'response.notes',
          name: 'notes',
          width: 70,
          Cell: (props: CellInfo) => {
            return <div>{props.value && <MessageIcon fillColor={styles.x_dark_gray} width={20} />}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: CellInfo) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  baseSleepDiary: {
    report: {
      customColumns: [
        {
          Header: 'Meds/substances',
          accessor: 'response.describeMedications',
          name: 'describeMedications',
          width: 150,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time in bed',
          accessor: 'response.timeWhenInBed',
          name: 'timeWhenInBed',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time asleep',
          accessor: 'response.timeWhenFallAsleep',
          name: 'timeWhenFallAsleep',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: '# Awakenings',
          accessor: 'response.wakeUpCount',
          name: 'wakeUpCount',
          width: 120,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time awake',
          accessor: 'response.timeOfWakeUp',
          name: 'timeOfWakeUp',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Time up',
          accessor: 'response.timeWhenOutOfBed',
          name: 'timeWhenOutOfBed',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Sleep quality',
          accessor: 'response.qualityOfSleep',
          name: 'qualityOfSleep',
          width: 120,
          Cell: (props: CellInfo) => {
            const responseValues = ['Very poor', 'Poor', 'Fair', 'Good', 'Very good']
            return <div>{responseValues[props.value]}</div>
          },
        },
        {
          Header: 'Caffeine',
          accessor: 'response.caffeine',
          name: 'caffeine',
          width: 100,
          Cell: (props: CellInfo) => {
            const responseValues = ['Yes', 'No']
            return <div>{responseValues[props.value]}</div>
          },
        },
        {
          Header: '# Naps',
          accessor: 'response.napOrDozeAmount',
          name: 'napOrDozeAmount',
          width: 80,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Nap length',
          accessor: 'response.napOrDozeTime',
          name: 'napOrDozeTime',
          width: 100,
          Cell: (props: CellInfo) => {
            return <div>{props.value}</div>
          },
        },
        {
          Header: 'Sleepiness',
          accessor: 'response.howSleepy',
          name: 'howSleepy',
          width: 150,
          Cell: (props: CellInfo) => {
            const responseValues = ['So sleepy', 'Somewhat tired', 'Fairly tired', 'Alert']
            return <div>{responseValues[props.value]}</div>
          },
        },
      ],
      fixedColumns: [
        {
          Header: 'Date',
          accessor: 'submit_date',
          width: 70,
          Cell: (props: CellInfo) => {
            const displayDate = props.value || props.original.update_date
            return (
              <div className='rt-date-cell'>
                <div>{format(parseISO(displayDate), 'MMM d')}</div>
              </div>
            )
          },
        },
      ],
    },
  },
  baseTherapyTargets: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 10,
          minLabel: '0: Not a problem',
          maxLabel: '10: A very big problem',
          step: 1,
        },
      },
    },
  },
  baseExposureHierarchy: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  baseYouthTopProblems: {
    customFields: {
      fields: [
        { type: 'select', placeholder: 'Select rank', enums: [1, 2, 3], enumNames: ['1', '2', '3'] },
        {
          type: 'range',
          minValue: 0,
          maxValue: 10,
          minLabel: 'Not a problem',
          maxLabel: 'Very big problem',
          noTitle: true,
          step: 1,
        },
      ],
      maxNumberOfFieldGroups: 3,
    },
  },
}

export default UIMetadata
