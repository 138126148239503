import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number
}

export const ControlledMedsIllustration: FunctionComponent<Props> = ({ size = 80 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 80 81' fill='none'>
      <Path
        d='M40.2812 1.11902C34.7592 1.11902 29.4015 2.06597 24.3569 3.933C12.3469 8.38478 3.9034 18.2543 1.1906 31.0104C-0.602123 39.3275 -0.372894 47.0201 1.89568 54.5166C4.79186 64.0905 11.9011 72.1641 21.4007 76.6665C26.1164 78.9066 31.5341 80.1855 37.5004 80.4669C38.2671 80.5017 39.0465 80.5207 39.8212 80.5207C44.6081 80.5207 49.4235 79.8646 54.1345 78.5714C54.4965 78.4813 54.8412 78.388 55.2048 78.2805C59.4384 77.0411 63.2483 75.0808 66.5081 72.4613C70.0477 69.6331 72.9613 66.0476 75.1587 61.8109C75.5919 60.9762 76.0061 60.0972 76.463 59.0459C78.9766 53.2093 80.1907 46.6754 79.9757 40.1432C79.8745 37.2549 79.4445 34.1849 78.7015 31.0104C77.4779 25.8599 75.4828 21.0161 72.9313 16.9943C72.2325 15.9145 71.5907 15.0071 70.9551 14.1914C66.369 8.2757 59.2234 4.12428 50.2977 2.19244C47.0189 1.48104 43.6485 1.1206 40.2828 1.1206L40.2812 1.11902Z'
        fill='white'
      />
      <Path
        d='M76.5437 40.2507C76.4457 37.4825 76.022 34.6148 75.3549 31.7645C74.2641 27.172 72.4713 22.6443 70.0131 18.768C69.4424 17.889 68.8574 17.0417 68.2219 16.2275C63.6121 10.2834 56.6261 6.97615 49.4758 5.42846C41.5113 3.70213 33.0424 4.20644 25.3862 7.04097C14.3105 11.1449 6.76968 20.1355 4.30982 31.6996C3.36445 36.0803 3.0878 41.0316 3.51148 45.7885H3.47828C3.72332 48.4918 4.21181 51.1303 4.94376 53.557C7.66447 62.5475 14.2757 69.682 22.6971 73.6722C27.258 75.838 32.3548 76.913 37.5353 77.1581C42.8281 77.4031 48.2031 76.7344 53.1719 75.3669C53.4975 75.2863 53.8074 75.2041 54.1331 75.1061C58.0252 73.9662 61.413 72.2067 64.3124 69.8781C67.4726 67.3534 70.0779 64.1774 72.0983 60.2852C72.5377 59.4379 72.9282 58.5921 73.3029 57.7274C75.6805 52.2053 76.7397 46.2122 76.5279 40.268L76.5437 40.2522V40.2507Z'
        fill='#F5F2EE'
      />
      <Path
        d='M49.9843 62.7063H29.4423C28.0369 62.7063 26.8971 61.5664 26.8971 60.161L25.9312 27.2343L53.9223 28.9227L52.528 60.2148C52.498 61.5981 51.3676 62.7047 49.9843 62.7047V62.7063Z'
        fill='#FFC03C'
      />
      <Path
        d='M34.6094 49.9946C33.1847 48.8076 31.0674 49.0003 29.8803 50.425C28.6933 51.8498 28.8859 53.9671 30.3107 55.1541L35.5674 59.5338C36.9921 60.7209 39.1094 60.5282 40.2965 59.1034C41.4835 57.6787 41.2908 55.5614 39.8661 54.3743L34.6094 49.9946Z'
        fill='#00A4B3'
      />
      <Path
        d='M40.2963 59.1018C39.109 60.5262 36.9922 60.7191 35.5663 59.5318L33.0605 57.4609L37.359 52.3008L39.8647 54.3718C41.2891 55.559 41.4819 57.6759 40.2947 59.1018H40.2963Z'
        fill='#008295'
      />
      <Path
        d='M44.1262 49.3966L42.544 56.0533C42.1152 57.8575 43.2302 59.6677 45.0344 60.0965C46.8386 60.5253 48.6488 59.4104 49.0776 57.6062L50.6598 50.9495C51.0886 49.1453 49.9736 47.3351 48.1694 46.9063C46.3652 46.4775 44.555 47.5924 44.1262 49.3966Z'
        fill='#00A4B3'
      />
      <Path
        d='M48.1724 46.9098C49.9762 47.3383 51.0923 49.1499 50.6623 50.9537L49.9225 54.1187L43.3887 52.5647L44.1285 49.3997C44.5569 47.5959 46.3686 46.4798 48.1724 46.9098Z'
        fill='#008295'
      />
      <Mask id='mask0_2693_23711' maskUnits='userSpaceOnUse' x='25' y='27' width='29' height='36'>
        <Path
          d='M49.9841 62.7063H29.4421C28.0366 62.7063 26.8968 61.5665 26.8968 60.1611L25.9309 27.2344L53.9221 28.9228L52.5278 60.2148C52.4977 61.5981 51.3674 62.7047 49.9841 62.7047V62.7063Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_2693_23711)'>
        <Path
          d='M53.2311 47.7842L33.5823 47.5724C32.7381 47.5582 32.0583 46.8752 32.0488 46.031L32.0646 35.096C32.0552 34.2281 32.7555 33.5183 33.6234 33.5183L54.2887 33.6416L53.2311 47.7842Z'
          fill='white'
        />
        <Path d='M53.585 36.9186H36.4387V38.7508H53.585V36.9186Z' fill='#DCCDBF' />
        <Path d='M53.585 40.9469H36.4387V42.6211H53.585V40.9469Z' fill='#DCCDBF' />
      </G>
      <Path
        d='M54.2918 24.7727L25.7126 24.5861C25.2478 24.583 24.862 24.9418 24.8304 25.405L24.5459 29.6766C24.5127 30.1825 24.9142 30.6125 25.4217 30.6125H54.4547C54.9542 30.6125 55.3526 30.1967 55.3321 29.6987L55.1629 25.6137C55.1439 25.1458 54.7598 24.7758 54.2918 24.7727Z'
        fill='#6ECBD3'
      />
      <Path
        d='M52.2963 21.4782L28.1277 20.6071C27.3688 20.5802 26.7033 21.0608 26.5689 21.7358L25.4639 27.28H54.3878L53.7459 22.6781C53.6527 22.0125 53.0393 21.505 52.2963 21.4782Z'
        fill='#6ECBD3'
      />
    </Svg>
  )
}
