import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { CALENDAR } from '../common/constants/routingConstants'
import { getAuthConfig } from '../data/auth/authSelectors'
import { Config } from '../lyraTherapy/types'
import SmartScheduling from '../scheduling/SmartScheduling'

export const SchedulingRoutes: React.FC = () => {
  const config: Config = useSelector(getAuthConfig)
  const navigate = useNavigate()
  //Individual and Practice Providers, Excluding ICAS and BC Providers
  if (config.smartSchedulingEnabled || config.internationalSmartSchedulingEnabled) {
    return <SmartScheduling />
  } else {
    //if we reach this endpoint erroneously, redirect to the /python/calendar route
    navigate(CALENDAR.route)
    return <></>
  }
}

export default SchedulingRoutes
