import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { AlertIconStroke, Subhead } from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSlotTargetProps = {
  target: number
  current: number
  max: number
  showWarningBelowTarget?: boolean
}

enum State {
  NONE,
  WARNING,
}

const Container = styled.View<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
  padding: theme.spacing['4px'],
  backgroundColor,
}))

const OfText = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

export const ProviderBookableCalendarSlotTarget: FunctionComponent<ProviderBookableCalendarSlotTargetProps> = ({
  target,
  current,
  max,
  showWarningBelowTarget = false,
}) => {
  const theme = useTheme() as ThemeType
  const state = useMemo(() => {
    if (current > max) {
      return State.WARNING
    }

    if (showWarningBelowTarget && current < target) {
      return State.WARNING
    }

    return State.NONE
  }, [current, max, showWarningBelowTarget, target])

  const { icon, textColor, backgroundColor } = useMemo(() => {
    switch (state) {
      case State.WARNING:
        return {
          icon: <AlertIconStroke fillColor={theme.colors.iconWarning} size={24} />,
          textColor: theme.colors.textWarning,
          backgroundColor: theme.colors.backgroundWarning,
        }
      case State.NONE:
        return {
          icon: null,
          textColor: theme.colors.textPrimary,
          backgroundColor: theme.colors.backgroundSection,
        }
    }
  }, [state, theme.colors])
  return (
    <Container testID={tID('ProviderBookableCalendarSlotTarget')} backgroundColor={backgroundColor}>
      {icon}
      <Subhead
        size={SubheadSize.LARGE}
        text={
          <FormattedMessage
            defaultMessage='{availableSpots} '
            description='Text for available spots'
            values={{
              availableSpots: current,
            }}
          />
        }
        color={textColor}
      />
      <OfText
        size={SubheadSize.SMALL}
        color={textColor}
        text={<FormattedMessage defaultMessage='of' description='Text for of' />}
      />
      <Subhead
        size={SubheadSize.LARGE}
        text={
          <FormattedMessage
            defaultMessage=' {targetSpots}'
            description='Text for available spots'
            values={{
              targetSpots: target,
            }}
          />
        }
        color={textColor}
      />
    </Container>
  )
}
