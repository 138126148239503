import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAuthConfig, getAuthUserId, getAuthUserZendeskJWT } from './auth/authSelectors'
import { logToSumoLogic } from '../common/utils/utils'
import { Config } from '../lyraTherapy/types'

const ZendeskWidget = () => {
  const config: Config = useSelector(getAuthConfig)
  const userId: string = useSelector(getAuthUserId)
  const zendeskJwt: string = useSelector(getAuthUserZendeskJWT)
  useEffect(() => {
    try {
      // Snippet copied from Zendesk dashboard
      /* eslint-disable */
      ;(window as any).zE ||
      (function(e, t, s) {
        var n =
          ((window as any).zE =
            (window as any).zEmbed =
              function() {
                ;(n as any)._.push(arguments)
              })
        var a = ((n as any).s = e.createElement(t))
        var r = e.getElementsByTagName(t)[0]
        ;((n as any).set = function(e: any) {
          ;(n as any).set._.push(e)
        }),
          ((n as any)._ = []),
          ((n as any).set._ = []),
          ((a as any).async = true),
          a.setAttribute('charset', 'utf-8'),
          ((a as any).src = 'https://static.zdassets.com/ekr/snippet.js?key=' + s),
          ((n as any).t = +new Date()),
          ((a as any).type = 'text/javascript'),
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          r.parentNode.insertBefore(a, r)
      })(document, 'script', '7658de4a-82dc-4963-9122-4d9a0526767f')
    } catch (error) {
        logToSumoLogic("providerPortalErrors", userId, {
          message: `Error loading Zendesk widget: ${error}`,
          type: 'error'
        })
      }
  }, [])

  useEffect(() => {
    try {
      // Config
      if (zendeskJwt) {
        ;(window as any).zESettings = { authenticate: { jwt: zendeskJwt } }
      }
    } catch (error) {
      logToSumoLogic("providerPortalErrors", userId, {
        message: `Error in Zendesk widget config: ${error}`,
        type: 'error'
      })
    }
  }, [zendeskJwt])

  // Hide the widget for BC Providers and internal Admins
  useEffect(() => {
    try {
      if (config.zendeskWidget === false) {
        ;(window as any).zE('webWidget', 'hide')
      }
  } catch (error) {
      logToSumoLogic("providerPortalErrors", userId, {
        message: `Error hiding Zendesk widget: ${error}`,
        type: 'error'
      })
    }
  }, [config])

  return null
}

export default ZendeskWidget
