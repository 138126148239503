import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ZOOM_EVENTS, ZOOM_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'

import ZoomVideo, { ConnectionChangePayload, ConnectionState, Stream } from './ZoomVideo'
import { AppContext } from '../../context'

export const useZoomStream: () => typeof Stream | null = () => {
  const client = useMemo(() => ZoomVideo.createClient(), [])
  const [stream, setStream] = useState<typeof Stream | null>(null)
  const { trackEvent } = useContext(AppContext)

  const onConnectionChange = useCallback(
    (payload: ConnectionChangePayload) => {
      switch (payload.state) {
        case ConnectionState.Connected:
          const stream = client.getMediaStream()
          setStream(stream)
          trackEvent?.(ZOOM_EVENTS.CONNECTION_CONNECTED, {
            [ZOOM_PROPERTIES.CONNECTION_CHANGE_REASON]: payload.reason,
          })
          break
        case ConnectionState.Closed:
          trackEvent?.(ZOOM_EVENTS.CONNECTION_CLOSED, {
            [ZOOM_PROPERTIES.CONNECTION_CHANGE_REASON]: payload.reason,
          })
      }
    },
    [client, trackEvent],
  )

  useEffect(() => {
    if (client.getCurrentUserInfo()?.userId) {
      setStream(client.getMediaStream())
    }
    client.on('connection-change', onConnectionChange)
    return () => client.off('connection-change', onConnectionChange)
  }, [client, onConnectionChange])

  return stream
}
