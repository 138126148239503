import React, { FunctionComponent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Popover } from 'react-native-popper'

import { EventContentArg, formatDate, formatRange } from '@fullcalendar/core'
import { addMinutes, differenceInMinutes, parseISO, subMilliseconds } from 'date-fns'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import {
  EventExtendedProps,
  getAdjustedEvent,
  getProgramNameFromId,
  getProgramNameLabel,
  getTimezoneOffset,
  isBookableEvent,
  isOOOEvent,
  OffsetDirection,
} from '@lyrahealth-inc/shared-app-logic'

import {
  AlertIconStroke,
  BodyText,
  CheckMarkInCircleIcon,
  EventIcon,
  PressableOpacity,
  Subhead,
  TrashIcon,
} from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarPopover } from '../providerCalendarPopover/ProviderCalendarPopover'

export type ProviderCalendarEventProps = {
  args: EventContentArg
  startingRange: MutableRefObject<{ startStr: string; endStr: string } | null> | null
  timeZone?: string
  disableEditing?: boolean
  onClientProfilePressed?: (clientId: string) => void
  showPopover?: boolean
  onOpenInGoogleCalendar?: (externalId: string, calendarConfigurationId: string) => void
  onOpenZoom?: (externalId: string, calendarConfigurationId: string) => void
  isTemplate?: boolean
}

type EventStyles = {
  container: {
    backgroundColor: string
    borderColor: string
  }
  textColor?: string
}

const EventContainer = styled(PressableOpacity)<{
  offset?: OffsetDirection
  hasConflict?: boolean
  styles?: CSSObject
}>(({ theme, offset = OffsetDirection.NONE, hasConflict = false, styles }) => ({
  flexDirection: 'column',
  height:
    offset !== OffsetDirection.NONE
      ? `calc(100% + ${offset === OffsetDirection.BEFORE_AND_AFTER ? '82px' : '41px'})`
      : '100%',
  marginTop: offset === OffsetDirection.BEFORE_AND_AFTER ? '-41px' : '0px',
  borderWidth: '1px',
  padding: theme.spacing['4px'],
  borderRadius: '4px',
  gap: theme.spacing['4px'],
  width: '100%',
  ...styles,
  ...(hasConflict && {
    borderLeftWidth: '2px',
    borderLeftColor: theme.colors.borderWarning,
  }),
}))

const EventRow = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const SelectedAmountBadge = styled(Subhead)(({ theme }) => ({
  position: 'absolute',
  flexDirection: 'row',
  bottom: -11,
  right: 11,
  backgroundColor: theme.colors.backgroundHover,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '22px',
  width: '22px',
  borderRadius: '11px',
  color: theme.colors.textInverse,
}))

const TrashIconContainer = styled(PressableOpacity)(({ theme }) => ({
  position: 'absolute',
  top: -10,
  right: -10,
  backgroundColor: theme.colors.backgroundPrimary,
  height: '20px',
  width: '20px',
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.colors.borderDefault}`,
}))

const RightIconContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['4px'],
}))

export const ProviderCalendarEvent: FunctionComponent<ProviderCalendarEventProps> = ({
  args,
  startingRange,
  timeZone = 'America/Los_Angeles',
  disableEditing = false,
  showPopover = true,
  onClientProfilePressed,
  onOpenInGoogleCalendar,
  onOpenZoom,
  isTemplate = false,
}) => {
  const theme = useTheme() as ThemeType
  const pressableRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isOverlapped, setIsOverlapped] = useState(false)

  const eventProperties = args.event.extendedProps as EventExtendedProps
  const eventTitle = useMemo(() => {
    if (eventProperties.lyraEventType === 'bookable' && !isTemplate) {
      return args.event.title + ' (one-time)'
    }
    if (
      eventProperties.lyraEventType !== 'session' ||
      !eventProperties.clientFirstName ||
      !eventProperties.clientLastName ||
      eventProperties.appointmentSessionNumber == null ||
      !eventProperties.lyraProgramId ||
      !eventProperties.appointmentClass
    ) {
      return args.event.title
    }

    const programName = getProgramNameFromId(eventProperties.lyraProgramId)
    const programNameLabel = programName ? getProgramNameLabel(programName) : ''
    if (eventProperties.isNoShow) {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastInitial}. • No Show • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: eventProperties.clientFirstName,
            lastInitial: eventProperties.clientLastName.charAt(0),
            programName: programNameLabel,
          }}
        />
      )
    }

    if (eventProperties.appointmentClass === 'initial') {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastInitial}. • Intake • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: eventProperties.clientFirstName,
            lastInitial: eventProperties.clientLastName.charAt(0),
            programName: programNameLabel,
          }}
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='{firstName} {lastInitial}. • Session {sessionNumber} • {programName}'
        description='Event title for lyra appointment'
        values={{
          firstName: eventProperties.clientFirstName,
          lastInitial: eventProperties.clientLastName.charAt(0),
          sessionNumber: eventProperties.appointmentSessionNumber,
          programName: programNameLabel,
        }}
      />
    )
  }, [args.event.title, eventProperties])

  const isOOO = isOOOEvent(args.event.title)
  useEffect(() => {
    if (!pressableRef.current) {
      return
    }

    const buttonEl = pressableRef.current as HTMLElement

    const parentHarness = buttonEl.closest('.fc-timegrid-event-harness')
    if (parentHarness && parentHarness instanceof HTMLElement) {
      setIsOverlapped(parseInt(parentHarness.style.zIndex) > 1)
    }
  }, [pressableRef])
  const isBookableSlot = isBookableEvent(eventProperties)
  const eventStyles = useMemo<EventStyles>(() => {
    const eventColors = theme.colors.components.providerCalendar.event
    if (args.isMirror && !args.isDragging) {
      return {
        container: {
          backgroundColor: eventColors.mirror.background,
          borderColor: eventColors.mirror.border,
        },
      }
    }

    if (eventProperties.lyraEventType === 'generic') {
      if (isOOO) {
        return {
          container: {
            backgroundColor: eventColors.ooo.background,
            borderColor: eventColors.ooo.border,
          },
        }
      }
      return {
        container: {
          backgroundColor: eventColors.default.background,
          borderColor: eventColors.default.border,
        },
      }
    }

    if (isBookableSlot) {
      if (eventProperties.hovered === true) {
        return {
          textColor: theme.colors.textSecondary,
          container: {
            backgroundColor: eventColors.bookableHover.background,
            borderColor: eventColors.bookableHover.border,
          },
        }
      }
      return {
        textColor: theme.colors.textSecondary,
        container: {
          backgroundColor: eventColors.bookable.background,
          borderColor: eventColors.bookable.border,
        },
      }
    }

    if (eventProperties.lyraEventType === 'session' && eventProperties.appointmentClass === 'initial') {
      return {
        container: {
          backgroundColor: eventColors.initialSession.background,
          borderColor: eventColors.initialSession.border,
        },
        textColor: eventColors.initialSession.text,
      }
    }

    return {
      container: {
        backgroundColor: eventColors.recurringSession.background,
        borderColor: eventColors.recurringSession.border,
      },
      textColor: eventColors.recurringSession.text,
    }
  }, [
    args.isDragging,
    args.isMirror,
    eventProperties,
    isBookableSlot,
    isOOO,
    theme.colors.components.providerCalendar.event,
    theme.colors.textSecondary,
  ])

  const durationMinutes = differenceInMinutes(parseISO(args.event.endStr), parseISO(args.event.startStr))
  if (args.isMirror && !args.isDragging && startingRange) {
    const differenceHours = durationMinutes / 60
    if (differenceHours === 0.5) {
      startingRange.current = {
        startStr: args.event.startStr,
        endStr: addMinutes(parseISO(args.event.endStr), 30).toISOString(),
      }
    }
    const adjustedEvent = getAdjustedEvent(args.event, startingRange?.current ?? null)
    return (
      <EventContainer offset={adjustedEvent.offset} style={eventStyles.container}>
        <BodyText
          style={{ lineHeight: 14 }}
          color={theme.colors.textSecondary}
          size={BodyTextSize.CAPTION}
          text={formatRange(adjustedEvent.start.slice(0, -1), adjustedEvent.end.slice(0, -1), {
            hour: 'numeric',
            minute: 'numeric',
          })}
        />
        <SelectedAmountBadge
          color={theme.colors.textSecondary}
          size={SubheadSize.XSMALL}
          text={Math.ceil(differenceInMinutes(parseISO(adjustedEvent.end), parseISO(adjustedEvent.start)) / 60)}
        />
      </EventContainer>
    )
  }

  const isInitial = eventProperties.lyraEventType === 'session' && eventProperties.appointmentClass === 'initial'

  const tzOffset = getTimezoneOffset(timeZone, args.event.startStr)
  const startDate = subMilliseconds(parseISO(args.event.startStr), tzOffset).toISOString()
  const endDate = subMilliseconds(parseISO(args.event.endStr), tzOffset).toISOString()
  const useOneLine = durationMinutes < 30
  const displayStartTimeOnly = isBookableSlot || useOneLine || (isOverlapped && durationMinutes < 60)
  const displayTimeText = !isOverlapped || !useOneLine
  const timeText = displayTimeText ? (
    <BodyText
      style={{ lineHeight: 12, flexShrink: 0 }}
      color={eventStyles.textColor ?? theme.colors.textSecondary}
      size={BodyTextSize.CAPTION}
      numberOfLines={useOneLine ? 1 : undefined}
      text={
        displayStartTimeOnly
          ? formatDate(args.event.startStr, { hour: 'numeric', minute: 'numeric', timeZone })
          : formatRange(args.event.startStr, args.event.endStr, {
              hour: 'numeric',
              minute: 'numeric',
              timeZone,
            })
      }
    />
  ) : null

  const hasConflict = eventProperties.hasConflict ?? false
  return (
    <>
      <EventContainer
        testID={tID('ProviderCalendarEvent')}
        styles={eventStyles.container}
        pressableRef={pressableRef}
        onPress={() => setIsOpen(!isOpen)}
        hasConflict={hasConflict}
      >
        <EventRow>
          <BodyText
            style={{ lineHeight: 14 }}
            numberOfLines={1}
            color={eventStyles.textColor ?? theme.colors.textPrimary}
            size={isBookableSlot ? BodyTextSize.CAPTION : BodyTextSize.BADGE}
            text={eventTitle}
          />
          {useOneLine && timeText}
          <RightIconContainer>
            {isInitial && <EventIcon fillColor={eventStyles.textColor} />}
            {isOOO && (
              <CheckMarkInCircleIcon size={16} fillColor={theme.colors.components.providerCalendar.badge.ooo.text} />
            )}
            {hasConflict && <AlertIconStroke size={16} fillColor={theme.colors.iconWarning} />}
          </RightIconContainer>
        </EventRow>
        {!useOneLine && timeText}
        {args.event.extendedProps.hovered && isBookableSlot && !disableEditing && (
          <TrashIconContainer testID={tID('ProviderCalendarEvent-delete-button')} onPress={() => args.event.remove()}>
            <TrashIcon size={12} />
          </TrashIconContainer>
        )}
      </EventContainer>
      {showPopover && (
        <Popover
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          trigger={pressableRef}
          placement='right top'
          animated={!window.Cypress}
        >
          <Popover.Content>
            <ProviderCalendarPopover
              title={args.event.title}
              startDate={startDate}
              endDate={endDate}
              extendedProps={eventProperties}
              timeZone={timeZone}
              onClose={() => setIsOpen(false)}
              onClientProfilePressed={onClientProfilePressed}
              onDelete={() => args.event.remove()}
              onOpenInGoogleCalendar={onOpenInGoogleCalendar}
              onOpenZoom={onOpenZoom}
            />
          </Popover.Content>
        </Popover>
      )}
    </>
  )
}
