import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import { BookingMode, useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  LoadingIndicator,
  ProviderBookableCalendarSettings,
  Subhead,
  SubheadSize,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import './providerBookableCalendar.scss'
import CalendarSetupCard from '../calendar/CalendarSetupCard'
import {
  getCalendarAvailabilitySlots,
  getCalendarProvider,
  getCalendars,
  getCalendarToken,
} from '../calendar/data/calendarActions'
import {
  getCalendarAvailabilitySlots as getCalendarAvailabilitySlotsSelector,
  getCalendarProvider as getCalendarProviderSelector,
  getCalendarRequiresAuthorization,
} from '../calendar/data/calendarSelectors'
import { getBookableMaxSlots, getBookableTarget } from '../calendar/utils'
import { LC_CALENDAR_EDIT } from '../common/constants/routingConstants'
import { getAuthEmploymentStatus, getAuthUserId } from '../data/auth/authSelectors'

const Container = styled.View({
  width: '100%',
})

const Header = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: theme.spacing['24px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const ContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  paddingTop: theme.spacing['24px'],
}))

const CalendarContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  maxWidth: '944px',
  width: '100%',
  gap: theme.spacing['16px'],
}))

const CalendarSettingsTitle = styled(Subhead)({
  alignSelf: 'flex-start',
})

const Settings: FunctionComponent<SettingsProps> = ({
  actions: { getCalendarAvailabilitySlots, getCalendars, getCalendarToken, getCalendarProvider },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const availabilitySlots = useSelector(getCalendarAvailabilitySlotsSelector)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const userId = useSelector(getAuthUserId)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const calendarProvider = useSelector(getCalendarProviderSelector)
  const navigate = useNavigate()

  const [loading] = useFetcher(
    [
      [getCalendars, { providerId: userId }, !!userId],
      [getCalendarAvailabilitySlots, { providerId: userId }, !!userId],
      [getCalendarToken, { providerId: userId }, !!userId],
      [getCalendarProvider, { providerId: userId }, !!userId],
    ],
    [userId],
  )

  const initialEvents = useMemo(() => {
    return availabilitySlots?.map((slot) => ({
      startTime: slot.start_datetimetz,
      endTime: slot.end_datetimetz,
    }))
  }, [availabilitySlots])
  const target = getBookableTarget(employmentStatus)
  const maxSlots = getBookableMaxSlots(employmentStatus)
  if (!isInProductCalendarEnabled) {
    return null
  }

  if (initialEvents == null || loading) {
    return <LoadingIndicator />
  }

  return (
    <Container>
      <Header>
        <Subhead
          size={SubheadSize.LARGE}
          text={<FormattedMessage defaultMessage='Settings' description='Header for settings' />}
        />
      </Header>
      <ContentContainer>
        <CalendarContainer>
          <CalendarSettingsTitle
            size={SubheadSize.MEDIUM}
            text={<FormattedMessage defaultMessage='Calendar settings' description='Header for calendar settings' />}
          />
          {requiresAuth || initialEvents.length === 0 || calendarProvider?.booking_mode !== BookingMode.BOOKABLE ? (
            <CalendarSetupCard />
          ) : (
            <div className='lc-bookable-calendar'>
              <ProviderBookableCalendarSettings
                timeZone={timeZone}
                events={initialEvents}
                onEditPressed={() => navigate(LC_CALENDAR_EDIT.route)}
                bookableTarget={target}
                bookableMax={maxSlots}
              />
            </div>
          )}
        </CalendarContainer>
      </ContentContainer>
    </Container>
  )
}

export type SettingsProps = {
  actions: {
    getCalendarAvailabilitySlots: (params: Parameters<typeof getCalendarAvailabilitySlots>[0]) => Promise<any>
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    getCalendarToken: (params: Parameters<typeof getCalendarToken>[0]) => Promise<any>
    getCalendarProvider: (params: Parameters<typeof getCalendarProvider>[0]) => Promise<any>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendarAvailabilitySlots: getCalendarAvailabilitySlots as any,
      getCalendars: getCalendars as any,
      getCalendarToken: getCalendarToken as any,
      getCalendarProvider: getCalendarProvider as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(Settings)
