import React, { FunctionComponent, ReactElement, useRef, useState } from 'react'
import { View, ViewStyle } from 'react-native'

import styled from 'styled-components/native'
import { v4 as uuidv4 } from 'uuid'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { BaseButton, ButtonSize, ButtonType } from '../baseButton/BaseButton'
import { BodyText } from '../bodyText/BodyText'
import { DropdownMenu } from '../formElements/typeAhead/DropdownMenu'
import { ChevronIcon, ChevronIconDirection } from '../icons'

const ButtonTextContainer = styled.View<{ width?: string }>(({ width }) => ({
  ...(width && { width: width }),
}))

const DropdownContainer = styled.View<{ theme: ThemeType; width?: string | number }>(({ theme, width }) => ({
  position: 'absolute',
  backgroundColor: theme.colors.backgroundPrimary,
  padding: `${theme.spacing['16px']} 0`,
  borderRadius: theme.spacing['8px'],
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  ...(width && { width: width }),
  top: '100%',
}))

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({
  buttonTextWidth,
  buttonType = ButtonType.SECONDARY,
  buttonSize,
  customButtonText,
  dropdownWidth,
  dropdownItems,
  customTextSize,
  customTextColor,
  style,
  dropdownStyle,
  testID = 'DropdownButton',
  preselectedValue,
  fullWidth = false,
  leftIcon,
  iconAtEnd = false,
  iconColor,
}) => {
  const dropdownContainerRef = useRef(null)

  const [selectedValue, setSelectedValue] = useState(preselectedValue || dropdownItems[0].text)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const dropdownContainerRefID = 'DropDownContainer'
  const triggerButtonRefID = 'TriggerButton-' + uuidv4().substring(0, 8)

  useOnClickOutside(
    dropdownContainerRefID,
    dropdownContainerRef,
    () => {
      setIsDropdownOpen(false)
    },
    triggerButtonRefID,
  )

  return (
    <View testID={tID(testID)}>
      <View nativeID={triggerButtonRefID}>
        <BaseButton
          buttonType={buttonType}
          rightIcon={<ChevronIcon direction={isDropdownOpen ? ChevronIconDirection.UP : ChevronIconDirection.DOWN} />}
          style={style}
          size={buttonSize}
          fullWidth={fullWidth}
          leftIcon={leftIcon}
          iconAtEnd={iconAtEnd}
          iconColor={iconColor}
          text={
            buttonTextWidth ? (
              <ButtonTextContainer width={buttonTextWidth}>
                <BodyText text={customButtonText ?? selectedValue} color={customTextColor} />
              </ButtonTextContainer>
            ) : (
              <BodyText text={customButtonText ?? selectedValue} color={customTextColor} />
            )
          }
          onPress={() => {
            setIsDropdownOpen((prev) => !prev)
          }}
          customTextSize={customTextSize}
        />
      </View>
      {isDropdownOpen && (
        <DropdownContainer
          testID={tID('DropdownButton-dropdown')}
          width={dropdownWidth}
          ref={dropdownContainerRef}
          nativeID={dropdownContainerRefID}
          style={dropdownStyle}
        >
          <DropdownMenu
            options={dropdownItems.map((option) => option.text)}
            onOptionPress={(selected) => {
              !customButtonText && setSelectedValue(selected)
              dropdownItems.find((option) => option.text === selected)?.selectHandler()
              setIsDropdownOpen((prev) => !prev)
            }}
            value={selectedValue ? [selectedValue] : ['']}
          />
        </DropdownContainer>
      )}
    </View>
  )
}

export type DropdownButtonProps = {
  buttonTextWidth?: string
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  customButtonText?: string
  customTextSize?: BodyTextSize
  customTextColor?: string
  dropdownWidth?: string | number
  dropdownItems: { text: string; id: string; selectHandler: () => void }[]
  testID?: string
  style?: ViewStyle
  dropdownStyle?: ViewStyle
  preselectedValue?: string
  fullWidth?: boolean
  leftIcon?: ReactElement | null
  iconAtEnd?: boolean
  iconColor?: string
}
