/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { createColumnHelper } from '@tanstack/react-table'
import { isBefore } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { targetUtilization, weekUtilizationData } from '@lyrahealth-inc/shared-app-logic'

import { ThemeType, tID } from '../..'
import { BodyText, InfoIcon, Subhead, Tooltip } from '../../atoms'
import { DataTable, InlineInfoBanner } from '../../molecules'
import { SubheadSize } from '../../styles'
import { BodyTextSize } from '../../styles/typeStyles'

export interface DetailedWeeklyViewProps {
  historicUtilization: weekUtilizationData[]
  targetUtilization?: targetUtilization
  historicQuarter?: string
  currentQuarter?: string
  employmentStatus: string
  employmentTypeToExpectedWorkingHours: { [key: string]: number }
  employmentTypeToWeeklyExpectedBookable: { [key: string]: number }
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  maxWidth: '1275px',
  marginLeft: '84px',
  marginRight: '84px',
  flexGrow: 1,
  gap: theme.spacing['32px'],
  paddingVertical: theme.spacing['32px'],
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: `${theme.spacing['32px']} ${theme.spacing['32px']}`,
  borderRadius: '16px',
  border: `1px solid ${theme.colors.borderDefault}`,
}))

const CardContainer = styled.View({
  gap: '24px',
})

const DetailsTableViewHeader = styled.View({
  textAlign: 'center',
  flexDirection: 'row',
  alignItems: 'start',
})

const DetailsTableViewTitle = styled(BodyText)({
  textAlign: 'center',
})

const DetailsTableViewHeaderTooltip = styled(Tooltip)({
  marginLeft: '4px',
})

const InlineBannerContainer = styled.View({
  marginTop: '8px',
  marginBottom: '24px',
})

export const DetailedWeeklyView: FunctionComponent<DetailedWeeklyViewProps> = ({
  historicUtilization,
  targetUtilization,
  historicQuarter,
  currentQuarter,
  employmentStatus,
  employmentTypeToExpectedWorkingHours,
  employmentTypeToWeeklyExpectedBookable,
}) => {
  // @ts-ignore-next-line Property 'colors' does not exist on type 'DefaultTheme'.ts(2339)
  const { colors } = useTheme()
  const { formatDate } = useIntl()

  const columnHelper = createColumnHelper<weekUtilizationData>()

  /**
   * this function calculates the Bookable Target for each week the Provider works. The formula is:
   * Bookable Target = (([Expected Working Hours] - [Time Off Hours]) / [Expected Working Hours]) * [Weekly Expected Bookable]
   * @param timeOffHours<number>
   * @returns bookableTarget<number>
   */
  const getBookableTarget = (timeOffHours: number) => {
    const expectedWorkingHours = employmentTypeToExpectedWorkingHours[employmentStatus]
    const weeklyExpectedBookable = employmentTypeToWeeklyExpectedBookable[employmentStatus]
    return Math.ceil(((expectedWorkingHours - timeOffHours) / expectedWorkingHours) * weeklyExpectedBookable)
  }

  const columns = [
    columnHelper.accessor('startDate', {
      id: 'startDateRange',
      header: 'DATES',
      cell: (item) => {
        const startDate = new Date(item.getValue())
        const endDate = new Date(item.row.original.endDate)
        const isSameYear = startDate.getFullYear() === endDate.getFullYear()
        const formattedStart = isSameYear
          ? formatDate(startDate, { month: 'numeric', day: 'numeric' })
          : formatDate(startDate, { month: 'numeric', day: 'numeric', year: '2-digit' })
        const formattedEnd = isSameYear
          ? formatDate(endDate, { month: 'numeric', day: 'numeric' })
          : formatDate(endDate, { month: 'numeric', day: 'numeric', year: '2-digit' })
        return (
          <>
            {formattedStart}-{formattedEnd}
          </>
        )
      },
    }),
    columnHelper.accessor('providerPaidTimeOffHours', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='TIME OFF' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip
            content='Time off hours reflected are based on hours blocked on your Google calendar'
            hoverEnabled
          >
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      cell: (cell) => <>{cell.getValue() || 0} hrs</>,
    }),
    columnHelper.accessor('providerPaidTimeOffHours', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='BOOKABLE' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='Bookable hours' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      cell: (cell) => <>{getBookableTarget(parseInt(cell.getValue() ?? '0', 10))} hrs</>,
      id: 'bookable',
    }),
    columnHelper.accessor('providerPaidTimeOffHours', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='BOOKABLE EXPECTED' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='Expected Bookable hours' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      cell: () => <>{employmentTypeToWeeklyExpectedBookable[employmentStatus]} hrs</>,
      id: 'bookableExpected',
    }),
    columnHelper.accessor('available', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='AVAILABLE' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='Available slots' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      id: 'available',
      cell: (cell) => <>{cell.getValue()} FIX ME</>,
    }),
    columnHelper.accessor('attended', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='ATTENDED' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='Slots that were attended by a patient' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      id: 'attended',
      cell: (cell) => <>{cell.getValue()} FIX ME</>,
    }),
    columnHelper.accessor('unattended', {
      header: () => (
        <DetailsTableViewHeader>
          <DetailsTableViewTitle text='UNATTENDED' size={BodyTextSize.CAPTION} color={colors.textPrimary} />
          <DetailsTableViewHeaderTooltip content='Slots that were not attended by a patient' hoverEnabled>
            <InfoIcon size={18} />
          </DetailsTableViewHeaderTooltip>
        </DetailsTableViewHeader>
      ),
      id: 'unattended',
      cell: (cell) => <>{cell.getValue()} FIX ME</>,
    }),
  ]

  const updatedData = historicUtilization?.map((row, index) => {
    const target = targetUtilization
      ? Math.round(
          (targetUtilization?.providerExpectedWeekWorkHours -
            (row.providerPaidTimeOffHours ? parseFloat(row.providerPaidTimeOffHours) : 0)) *
            0.75,
        )
      : 0
    return {
      week: 'Week ' + (index + 1),
      startDate: row.startDate,
      endDate: row.endDate,
      sessionsCredited: row.sessionsCredited,
      sessionsNonCredited: row.sessionsNonCredited,
      providerPaidTimeOffHours: row.providerPaidTimeOffHours
        ? parseFloat(Math.round(2 * parseFloat(row.providerPaidTimeOffHours)).toFixed()) / 2
        : 0,
      target: target,
      achieved_target: isBefore(new Date(), new Date(row.endDate))
        ? '-'
        : row.sessionsCredited >= target
        ? 'Yes'
        : 'No',
      available: row.available,
      attended: row.attended,
      unattended: row.unattended,
    }
  })

  return (
    <Container>
      <CardContainer>
        <Subhead
          size={SubheadSize.MEDIUM}
          text='Detailed weekly view'
          testID={tID('CaseloadManagementDetailedWeeklyView-title')}
        />
        <DataBoardContainer>
          {historicQuarter && historicQuarter !== currentQuarter && (
            <InlineBannerContainer testID={tID('WeeklySnapshot-historicInlineBanner')}>
              <InlineInfoBanner
                marginBottom={'0px'}
                text={`You are viewing ${historicQuarter.replace(
                  '_',
                  ' ',
                )} data. If manual adjustments were made after the quarter they will not be reflected here.`}
              />
            </InlineBannerContainer>
          )}
          <DataTable
            columns={columns}
            data={updatedData}
            pagination={false}
            rowHeight='48px'
            headerBottomPadding='16px'
            tableZebraColor={colors.backgroundSection}
          />
        </DataBoardContainer>
      </CardContainer>
    </Container>
  )
}
