import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { getDay, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { sortBy, uniq } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, EditIconBoxed, PrimaryButton, QuestionMarkIcon, Subhead, Tooltip } from '../../atoms'
import { ProviderBookableCalendarSlotTarget } from '../../molecules'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSettingsProps = {
  timeZone?: string
  events?: { startTime: string; endTime: string }[]
  onEditPressed?: () => void
  bookableTarget: number
  bookableMax: number
}

const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  padding: theme.spacing['24px'],
  borderRadius: '16px',
  gap: theme.spacing['24px'],
}))

const Header = styled.View(({ theme }) => ({
  gap: theme.spacing['16px'],
}))

const HeaderInner = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  paddingBottom: theme.spacing['16px'],
}))

const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

const HeaderText = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))
export const ProviderBookableCalendarSettings: FunctionComponent<ProviderBookableCalendarSettingsProps> = ({
  timeZone = 'America/Los_Angeles',
  events = [],
  onEditPressed,
  bookableTarget,
  bookableMax,
}) => {
  const theme = useTheme() as ThemeType
  const intl = useIntl()
  const availabilityText = useMemo(() => {
    const sortedByDay = sortBy(events, (event) => {
      const dateInZone = utcToZonedTime(event.startTime, timeZone)
      return getDay(dateInZone)
    })
    return intl.formatList(
      uniq(
        sortedByDay.map((event) => {
          const date = parseISO(event.startTime)
          return intl.formatDate(date, { weekday: 'long', timeZone })
        }),
      ),
    )
  }, [events, intl, timeZone])

  return (
    <Container testID={tID('ProviderBookableCalendarSettings')}>
      <Header>
        <HeaderInner>
          <View>
            <HeaderText>
              <Subhead
                size={SubheadSize.MEDIUM}
                text={
                  <FormattedMessage
                    defaultMessage='Recurring availability'
                    description='Header for setting calendar availability'
                  />
                }
              />
              <Tooltip hoverEnabled content='Tooltip content here' placement='right'>
                <QuestionMarkIcon width={24} fillColor={theme.colors.iconDefault} />
              </Tooltip>
            </HeaderText>
            <BodyText
              color={theme.colors.textSecondary}
              text={
                <FormattedMessage
                  defaultMessage='Clients can see and book these times'
                  description='Subhead for setting calendar availability'
                />
              }
            />
          </View>
          <PrimaryButton
            testID={tID('ProviderBookableCalendarSettings-edit-button')}
            text={<FormattedMessage defaultMessage='Edit' description='Button to edit calendar' />}
            onPress={() => onEditPressed?.()}
            leftIcon={<EditIconBoxed size={24} />}
          />
        </HeaderInner>
      </Header>
      <Row>
        <ProviderBookableCalendarSlotTarget
          target={bookableTarget}
          current={events.length}
          max={bookableMax}
          showWarningBelowTarget={true}
        />
        <BodyText
          color={theme.colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Availability on {availabilityText}'
              description='Text describing which days of the week the provider is available'
              values={{ availabilityText }}
            />
          }
        />
      </Row>
    </Container>
  )
}
