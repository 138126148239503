import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

import { useZoom } from '../../hooks/useZoom'
import { ThemeType } from '../../utils'

export type ZoomSelfPreviewProps = object

const Video = styled.video<{ show: boolean; isMobileSized: boolean }>(({ isMobileSized, show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: isMobileSized ? 'cover' : undefined,
  display: show ? undefined : 'none',
  transform: 'scaleX(-1)',
}))

const CanvasContainer = styled.div<{ show: boolean }>(({ show }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  display: show ? undefined : 'none',
}))

const Canvas = styled.canvas({
  maxWidth: '100%',
  maxHeight: '100%',
})

export const ZoomSelfPreview: React.FC<ZoomSelfPreviewProps> = ({}) => {
  const { setPreviewVideoElement, setPreviewCanvasElement, videoOff, sessionStarted, isPreviewVBMode } = useZoom()
  const theme = useTheme() as ThemeType
  const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null)
  const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement | null>(null)

  useEffect(() => {
    setPreviewVideoElement(videoEl)
    setPreviewCanvasElement(canvasEl)
    return () => {
      setPreviewVideoElement(null)
      setPreviewCanvasElement(null)
    }
  }, [canvasEl, setPreviewCanvasElement, setPreviewVideoElement, videoEl])

  const displayPreview = !videoOff && !sessionStarted
  return (
    <>
      <Video
        playsInline
        show={displayPreview && !isPreviewVBMode}
        isMobileSized={theme.breakpoints.isMobileSized}
        ref={setVideoEl}
        width='1920'
        height='1080'
      />
      <CanvasContainer show={displayPreview && isPreviewVBMode}>
        <Canvas ref={setCanvasEl} width='1920' height='1080' />
      </CanvasContainer>
    </>
  )
}
