import React from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { isWithinInterval } from 'date-fns'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import { historicUtilization, targetUtilization, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import { DetailedWeeklyView, LoadingIndicator, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import { employmentTypes } from '../../common/constants/appConstants'
import { getYearMonthDayLocaleByDate } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getCurrentQuarterCMDDetailedWeeklyView,
  getProviderHistoricUtilization,
  getProviderTargetUtilization,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'
import {
  getHistoricCharges,
  getHistoricUtilization,
  getUtilizationCharges,
} from '../clients/clientDetails/data/appointmentsAutoActions'
const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

type DetailedWeeklyViewContainerProps = ConnectedProps<typeof connector>

const DetailedWeeklyViewContainer: React.FunctionComponent<DetailedWeeklyViewContainerProps> = ({
  actions: { getHistoricUtilization, getHistoricCharges },
}) => {
  const employmentStatus: string = useSelector(getAuthEmploymentStatus)
  const userId: string = useSelector(getAuthUserId)
  const historicUtilization: historicUtilization | undefined = useSelector(getProviderHistoricUtilization)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const currentQuarter: string | undefined = useSelector(getCurrentQuarterCMDDetailedWeeklyView)
  const targetUtilization: targetUtilization | undefined = useSelector(getProviderTargetUtilization)

  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
  const todayDate = new Date()
  const targetDate =
    !requestedQuarter ||
    isWithinInterval(todayDate, {
      start: new Date(`${requestedQuarter[requestedQuarterName][0]}T00:00`),
      end: new Date(`${requestedQuarter[requestedQuarterName][1]}T00:00`),
    })
      ? todayDate
      : new Date(`${requestedQuarter[requestedQuarterName][1]}T00:00`)
  const [loadingHistoricUtilization, , fetchedHistoricUtilization] = useFetcher(
    [
      [getHistoricUtilization, { id: userId, date: getYearMonthDayLocaleByDate(targetDate) }],
      [
        getHistoricCharges,
        { id: userId, date: getYearMonthDayLocaleByDate(targetDate), sessionType: 'lyra_therapy_teens_parent_session' },
      ],
    ],
    [userId],
  )

  const employmentTypeToExpectedWorkingHours = {
    [employmentTypes.FULL_TIME]: 40,
    [employmentTypes.PART_TIME]: 24,
    [employmentTypes.FULL_TIME_REDUCED]: 32,
    [employmentTypes.REDUCED_FULL_TIME]: 32,
    [employmentTypes.CLINICAL_QUALITY_CONSULTANTS]: 20,
    [employmentTypes.CLINICAL_QUALITY_COORDINATOR]: 20,
    [employmentTypes.FELLOW_SUPERVISOR]: 32,
  }
  const employmentTypeToWeeklyExpectedBookable = {
    [employmentTypes.FULL_TIME]: 30,
    [employmentTypes.PART_TIME]: 19,
    [employmentTypes.FULL_TIME_REDUCED]: 24,
    [employmentTypes.REDUCED_FULL_TIME]: 24,
    [employmentTypes.CLINICAL_QUALITY_CONSULTANTS]: 15,
    [employmentTypes.CLINICAL_QUALITY_COORDINATOR]: 15,
    [employmentTypes.FELLOW_SUPERVISOR]: 24,
  }
  return (
    <Container>
      {loadingHistoricUtilization || !fetchedHistoricUtilization || !historicUtilization ? (
        <LoadingIndicator size={45} />
      ) : (
        <DetailedWeeklyView
          historicUtilization={historicUtilization}
          targetUtilization={targetUtilization}
          historicQuarter={requestedQuarterName}
          currentQuarter={currentQuarter}
          employmentStatus={employmentStatus}
          employmentTypeToExpectedWorkingHours={employmentTypeToExpectedWorkingHours}
          employmentTypeToWeeklyExpectedBookable={employmentTypeToWeeklyExpectedBookable}
        />
      )}
    </Container>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getHistoricCharges, getHistoricUtilization, getUtilizationCharges }, dispatch),
})
const connector = connect(null, mapDispatchToProps)
export default connector(DetailedWeeklyViewContainer)
